import {
  Checkbox,
  FormControlLabel,
  IconButton,
  RadioGroup,
  makeStyles,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { AddCircle, Cancel, CheckCircle, Label } from '@material-ui/icons';
import React from 'react';
import { patchPairInflexion, postPairInflexion } from '../../api/pair';
import { LANGUAGES } from '../../constants/languages';
import { Pair } from '../../interfaces';
import { PairChip } from '../PairChip';

interface Props {
  pair: Pair;
  setPair: React.Dispatch<React.SetStateAction<Pair | undefined>>;
  onClick: (inflexion?: Pair) => void;
}

const useStyles = makeStyles((theme) => ({
  flag: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle'
  }
}));

const PROPER_ID = 1;
const COUNTABLE_ID = 2;
const UNCOUNTABLE_ID = 3;

const SINGULAR_ID = 1;
const PLURAL_ID = 2;

const PERSON_ID = 1;
const ANIMAL_ID = 4;

const MALE_IDS = [2, 5];
const FEMALE_IDS = [3, 6];

const MALE_ADD = 1;
const FEMALE_ADD = 2;

export const InflexionEnglishName = ({ pair, setPair, onClick }: Props) => {
  const [hasSex, setHasSex] = React.useState(
    (pair.inflexions ?? []).some((i) => i.sexId)
  );
  const [sexIndex, setSexIndex] = React.useState(
    (pair.inflexions ?? []).find(
      (i) => i.sexId === PERSON_ID || i.sexId === ANIMAL_ID
    )?.sexId
  );

  const [subcategoryId, setSubcategoryId] = React.useState(
    (pair.inflexions ?? []).find((i) => i.subcategoryId)?.subcategoryId
  );

  const classes = useStyles();

  const singulars = React.useMemo(
    () =>
      (pair.inflexions ?? []).filter(
        (p) =>
          p.numberId === SINGULAR_ID &&
          (!p.sexId || p.sexId === PERSON_ID || p.sexId === ANIMAL_ID)
      ),
    [pair]
  );
  const plurals = React.useMemo(
    () =>
      (pair.inflexions ?? []).filter(
        (p) =>
          p.numberId === PLURAL_ID &&
          (!p.sexId || p.sexId === PERSON_ID || p.sexId === ANIMAL_ID)
      ),
    [pair]
  );
  const maleSingulars = React.useMemo(
    () =>
      (pair.inflexions ?? []).filter(
        (p) => p.numberId === SINGULAR_ID && MALE_IDS.includes(p.sexId)
      ),
    [pair]
  );

  const malePlurals = React.useMemo(
    () =>
      (pair.inflexions ?? []).filter(
        (p) => p.numberId === PLURAL_ID && MALE_IDS.includes(p.sexId)
      ),
    [pair]
  );

  const femaleSingulars = React.useMemo(
    () =>
      (pair.inflexions ?? []).filter(
        (p) => p.numberId === SINGULAR_ID && FEMALE_IDS.includes(p.sexId)
      ),
    [pair]
  );

  const femalePlurals = React.useMemo(
    () =>
      (pair.inflexions ?? []).filter(
        (p) => p.numberId === PLURAL_ID && FEMALE_IDS.includes(p.sexId)
      ),
    [pair]
  );

  const handleRegionChange =
    (id: number) => async (regionId: number | null) => {
      const newInflexion = await patchPairInflexion(id, {
        regionId
      });

      const newInflexions = [...(pair.inflexions ?? [])];
      const newIndex = newInflexions.findIndex((p) => newInflexion.id === p.id);
      newInflexions[newIndex] = { ...newInflexion, regionId };
      setPair({
        ...pair,
        inflexions: newInflexions
      });
    };

  const handleSubmit =
    (numberId?: number, sexAdd?: number) => async (name: string) => {
      const { id, languageId, grammaticalCategoryId } = pair;
      const newInflexion = await postPairInflexion(id, {
        name,
        languageId,
        grammaticalCategoryId,
        numberId,
        subcategoryId,
        sexId: sexIndex ? sexIndex + (sexAdd ?? 0) : undefined
      });

      setPair({
        ...pair,
        inflexions: [...(pair.inflexions ?? []), newInflexion]
      });
    };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <RadioGroup row>
              <FormControlLabel
                onChange={(_) => setSubcategoryId(PROPER_ID)}
                checked={subcategoryId === PROPER_ID}
                control={<Radio />}
                label="Proper"
              />
              <FormControlLabel
                onChange={(_) => setSubcategoryId(COUNTABLE_ID)}
                checked={subcategoryId === COUNTABLE_ID}
                control={<Radio />}
                label="Countable"
              />
              <FormControlLabel
                onChange={(_) => setSubcategoryId(UNCOUNTABLE_ID)}
                checked={subcategoryId === UNCOUNTABLE_ID}
                control={<Radio />}
                label="Uncountable"
              />
            </RadioGroup>
          </TableCell>
          <TableCell align="right">
            <RadioGroup row>
              <FormControlLabel
                checked={hasSex}
                onChange={() => {
                  setSexIndex(!hasSex ? PERSON_ID : undefined);
                  setHasSex(!hasSex);
                }}
                control={<Checkbox />}
                label="Sex"
              />
              <FormControlLabel
                disabled={!hasSex}
                onChange={() => setSexIndex(PERSON_ID)}
                checked={sexIndex === PERSON_ID}
                control={<Radio />}
                label="Person"
              />
              <FormControlLabel
                disabled={!hasSex}
                onChange={() => setSexIndex(ANIMAL_ID)}
                checked={sexIndex === ANIMAL_ID}
                control={<Radio />}
                label="Animal"
              />
            </RadioGroup>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <Typography variant="subtitle2">
              <img
                className={classes.flag}
                width="20px"
                src={LANGUAGES[pair.languageId].flag}
                alt={LANGUAGES[pair.languageId].name}
              />
              {pair.id}
            </Typography>
          </TableCell>
          <TableCell>GENERAL INFLEXION</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRowTitle
          title="SINGULAR"
          inflexions={singulars}
          onClick={onClick}
          onRegionChange={handleRegionChange}
          onSubmit={handleSubmit(SINGULAR_ID)}
        />
        <TableRowTitle
          title="PLURAL"
          inflexions={plurals}
          onClick={onClick}
          onRegionChange={handleRegionChange}
          onSubmit={handleSubmit(PLURAL_ID)}
        />
        {hasSex ? (
          <>
            <TableRowTitle
              title="MALE SINGULAR"
              inflexions={maleSingulars}
              onClick={onClick}
              onRegionChange={handleRegionChange}
              onSubmit={handleSubmit(SINGULAR_ID, MALE_ADD)}
            />
            <TableRowTitle
              title="MALE PLURAL"
              inflexions={malePlurals}
              onClick={onClick}
              onRegionChange={handleRegionChange}
              onSubmit={handleSubmit(PLURAL_ID, MALE_ADD)}
            />
            <TableRowTitle
              title="FEMALE SINGULAR"
              inflexions={femaleSingulars}
              onClick={onClick}
              onRegionChange={handleRegionChange}
              onSubmit={handleSubmit(SINGULAR_ID, FEMALE_ADD)}
            />
            <TableRowTitle
              title="FEMALE PLURAL"
              inflexions={femalePlurals}
              onClick={onClick}
              onRegionChange={handleRegionChange}
              onSubmit={handleSubmit(PLURAL_ID, FEMALE_ADD)}
            />
          </>
        ) : null}
      </TableBody>
    </Table>
  );
};

//#region Subcomponents

interface TableRowTitleProps {
  title: string;
  inflexions: Pair[];
  onSubmit: (name: string) => Promise<void>;
  onClick: (inflexion?: Pair) => void;
  onRegionChange: (id: number) => (regionId: number | null) => void;
}

const TableRowTitle = ({
  title,
  inflexions,
  onSubmit,
  onClick,
  onRegionChange
}: TableRowTitleProps) => {
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [newName, setNewName] = React.useState<string>('');

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey && newName?.trim()) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleEdit = () => setEditMode(true);

  const handleCancel = () => {
    setEditMode(false);
    setNewName('');
  };

  const handleSubmit = async () => {
    await onSubmit(newName!.trim());
    handleCancel();
  };

  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">{title}</Typography>
      </TableCell>
      <TableCell>
        {inflexions.map((inflexion) => (
          <PairChip
            key={inflexion.id}
            regionId={inflexion.regionId}
            languageId={inflexion.languageId}
            onRegionChange={onRegionChange(inflexion.id)}
            isEnabled
            name={inflexion.name}
            deleteIcon={<Label />}
            onDelete={() => onClick(inflexion)}
          />
        ))}
        {editMode ? (
          <>
            <TextField
              onKeyPress={handleKeyPress}
              autoFocus
              placeholder="Name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
            <IconButton onClick={handleCancel}>
              <Cancel />
            </IconButton>
            <IconButton disabled={!newName?.trim()} onClick={handleSubmit}>
              <CheckCircle />
            </IconButton>
          </>
        ) : (
          <IconButton onClick={handleEdit}>
            <AddCircle />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

//#endregion
