import { Unit } from '../interfaces';
import { deleteRequest, getRequest, postRequest, patchRequest } from './index';

const UNIT_ENDPOINT = '/unit/';

export const getUnitById = (id: number) => getRequest(UNIT_ENDPOINT + id);
export const postUnit = (data: Unit) => postRequest(UNIT_ENDPOINT, data, true);
export const patchUnit = (id: number, data?: Unit) =>
  patchRequest(UNIT_ENDPOINT + id, data, true);
export const deleteUnit = (id: number) => deleteRequest(UNIT_ENDPOINT + id);
