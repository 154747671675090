import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  useMediaQuery
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postPairTags, TagState } from '../../api/tag';
import { refetchCurrentChain } from '../../features/chainSlice';
import { selectPairTags } from '../../features/tagSlice';
import { Pair } from '../../interfaces';
import { breakpointDownSm } from '../../theme';
import { Transition } from '../Transition';

interface Props {
  open: boolean;
  pair?: Pair;
  setPair: (pair: Pair) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refresh?: boolean;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  levelControl?: boolean;
}

const useStyles = makeStyles((_theme: Theme) => ({
  container: {
    height: '100vh'
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%'
  }
}));

export function ChainPairTagDialog({
  open,
  setOpen,
  pair,
  setPair,
  refresh = true,
  maxWidth = 'lg',
  levelControl = false
}: Props) {
  const classes = useStyles();
  const tags = useSelector(selectPairTags);
  const fullScreen = useMediaQuery(breakpointDownSm);
  const [pairTags, setPairTags] = React.useState<TagState>();
  const [level, setLevel] = React.useState<number | null>();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { name: id } = event.target;
    const tag = tags.find((t) => t.id === Number(id));
    if (tag) {
      setPairTags({ ...pairTags, [id]: { ...tag, checked } });
    }
  };

  const handleSubmit = async () => {
    if (pair && (pairTags || level !== undefined)) {
      await postPairTags(pair.id, pairTags ?? {}, level);
      if (refresh) {
        await dispatch(refetchCurrentChain());
      } else {
        const tags = Object.values(pairTags ?? {}).filter((t) => t.checked);
        setPair({ ...pair, tags });
      }
    }
    handleClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      open={open}
      onClose={handleSubmit}
    >
      <DialogTitle>Tags</DialogTitle>
      <DialogContent className={classes.container} dividers>
        <FormGroup className={classes.wrap}>
          {tags?.map((t) => (
            <FormControlLabel
              key={t.id}
              control={
                <Checkbox
                  name={t.id.toString()}
                  checked={
                    (pairTags && pairTags[t.id] && pairTags[t.id].checked) ??
                    Boolean(pair?.tags?.find((pt) => pt.id === t.id))
                  }
                  onChange={handleChange}
                />
              }
              label={t.name}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        {levelControl ? (
          <Select
            variant="outlined"
            label="Level"
            defaultValue={pair?.level ?? 0}
            onChange={(e) =>
              setLevel(e.target.value !== 0 ? Number(e.target.value) : null)
            }
          >
            <MenuItem value={0}>No level</MenuItem>
            <MenuItem value={1}>Basic</MenuItem>
            <MenuItem value={2}>Intermediate</MenuItem>
            <MenuItem value={3}>Advanced</MenuItem>
          </Select>
        ) : null}
        <Button color="primary" onClick={handleSubmit}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
