// Using redux toolkit so the store is configured here with the slices imported
import { configureStore } from '@reduxjs/toolkit';
import areasReducer, { areaSliceName } from './features/areaSlice';
import authReducer, { authSliceName } from './features/authSlice';
import chainsReducer, { chainSliceName } from './features/chainSlice';
import courseReducer, { courseSliceName } from './features/courseSlice';
import definitionsReducer, {
  definitionsSliceName
} from './features/definitionsSlice';
import errorReducer, { errorSliceName } from './features/errorSlice';
import foldersReducer, { folderSliceName } from './features/folderSlice';
import levelReducer, { levelSliceName } from './features/levelSlice';
import listsReducer, { listSliceName } from './features/listSlice';
import sectionReducer, { sectionSliceName } from './features/sectionSlice';
import tagReducer, { tagSliceName } from './features/tagSlice';
import unitReducer, { unitSliceName } from './features/unitSlice';

export const store = configureStore({
  reducer: {
    [authSliceName]: authReducer,
    [courseSliceName]: courseReducer,
    [areaSliceName]: areasReducer,
    [folderSliceName]: foldersReducer,
    [listSliceName]: listsReducer,
    [chainSliceName]: chainsReducer,
    [errorSliceName]: errorReducer,
    [tagSliceName]: tagReducer,
    [levelSliceName]: levelReducer,
    [unitSliceName]: unitReducer,
    [sectionSliceName]: sectionReducer,
    [definitionsSliceName]: definitionsReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
