import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Container, Avatar, Link, Theme, Chip } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import { EMAIL_REGEX, isInvalidPassword } from '../utils/validations';
import { login } from '../features/authSlice';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const EMAIL_ERROR =
  'Email should be correctly written (e.g. wordlist@wordlist.com)';
const PASSWORD_ERROR =
  'Password should have at least one lowercase letter, one uppercase letter and a number';

const styles = makeStyles((theme: Theme) => ({
  error: {
    color: theme.palette.error.main
  },
  submitRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  devTag: {
    marginLeft: theme.spacing(1)
  }
}));

interface FormData {
  email: string;
  password: string;
}

interface FormError {
  email?: string;
  password?: string;
}

const LOCAL_STORAGE_KEY = 'wlzero-credentials';

export const AuthForm = () => {
  const authCredentials = localStorage.getItem(LOCAL_STORAGE_KEY);
  const [form, setForm] = React.useState<FormData>(
    authCredentials ? JSON.parse(authCredentials) : undefined
  );
  const [formError, setFormError] = React.useState<FormError>();
  const [rememberMe, setRememberMe] = React.useState(Boolean(form));
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  const classes = styles();

  const dispatch = useDispatch();

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setFormError({ ...formError, [name]: undefined });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const validEmail = EMAIL_REGEX.test(form.email);
    const validPassword = !isInvalidPassword(form.password);
    if (validEmail && validPassword) {
      try {
        await dispatch(login(form));
      } catch (err: any) {
        setError(err.message);
      } finally {
        if (rememberMe) {
          localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(form));
        } else {
          localStorage.removeItem(LOCAL_STORAGE_KEY);
        }
      }
    } else {
      setFormError({
        email: !validEmail ? EMAIL_ERROR : undefined,
        password: !validPassword ? PASSWORD_ERROR : undefined
      });
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
          {process.env.NODE_ENV === 'development' ? (
            <Chip className={classes.devTag} label="DEV" size="small" />
          ) : null}
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={form?.email}
            onChange={handleChange}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={Boolean(formError?.email)}
            helperText={formError?.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={form?.password}
            onChange={handleChange}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={Boolean(formError?.password)}
            helperText={formError?.password}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
            value={rememberMe}
            onChange={(_e) => setRememberMe(!rememberMe)}
          />
          {error ? (
            <Typography className={classes.error} variant="caption">
              {error}
            </Typography>
          ) : undefined}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submit}
            endIcon={loading ? <CircularProgress size={12} /> : undefined}
          >
            Sign In
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.wordlist.com/">
        WordList
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
