import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((_theme: Theme) => ({
  input: {
    maxWidth: '30rem'
  },
  iconButton: {
    cursor: 'pointer'
  }
}));

interface IProps {
  term: string;
  setTerm: (term: string) => void;
  className?: string;
}

export function SearchInput({ className, term, setTerm }: IProps) {
  const classes = useStyles();
  return (
    <Box p={2} className={className}>
      <TextField
        className={classes.input}
        fullWidth
        autoFocus
        value={term}
        label="Search..."
        variant="outlined"
        onChange={(e) => setTerm(e.target.value ?? '')}
      />
    </Box>
  );
}
