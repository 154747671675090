import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { UnitState } from './interfaces';

import {
  getUnitById,
  postUnit,
  patchUnit,
  deleteUnit as deleteUnitAPI
} from '../../api/unit';
import { Unit } from '../../interfaces';
import { reorderIds } from '../../utils/reoderIds';
import { RootState } from '../../store';
import { refetchCurrentArea } from '../areaSlice';

// Slice name
export const unitSliceName = 'unit';

// Initial State
const unitInitialState: UnitState = {
  allUnitIds: [],
  unitsById: {},
  currentUnitId: -1,
  loading: true,
  error: null
};

// Slice definition with reducers
const unitSlice = createSlice({
  name: unitSliceName,
  initialState: unitInitialState,
  reducers: {
    setLoading: (state: UnitState, action) => {
      state.loading = action.payload;
    },
    setError: (state: UnitState, action) => {
      const errorMessage: string = action.payload;
      state.error = errorMessage;
    },
    pushUnits: (state: UnitState, action) => {
      const units: Unit[] = action.payload;

      const allIds: string[] = units.map(({ id }) => id.toString());
      const unitsById: {
        [key: string]: Unit;
      } = units.reduce(
        (acc, next) => ({
          ...acc,
          [next.id]: next
        }),
        {}
      );

      state.allUnitIds.push(...allIds);
      state.unitsById = { ...state.unitsById, ...unitsById };
    },
    pushNewUnit: (state: UnitState, action) => {
      const unitFromApi: Unit = action.payload;

      state.unitsById[unitFromApi.id] = unitFromApi;

      state.allUnitIds.push(unitFromApi.id.toString());
      state.allUnitIds = reorderIds(state.allUnitIds, state.unitsById);
    },
    pushExistingUnit: (state: UnitState, action) => {
      const unitFromApi: Unit = action.payload;
      state.unitsById[unitFromApi.id] = unitFromApi;
      state.allUnitIds = reorderIds(state.allUnitIds, state.unitsById);
    },
    removeUnit: (state: UnitState, action) => {
      const id = action.payload;
      delete state.unitsById[id];
      state.allUnitIds = state.allUnitIds.filter((fId) => fId !== id);
    },
    setCurrentUnitId: (state: UnitState, action) => {
      const id = action.payload;
      state.currentUnitId = id;
    }
  }
});

// Actions
// TODO: Remove when [this PR](https://github.com/benmosher/eslint-plugin-import/pull/2038) is merged.
// eslint-disable-next-line import/no-unused-modules
export const {
  setLoading,
  setError,
  pushUnits,
  removeUnit,
  pushNewUnit,
  pushExistingUnit,
  setCurrentUnitId
} = unitSlice.actions;

export const loadUnitById =
  (unitId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const fetchedUnit = await getUnitById(unitId);
      dispatch(pushNewUnit(fetchedUnit));
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const refetchCurrentUnit =
  () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const fetchedUnit = await getUnitById(getState().unit.currentUnitId);
      dispatch(pushExistingUnit(fetchedUnit));
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createUnit = (unit: Unit) => async (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true));
  try {
    const fetchedUnit = await postUnit(unit);
    dispatch(pushNewUnit(fetchedUnit));
    dispatch(refetchCurrentArea());
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateUnit = (unit: Unit) => async (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true));

  try {
    const fetchedUnit = await patchUnit(unit.id, unit);
    dispatch(pushExistingUnit(fetchedUnit));
    dispatch(refetchCurrentArea());
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteUnit =
  (unitId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    try {
      await deleteUnitAPI(unitId);
      dispatch(removeUnit(unitId));
      dispatch(refetchCurrentArea());
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

// Selectors
export const selectUnitsById = (state: RootState) =>
  state[unitSliceName].unitsById;
export const selectLoading = (state: RootState) => state[unitSliceName].loading;

// Reducer
export default unitSlice.reducer;
