import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { List } from '../../interfaces';
import { RootState } from '../../store';
import { ListState } from '../listSlice/interfaces';
import {
  getListById,
  postList,
  patchList,
  deleteList as deleteListApi
} from '../../api/lists';

// Slice name
export const listSliceName = 'list';

// Initial State
const listInitialState: ListState = {
  listsId: {},
  loading: true,
  error: null
};

// Slice definition with reducers
const listSlice = createSlice({
  name: listSliceName,
  initialState: listInitialState,
  reducers: {
    setLoading: (state: ListState, action) => {
      state.loading = action.payload;
    },
    setError: (state: ListState, action) => {
      const errorMessage: string = action.payload;
      state.error = errorMessage;
    },
    pushList: (state: ListState, action) => {
      const list: List = action.payload;
      state.listsId = { ...state.listsId, [list.id]: list };
    },
    editList: (state: ListState, action) => {
      const list: List = action.payload;
      state.listsId = {
        ...state.listsId,
        [list.id]: merge(state.listsId[list.id], list)
      };
    },
    removeList: (state: ListState, action) => {
      const listId: number = action.payload;
      delete state.listsId[listId];
    }
  }
});

// Actions
// TODO: Remove when [this PR](https://github.com/benmosher/eslint-plugin-import/pull/2038) is merged.
// eslint-disable-next-line import/no-unused-modules
export const { setLoading, setError, pushList, editList, removeList } =
  listSlice.actions;

// Thunks
export const loadList =
  (listId: number, languageId?: number, translationLanguageId?: number) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const list = await getListById(listId, languageId, translationLanguageId);
      dispatch(pushList(list));
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createList = (list: List) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const createdList = await postList(list);
    dispatch(pushList(createdList));
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateList =
  (listId: number, list: List) => async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      if (list.unitId) list.folderId = null;
      if (list.folderId) list.unitId = null;
      await patchList(listId, list);
      dispatch(editList(list));
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteList = (listId: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const list = await deleteListApi(listId);
    dispatch(removeList(list));
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

// Selectors
export const selectListsId = (state: RootState) => state[listSliceName].listsId;
export const selectError = (state: RootState) => state[listSliceName].error;

// Reducer
export default listSlice.reducer;
