import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  loadSections,
  selectSections,
  selectLoading,
  selectError
} from '../features/sectionSlice';
import { TITLE } from '../constants';
import { SectionCard } from '../components/Section/SectionCard';
import { selectUser } from '../features/authSlice';

export function Sections() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const sections = useSelector(selectSections);
  const user = useSelector(selectUser);

  React.useEffect(() => {
    document.title = `Sections | ${TITLE}`;
    dispatch(loadSections(user!.primaryLanguageId));
  }, [user, dispatch]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography variant="h1" color="textPrimary">
        {error}
      </Typography>
    );
  }

  return (
    <Box p={2} textAlign="center">
      <Typography variant="h1" color="textPrimary">
        Sections
      </Typography>

      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        p={2}
        marginLeft={4}
        marginRight={4}
      >
        {Object.values(sections).map((section) => (
          <SectionCard key={section.id} section={section} />
        ))}
      </Box>
    </Box>
  );
}
