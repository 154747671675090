import {
  TableFooter,
  TablePagination,
  TableRow,
  makeStyles,
  Theme,
  IconButton
} from '@material-ui/core';
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage
} from '@material-ui/icons';
import React from 'react';

const ROWS_PER_PAGE = 20;

const styles = makeStyles((theme: Theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

interface TablePaginationActionsProps {
  count: number;
  page: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions({
  count,
  page,
  onChangePage
}: TablePaginationActionsProps) {
  const classes = styles();

  const apiPage = page + 1;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, apiPage - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, apiPage + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, Math.max(0, Math.ceil(count / ROWS_PER_PAGE) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <FirstPage />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / ROWS_PER_PAGE) - 1}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / ROWS_PER_PAGE) - 1}
      >
        <LastPage />
      </IconButton>
    </div>
  );
}

interface Props {
  page: number;
  total: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
}

export const SearchTableFooter = ({ page, total, handleChangePage }: Props) => {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={total}
          rowsPerPage={ROWS_PER_PAGE}
          labelRowsPerPage={null}
          rowsPerPageOptions={[]}
          page={page - 1}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  );
};
