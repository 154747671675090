import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import { LANGUAGES } from '../../constants/languages';

const styles = makeStyles((theme: Theme) => ({
  buttonImage: {
    height: '1em',
    marginRight: theme.spacing(1)
  }
}));

interface IProps {
  onFilterLanguage(languageId: number): void;
  languageId: number;
}

export const LanguageFilter = ({ onFilterLanguage, languageId }: IProps) => {
  const classes = styles();

  const handleChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void => {
    const { value: languageId } = e.target;
    onFilterLanguage(languageId as number);
  };

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="language-select">Language</InputLabel>
      <Select
        id="language-select"
        value={languageId}
        onChange={handleChange}
        variant="outlined"
      >
        <MenuItem alignItems="center" value={0}>
          <img
            className={classes.buttonImage}
            src="/images/flags/INTL.svg"
            alt="All languages"
          />
          All
        </MenuItem>
        {Object.values(LANGUAGES).map(({ id, name, flag }) => {
          return (
            <MenuItem key={id} value={id}>
              <img className={classes.buttonImage} src={flag} alt={name} />
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
