import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
  getAreas,
  postArea,
  patchArea,
  deleteArea as deleteAreaAPI,
  getAreaById
} from '../../api/areas';
import { reorderIds } from '../../utils/reoderIds';
import { RootState } from '../../store';
import { Area, Folder } from '../../interfaces';

// Slice name
export const areaSliceName = 'area';

interface AreaState {
  allIds: string[];
  areasById: {
    [key: string]: Area;
  };
  currentId: number;
  loading: boolean;
  error: string | null;
}

// Initial State
const areasInitialState: AreaState = {
  allIds: [],
  areasById: {},
  currentId: -1,
  loading: true,
  error: null
};

// Slice definition with reducers
const areasSlice = createSlice({
  name: areaSliceName,
  initialState: areasInitialState,
  reducers: {
    setLoading: (state: AreaState, action) => {
      state.loading = action.payload;
    },
    setError: (state: AreaState, action) => {
      const errorMessage: string = action.payload;
      state.error = errorMessage;
    },
    pushInitialAreas: (state: AreaState, action) => {
      const areasFromApi: Area[] = action.payload;

      const allIds = areasFromApi.map(({ id }) => id.toString());
      const areasById = Object.values(areasFromApi).reduce(
        (acc, nextVal) => ({
          ...acc,
          [nextVal.id]: nextVal
        }),
        {}
      );

      state.allIds = allIds;
      state.areasById = areasById;
    },
    pushNewArea: (state: AreaState, action) => {
      const areaFromApi: Area = action.payload;

      state.areasById[areaFromApi.id] = areaFromApi;

      state.allIds.push(areaFromApi.id.toString());
      state.allIds = reorderIds(state.allIds, state.areasById);
    },
    pushExistingArea: (state: AreaState, action) => {
      const areaFromApi: Area = action.payload;
      state.areasById[areaFromApi.id] = areaFromApi;
      state.allIds = reorderIds(state.allIds, state.areasById);
    },
    updateCurrentArea: (state: AreaState, action) => {
      const folders: Folder = action.payload;
      const currentArea = state.areasById[state.currentId];
      state.areasById[state.currentId] = {
        ...currentArea,
        folders: [...currentArea.folders, folders]
      };
    },
    removeArea: (state: AreaState, action) => {
      const areaId = action.payload;
      delete state.areasById[areaId];
      state.allIds = state.allIds.filter((id) => id !== areaId.toString());
    },
    setCurrentAreaId: (state: AreaState, action) => {
      const areaId = action.payload;
      state.currentId = areaId;
    }
  }
});

// Actions
const {
  pushInitialAreas,
  pushNewArea,
  pushExistingArea,
  removeArea,
  setLoading,
  setError
} = areasSlice.actions;

// TODO: Remove when [this PR](https://github.com/benmosher/eslint-plugin-import/pull/2038) is merged.
// eslint-disable-next-line import/no-unused-modules
export const { setCurrentAreaId } = areasSlice.actions;

// Thunks
export const loadAreas = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));

  try {
    const areas = await getAreas();
    dispatch(pushInitialAreas(areas));
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const loadArea = (id: number) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));

  try {
    const area = await getAreaById(id);
    dispatch(pushExistingArea(area));
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createArea = (area: Area) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  try {
    const fetchedArea = await postArea(area);
    dispatch(pushNewArea(fetchedArea));
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateArea = (area: Area) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));

  try {
    const fetchedArea = await patchArea(area, area.id);
    dispatch(pushExistingArea(fetchedArea));
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteArea = (areaId: number) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));

  try {
    await deleteAreaAPI(areaId);
    dispatch(removeArea(areaId));
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const refetchCurrentArea =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setLoading(true));

    try {
      const { currentId } = getState()[areaSliceName];
      const area = await getAreaById(currentId);
      dispatch(pushExistingArea(area));
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

// Selectors
export const selectAllAreasIds = (state: RootState) =>
  state[areaSliceName].allIds;
export const selectAreasById = (state: RootState) =>
  state[areaSliceName].areasById;
export const selectLoading = (state: RootState) => state[areaSliceName].loading;
export const selectError = (state: RootState) => state[areaSliceName].error;

// Reducer
export default areasSlice.reducer;
