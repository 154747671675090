import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  makeStyles,
  Theme,
  IconButton,
  Typography,
  Card,
  CircularProgress,
  CardContent,
  Tooltip,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { Add, RadioButtonChecked, Close } from '@material-ui/icons';

import { Level, LevelLanguage } from '../interfaces';
import { selectUser } from '../features/authSlice';
import { DialogResponsive } from '../components/DialogResponsive';
import { getByLanguageId } from '../utils/model';
import {
  loadCourse,
  selectCoursesById,
  selectLoading,
  setCurrentCourseId
} from '../features/courseSlice';
import { LevelForm } from '../components/Level/LevelForm';
import { UnitForm } from '../components/Unit/UnitForm';
import { TITLE } from '../constants';
import { UnitCard } from '../components/Unit/UnitCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    margin: theme.spacing(2, 0)
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    verticalAlign: 'middle',
    padding: theme.spacing(2)
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    verticalAlign: 'middle',
    padding: theme.spacing(2, 2)
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  headerWrapper: {
    margin: theme.spacing(4, 0),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0)
  },
  listsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  listWrapper: {
    width: '80%',
    margin: theme.spacing(2, 2)
  },
  levelCard: {
    margin: theme.spacing(2, 0),
    width: '100%'
  }
}));

export const Course = () => {
  const [level, setLevel] = React.useState<Level>();
  const user = useSelector(selectUser);
  const { id } = useParams<{ id: string }>();
  const course = useSelector(selectCoursesById)[id];
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();

  const handleOpen = (level: Level) => {
    setLevel(level);
  };

  const handleClose = () => {
    setLevel(undefined);
  };

  React.useEffect(() => {
    if (location.hash && !loading) {
      document
        .getElementById(location.hash.slice(1))
        ?.scrollIntoView({ block: 'center' });
    }
  }, [location.hash, loading]);

  React.useEffect(() => {
    dispatch(setCurrentCourseId(Number(id)));
    document.title = `Course ${id} | ${TITLE}`;
    dispatch(loadCourse(Number(id)));
  }, [dispatch, id]);

  if (loading) {
    return (
      <Box m={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.headerWrapper}>
        <Typography variant="h1">COURSE</Typography>
      </Box>
      <Box className={classes.listWrapper}>
        {(course?.levels ?? []).map((l) => (
          <Card key={l.id} id={l.id.toString()} className={classes.levelCard}>
            <Box
              className={classes.cardHeader}
              style={{ backgroundColor: l.primaryColor }}
            >
              <DialogResponsive
                title="Edit Level"
                maxWidth="md"
                FormComponent={(formProps) => (
                  <LevelForm {...formProps} level={l} courseId={id} />
                )}
              />
              <Typography variant="h3">
                {getByLanguageId<LevelLanguage>(
                  l.levelLanguages,
                  user?.primaryLanguageId
                )?.name ?? '-'}
              </Typography>
              <Box className={classes.row}>
                <Tooltip title="Is it enabled?">
                  <Icon
                    className={l.isEnabled ? classes.success : classes.error}
                  >
                    <RadioButtonChecked />
                  </Icon>
                </Tooltip>
                <Typography variant="h6">
                  &nbsp;{l.units.length} units
                </Typography>
              </Box>
            </Box>
            <CardContent className={classes.listsWrapper}>
              {l.units.map((unit) => (
                <UnitCard key={unit.id} unit={unit} user={user} />
              ))}
              <Tooltip title="Add">
                <IconButton
                  color="primary"
                  size="medium"
                  onClick={() => {
                    handleOpen(l);
                  }}
                >
                  <Add />
                </IconButton>
              </Tooltip>
            </CardContent>
          </Card>
        ))}
        <DialogResponsive
          isNew
          title="New Level"
          maxWidth="md"
          FormComponent={(formProps) => (
            <LevelForm
              {...formProps}
              courseId={id}
              maxSort={Math.max(...(course?.levels?.map((l) => l.sort) ?? []))}
            />
          )}
        />
        <Dialog
          fullWidth
          maxWidth="md"
          open={Boolean(level?.id)}
          onClose={handleClose}
        >
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              Create Unit
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <UnitForm
              afterSubmit={handleClose}
              onClose={handleClose}
              levelId={level?.id}
              maxSort={Math.max(...(level?.units?.map((u) => u.sort) ?? []))}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};
