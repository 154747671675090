import React from 'react';
import { makeStyles, Theme, Box } from '@material-ui/core';
import { PairChip } from './PairChip';
import { RegionButton } from './RegionButton';

interface Props {
  pairs?: string[][] | null;
  translations?: string[][] | null;
  main?: boolean;
  chainId?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  translation: {
    fontSize: theme.spacing(2),
    color: theme.palette.primary.light
  }
}));

export const PairName = ({
  pairs,
  translations,
  chainId,
  main = false
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.column}>
      <Box display="flex" alignItems="start" flexDirection="column">
        {pairs?.length
          ? pairs?.map((pair) => {
              const [id, name, regionId] = pair;
              return (
                <PairChip
                  chainId={chainId}
                  column
                  key={id + name}
                  main={main}
                  name={name}
                  regionId={Number(regionId)}
                  isEnabled
                />
              );
            })
          : null}
      </Box>
      <Box display="flex" alignItems="start" flexDirection="column">
        {translations?.length
          ? translations?.map((translation) => {
              const [id, name, regionId] = translation;
              return (
                <Box key={id}>
                  <RegionButton disabled regionId={Number(regionId)} size="s" />
                  <span className={classes.translation}>{name}</span>
                </Box>
              );
            })
          : null}
      </Box>
    </Box>
  );
};
