import { Pair } from '../interfaces';

export const fetchVerbalForms = (
  inflexions: Pair[] | undefined,
  verbModeId: number,
  verbPersonId?: number | null
): Pair[] =>
  (inflexions ?? []).filter(
    (i) =>
      i.verbModeId === verbModeId &&
      (verbPersonId !== undefined ? i.verbPersonId === verbPersonId : true)
  );
