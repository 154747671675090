import { Tag } from '../interfaces';
import { getRequest, postRequest } from './index';

const GET_PAIR_TAGS_ROUTE = '/tag/pair';
const GET_CHAIN_TAGS_ROUTE = '/tag/chain';
const POST_PAIR_TAGS_ROUTE = '/tag/pair/';
const POST_CHAIN_TAGS_ROUTE = '/tag/chain/';

export interface TagState {
  [tagId: number]: Tag;
}

export const getPairTags = (): Promise<Tag[]> =>
  getRequest(GET_PAIR_TAGS_ROUTE);
export const postPairTags = (
  pairId: number,
  tagIds: TagState,
  level?: number | null
): Promise<void> =>
  postRequest(POST_PAIR_TAGS_ROUTE + pairId.toString(), { tagIds, level });
export const getChainTags = (): Promise<Tag[]> =>
  getRequest(GET_CHAIN_TAGS_ROUTE);
export const postChainTags = (
  chainId: number,
  tagIds: TagState
): Promise<void> =>
  postRequest(POST_CHAIN_TAGS_ROUTE + chainId.toString(), { tagIds });
