import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  makeStyles,
  Theme,
  IconButton,
  Typography,
  Card,
  CircularProgress,
  CardContent,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { Add, ArrowBackIos, Close } from '@material-ui/icons';

import { List } from '../interfaces';
import { selectUser } from '../features/authSlice';
import { ListForm } from '../components/List/ListForm';
import { ListCard } from '../components/List/ListCard';
import { TITLE } from '../constants';
import { getTutorialLists } from '../api/lists';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    margin: theme.spacing(2, 0)
  },
  headerWrapper: {
    margin: theme.spacing(4, 0),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 12),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark
  },
  listsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  listWrapper: {
    width: '80%',
    margin: theme.spacing(2, 2)
  },
  folderCard: {
    margin: theme.spacing(2, 0),
    width: '100%'
  }
}));

export const Tutorial = () => {
  const [lists, setLists] = React.useState<List[]>();
  const [open, setOpen] = React.useState<boolean>(false);
  const user = useSelector(selectUser);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const afterSubmit = async () => {
    await fetchList();
    setOpen(false);
  };

  const goBack = () => {
    history.push('/courses');
  };

  const goToList = (listId: number) => {
    history.push(`/list/${listId}`);
  };

  const fetchList = async () => {
    const fetchedLists = await getTutorialLists();
    setLists(fetchedLists);
  };

  React.useEffect(() => {
    document.title = `Tutorial | ${TITLE}`;
    fetchList();
  }, [dispatch]);

  if (!lists) {
    return (
      <Box m={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.headerWrapper}>
        <Box className={classes.headerContainer}>
          <Box className={classes.header}>
            <Box display="flex" alignItems="center" flexBasis="20%">
              <IconButton onClick={goBack} color="inherit">
                <ArrowBackIos color="inherit" />
              </IconButton>
            </Box>
            <Typography variant="h3">TUTORIAL</Typography>
            <Box flexBasis="20%" />
          </Box>
        </Box>
      </Box>
      <Box className={classes.listWrapper}>
        <Card className={classes.folderCard}>
          <CardContent className={classes.listsWrapper}>
            {lists.length
              ? lists.map((list) => (
                  <ListCard
                    key={list.id}
                    user={user}
                    list={list}
                    onClick={() => goToList(list.id)}
                  />
                ))
              : undefined}
            <Tooltip title="Add">
              <IconButton color="primary" size="medium" onClick={handleOpen}>
                <Add />
              </IconButton>
            </Tooltip>
          </CardContent>
        </Card>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Create List
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <ListForm
            isTutorial
            afterSubmit={afterSubmit}
            onClose={handleClose}
            maxSort={Math.max(...(lists?.map((l) => l.sort) ?? [0, 1]))}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
