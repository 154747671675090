import React from 'react';
import { useSelector } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  Theme,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  TableBody,
  TableCell
} from '@material-ui/core';

import { breakpointDownSm } from '../../theme';
import { PairList } from '../../interfaces';
import { getPairLists } from '../../api/pair';
import { Transition } from '../Transition';
import { selectUser } from '../../features/authSlice';
import { Close } from '@material-ui/icons';
import { LanguageTableHead } from '../LanguageTableHead';
import { Link } from 'react-router-dom';

interface Props {
  open: boolean;
  pairId: number;
  pairLanguageId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '70%'
  },
  typoRoot: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2)
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  tableCell: {
    border: `1px solid ${theme.palette.divider}`,
    padding: 0
  },
  clickable: {
    transition: 'all 100ms ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[200]
    },
    '&:active': {
      backgroundColor: theme.palette.grey[300]
    }
  }
}));

export function ChainPairListDialog({
  pairId,
  open,
  setOpen,
  maxWidth = 'lg',
  pairLanguageId
}: Props) {
  const [lists, setLists] = React.useState<PairList[]>();
  const fullScreen = useMediaQuery(breakpointDownSm);
  const user = useSelector(selectUser);
  const classes = useStyles();
  const [languageId, translationLanguageId] = React.useMemo(() => {
    const secondary = pairLanguageId !== user?.primaryLanguageId;
    return [
      secondary ? user?.secondaryLanguageId : user?.primaryLanguageId,
      secondary ? user?.primaryLanguageId : user?.secondaryLanguageId
    ];
  }, [pairLanguageId, user]);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    (async () => {
      if (open) {
        const fetchedLists = await getPairLists(
          pairId,
          languageId ?? 1,
          translationLanguageId ?? 2
        );
        setLists(fetchedLists);
      }
    })();
  }, [pairId, user, open, languageId, translationLanguageId]);

  return (
    <Dialog
      keepMounted={false}
      fullWidth
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">Pair lists</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.center} dividers>
        <TableContainer className={classes.root} component={Paper}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableCell}>
                <LanguageTableHead topLeft languageId={languageId ?? 1} />
                <LanguageTableHead languageId={translationLanguageId ?? 2} />
                <LanguageTableHead
                  topRight
                  languageId={user?.tertiaryLanguageId}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {lists?.length ? (
                lists.map((l) => (
                  <TableRow key={l.listId1} className={classes.tableCell}>
                    <TableCell
                      className={`${classes.tableCell} ${
                        l.listId1 ? classes.clickable : ''
                      }`}
                    >
                      <Link to={l.listId1 ? `/list/${l.listId1}` : ''}>
                        <Typography
                          classes={{ root: classes.typoRoot }}
                          variant="subtitle2"
                        >
                          {l.listName1}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell
                      className={`${classes.tableCell} ${
                        l.listId2 ? classes.clickable : ''
                      }`}
                    >
                      <Link to={`/list/${l.listId2}`}>
                        <Typography
                          classes={{ root: classes.typoRoot }}
                          variant="subtitle2"
                        >
                          {l.listName2}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCell} />
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="h3">-</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}
