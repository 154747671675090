import React from 'react';

import {
  TableRow,
  TableCell,
  Box,
  CircularProgress,
  TableBody,
  Theme,
  makeStyles,
  Checkbox
} from '@material-ui/core';

import { ImportablePair, ListPair, SimpleListPair } from '../../interfaces';
import { IsEnabled } from '../IsEnabled';
import { PairName } from '../PairName';
import { PairChip } from '../PairChip';
import { ListTableSimpleCell } from './ListTableSimpleCell';

interface Props {
  loading: boolean;
  rows?: any[];
  tableCellClass?: string;
  mainClass?: string;
  simple?: boolean;
  check?: (index: number, checked: boolean) => void;
  languageId?: number;
  handleChange?: (pairId: number, sort: number) => void;
  handleDelete?: (pairId: number) => void;
  primaryListLanguageId?: number;
  secondaryListLanguageId?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  imageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '100%'
    }
  },
  previousButton: {
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  resetButton: {
    position: 'absolute',
    bottom: 0
  },
  nextButton: {
    position: 'absolute',
    bottom: 0,
    right: 0
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle'
  },
  imageCell: {
    width: '12%',
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0)
  },
  border: {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0)
  },
  tableCell: {
    border: `1px solid ${theme.palette.divider}`,
    width: '50%'
  },
  grey: {
    background: theme.palette.grey[200]
  }
}));

export const ListTableBody = ({
  loading,
  rows,
  tableCellClass,
  mainClass,
  check,
  simple = false,
  handleChange,
  handleDelete,
  primaryListLanguageId,
  secondaryListLanguageId
}: Props) => {
  const classes = useStyles();

  if (loading) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={5}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (check) {
    return (
      <TableBody>
        {rows && rows.length
          ? rows.map((row: ImportablePair, i) => {
              return (
                <React.Fragment key={row.chainId}>
                  <TableRow className={classes.border}>
                    <TableCell align="center" className={classes.border}>
                      <Checkbox
                        checked={row.selected}
                        value={row.selected}
                        onChange={() => check(i, !row.selected)}
                      />
                    </TableCell>
                    <TableCell
                      className={classes.border + ' ' + classes.imageCell}
                    >
                      <img
                        className={classes.image}
                        src={row.pairImageUrl ?? '/logo-bw.png'}
                        alt="chain"
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <PairName
                        chainId={row.chainId}
                        pairs={row.regionPairs}
                        main={row.pairMain}
                      />
                    </TableCell>
                    <TableCell
                      className={classes.tableCell + ' ' + classes.grey}
                    >
                      <PairChip
                        chainId={row.chainId}
                        column
                        name={row.translationPairName}
                        regionId={Number(row.translationPairRegion)}
                        isEnabled
                      />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })
          : undefined}
      </TableBody>
    );
  }

  if (simple) {
    return (
      <TableBody>
        {rows && rows.length
          ? rows.map((row: SimpleListPair) => (
              <ListTableSimpleCell
                key={row.pairId}
                row={row}
                classes={classes}
                tableCellClass={tableCellClass}
                mainClass={mainClass}
                handleChange={handleChange}
                handleDelete={handleDelete}
                primaryListLanguageId={primaryListLanguageId}
                secondaryListLanguageId={secondaryListLanguageId}
              />
            ))
          : undefined}
      </TableBody>
    );
  }

  return (
    <TableBody>
      {rows && rows.length
        ? rows.map((row: ListPair) => {
            return (
              <React.Fragment
                key={
                  row.regionPairs1
                    ? row.regionPairs1.reduce(
                        (acc, pair) => acc + Number(pair[0]),
                        0
                      )
                    : row.regionPairs2
                    ? row.regionPairs2.reduce(
                        (acc, pair) => acc + Number(pair[0]),
                        0
                      )
                    : row.chainId
                }
              >
                <TableRow className={classes.border}>
                  <TableCell size="small">
                    <IsEnabled
                      isEnabled={row.chainIsEnabled}
                      grammaticalCategoryName={row.pairGrammaticalCategory}
                    />
                  </TableCell>
                  <TableCell className={classes.border}>
                    <img
                      className={classes.image}
                      src={row.pairImageUrl ?? '/logo-bw.png'}
                      alt="chain"
                    />
                  </TableCell>
                  <TableCell className={tableCellClass ?? classes.tableCell}>
                    <PairName
                      chainId={row.pairChain}
                      pairs={row.regionPairs1}
                      translations={row.translationPairs1WithRegion}
                      main={row.pairMain1}
                    />
                  </TableCell>
                  <TableCell className={tableCellClass ?? classes.tableCell}>
                    <PairName
                      chainId={row.pairChain}
                      pairs={row.regionPairs2}
                      translations={row.translationPairs2WithRegion}
                      main={row.pairMain2}
                    />
                  </TableCell>
                  <TableCell className={tableCellClass ?? classes.tableCell}>
                    <PairName />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })
        : undefined}
    </TableBody>
  );
};
