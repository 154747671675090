export interface Course {
  id: number;
  courseLanguages: CourseLanguage[];
  sort: number;
  imageUrl?: string | null;
  picture?: File | null;
  levels: Level[];
  levelsLength: number;
  isEnabled: boolean;
}

interface CourseLanguage {
  id: number;
  name: string;
  courseId: number;
  languageId: number;
}

export interface Level {
  id: number;
  levelLanguages: LevelLanguage[];
  sort: number;
  units: Unit[];
  courseId: number;
  unitsLength: number;
  isEnabled: boolean;
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
}

export interface LevelLanguage {
  id: number;
  name: string;
  courseId: number;
  languageId: number;
}

export interface Unit {
  id: number;
  unitLanguages: UnitLanguage[];
  sort: number;
  imageUrl?: string | null;
  picture?: File | null;
  lists: List[];
  listsLength: number;
  levelId: number;
  courseId: number;
  levelPrimaryColor: string;
  levelSecondaryColor: string;
  levelTertiaryColor: string;
}

export interface UnitLanguage {
  id: number;
  name: string;
  isEnabled: boolean;
  unitId: number;
  languageId: number;
}

export interface Area {
  id: number;
  areaLanguages: AreaLanguage[];
  sort: number;
  imageUrl?: string | null;
  picture?: File | null;
  folders: Folder[];
  foldersLength: number;
  isEnabled: boolean;
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
}

export interface AreaLanguage {
  id: number;
  name: string;
  areaId: number;
  languageId: number;
}

export interface Folder {
  id: number;
  folderLanguages: FolderLanguage[];
  sort: number;
  lists: List[];
  listsLength: number;
  isEnabled: boolean;
  areaId?: number;
  sectionId?: number;
  legacyCourseId: number;
}

export interface FolderLanguage {
  id: number;
  name: string;
  folderId: number;
  languageId: number;
}

export interface Chain {
  id: number;
  state: string;
  grammaticalCategoryId: number;
  chainLanguages: ChainLanguage[];
  chainImages: ChainImage[];
  isEnabled: boolean;
  tags: Tag[];
}

export interface ChainLanguage {
  id: number;
  definition: string;
  chainId: number;
  languageId: number;
  regionId?: number | null;
  pairs: Pair[];
}

export interface ChainImage {
  id: number;
  imageUrl: string;
  chainId: number;
  isDefault: boolean;
}

export interface Pair {
  id: number;
  name: string;
  imageUrl?: string | null;
  audioUrl?: string | null;
  specificDefinition?: string | null;
  chainId: number;
  grammaticalCategoryId: number;
  languageId: number;
  regionId?: number | null;
  main: boolean;
  basePairId?: number | null;
  isEnabled: boolean;
  tags?: Tag[];
  inflexions?: Pair[];
  translations?: Pair[];
  lists?: List[];
  listsLength?: number;
  mainPairId?: number | null;
  subcategoryId: number;
  genderId: number;
  numberId: number;
  sexId: number;
  verbModeId: number;
  verbPersonId: number;
  verbNumberId: number;
  level?: number | null;
  inflexionsLength?: number;
  subcategory?: Subcategory;
  gender?: Gender;
  number?: NumberModel;
  sex?: Sex;
  verbMode?: VerbMode;
  verbPerson?: VerbPerson;
  verbNumber?: VerbNumber;
}

export enum PairStateColor {
  enabledMainColor = '#005AAD',
  enabledColor = '#008FFA',
  disabledMainColor = '#AD0000',
  disabledColor = '#FF3937'
}

export interface List {
  id: number;
  isPremium: boolean;
  sort: number;
  folderId?: number | null;
  unitId?: number | null;
  picture?: File;
  listLanguages: ListLanguage[];
  isTutorial: boolean;
  pairs: any[];
  folder?: {
    id: number;
    areaId: number;
    sectionId: number;
    area: { id: number; name: string };
    section: { id: number; name: string };
    name: string;
  };
  unit?: {
    id: number;
    levelId: number;
    level: { id: number; name: string; course: { id: number; name: string } };
    name: string;
  } | null;
}

export interface ListPair {
  pairGrammaticalCategory: string;
  pairImageUrl: string;
  pairChain: number;
  mainSort: number;
  pairLanguageId1: number;
  pairMain1: boolean;
  regionPairs1: string[][] | null;
  translationPairsWithRegion: string[][] | null;
  translationPairs1WithRegion: string[][] | null;
  pairRelationLang1: number;
  chainId: number;
  pair2Id: number;
  pairSort2: number;
  chainIsEnabled: boolean;
  pairLanguageId2: number;
  pairMain2: boolean;
  regionPairs2: string[][] | null;
  translationPairs2WithRegion: string[][] | null;
  pairRelationLang2: number;
}

export interface SimpleListPair {
  imageUrl: string;
  pairChain: number;
  pairSort: number;
  pairLanguageId: number;
  pairRegionId: number;
  pairMain: boolean;
  pairName: string;
  translationPairsWithRegion: string[][] | null;
  chainImages: string[][];
  chainId: number;
  chainImageId: number | null;
  pairId: number;
}

export interface ImportablePair {
  chainId: number;
  chainIsEnabled: boolean;
  pairSort: number;
  pairGrammaticalCategory: string;
  pairLanguageId: number;
  pairMain: boolean;
  regionPairs: string[][] | null;
  translationPairId: number;
  translationPairName: string;
  translationPairRegion?: number | null;
  pairImageUrl: string;
  chImageUrl: string;
  selected?: boolean;
}

export interface ListLanguage {
  id: number;
  listId: number;
  languageId: number;
  name: string;
  isEnabled: boolean;
  imageUrl?: string | null;
  pairsLength: number;
  pairs?: Pair[];
}

export interface Tag {
  id: number;
  name: string;
  abbreviation: string;
  languageId: number;
  checked?: boolean;
}

export interface PairList {
  listId1: number;
  listLanguageId1: number;
  listName1: string;
  listId2: number;
  listName2: string;
  listLanguageId2: number;
}

export type AutocompleteModel =
  | 'area'
  | 'course'
  | 'folder'
  | 'level'
  | 'unit'
  | 'section';

export interface Section {
  id: number;
  name: string;
  isEnabled: boolean;
  foldersLength: number;
  sectionLanguages: SectionLanguage[];
  folders?: Folder[];
}

interface SectionLanguage {
  id: number;
  name: string;
  sectionId: number;
  languageId: number;
}

export interface Subcategory {
  id: number;
  grammaticalCategoryId: number;
  languageId: number;
  name: string;
}

export interface Gender {
  id: number;
  name: string;
}

export interface NumberModel {
  id: number;
  name: string;
}

export interface Sex {
  id: number;
  name: string;
}

export interface VerbMode {
  id: number;
  name: string;
}

export interface VerbPerson {
  id: number;
  name: string;
}

export interface VerbNumber {
  id: number;
  name: string;
}

export interface Definitions {
  subcategories: Subcategory[];
  genders: Gender[];
  numbers: NumberModel[];
  sexes: Sex[];
  verbModes: VerbMode[];
  verbNumbers: VerbNumber[];
  verbPersons: VerbPerson[];
}
