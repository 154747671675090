import React from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  makeStyles,
  Switch,
  Theme
} from '@material-ui/core';
import { ChatBubbleOutline } from '@material-ui/icons';

import { List } from '../../interfaces';

import { ListTitle } from './ListTitle';
import { User } from '../../features/authSlice/interfaces';
import { getByLanguageId } from '../../utils/model';
import { Chat } from '../Chat';
import { DialogResponsive } from '../DialogResponsive';
import { fetchChatByRoomId } from '../../api/chat';
import { ChatMessage } from '../../interfaces/socket';
import { ListParent } from './ListParent';
import { ListUri } from './ListUri';

interface Props {
  list?: List;
  user?: User | null;
  chainMode: boolean;
  setChainMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 8)
  }
}));

export const ListHeader = ({ list, user, chainMode, setChainMode }: Props) => {
  const [messages, setMessages] = React.useState<ChatMessage[]>();
  const classes = useStyles();

  const primaryListLanguage = getByLanguageId(
    list?.listLanguages,
    user?.primaryLanguageId
  );
  const secondaryListLanguage = getByLanguageId(
    list?.listLanguages,
    user?.secondaryLanguageId
  );
  const tertiaryListLanguage = getByLanguageId(
    list?.listLanguages,
    user?.tertiaryLanguageId
  );

  React.useEffect(() => {
    (async () => {
      const chatMessages = await fetchChatByRoomId('l-' + list?.id);
      setMessages(chatMessages);
    })();
  }, [list?.id]);

  return (
    <Box className={classes.center}>
      <Box className={classes.column} flexBasis="20%">
        <ListUri list={list} />
        <ListParent list={list} />
      </Box>
      <Box className={classes.column} flexBasis="30%">
        <ListTitle listLanguage={primaryListLanguage} />
        <ListTitle listLanguage={secondaryListLanguage} />
        <ListTitle listLanguage={tertiaryListLanguage} />
      </Box>
      <Box
        className={classes.column}
        justifyContent="center"
        alignItems="center"
        flexBasis="20%"
      >
        <DialogResponsive
          startIcon={<ChatBubbleOutline />}
          text={`Comments (${messages ? messages.length : '...'})`}
          title="Chat"
          FormComponent={() => (
            <Chat fetchedMessages={messages} roomId={`l-${list?.id}`} />
          )}
        />
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={chainMode}
                onChange={() => setChainMode(!chainMode)}
              />
            }
            label="Chain Mode"
          />
        </FormControl>
      </Box>
    </Box>
  );
};
