import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, CircularProgress } from '@material-ui/core';

import { AuthForm } from '../components/AuthForm';
import { selectIsAuthenticated } from '../features/authSlice';
import { SEARCH_ROUTE } from '../constants/routes';
import { TITLE } from '../constants';

export const Home: FunctionComponent = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const history = useHistory();

  useEffect(() => {
    document.title = `Sign in | ${TITLE}`;
    if (isAuthenticated) {
      history.push(SEARCH_ROUTE);
    }
  }, [isAuthenticated, history]);

  return isAuthenticated ? (
    <CircularProgress />
  ) : (
    <Box p={2}>
      <AuthForm />
    </Box>
  );
};
