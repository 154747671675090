import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { TagState } from './interfaces';
import { getChainTags, getPairTags } from '../../api/tag';
import { RootState } from '../../store';

// Slice name
export const tagSliceName = 'tag';

// Initial State
const tagInitialState: TagState = {
  pairTags: [],
  chainTags: []
};

// Slice definition with reducers
const tagSlice = createSlice({
  name: tagSliceName,
  initialState: tagInitialState,
  reducers: {
    setPairTags: (state: TagState, action) => {
      const pairTags = action.payload;

      state.pairTags = pairTags;
    },
    setChainTags: (state: TagState, action) => {
      const chainTags = action.payload;

      state.chainTags = chainTags;
    }
  }
});

const { setPairTags, setChainTags } = tagSlice.actions;

// Thunks
export const fetchPairTags = () => async (dispatch: Dispatch) => {
  const fetchedPairTags = await getPairTags();
  dispatch(setPairTags(fetchedPairTags));
};

export const fetchChainTags = () => async (dispatch: Dispatch) => {
  const fetchedChainTags = await getChainTags();
  dispatch(setChainTags(fetchedChainTags));
};

// Selectors
export const selectPairTags = (state: RootState) =>
  state[tagSliceName].pairTags;
export const selectChainTags = (state: RootState) =>
  state[tagSliceName].chainTags;

// Reducer
export default tagSlice.reducer;
