import React from 'react';
import { useDispatch } from 'react-redux';

import {
  Avatar,
  Box,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  makeStyles,
  Theme,
  Fab,
  Chip,
  Select,
  MenuItem,
  capitalize,
  Button,
  InputAdornment
} from '@material-ui/core';
import {
  ArrowBackIos,
  ArrowForwardIos,
  RadioButtonChecked,
  Save,
  Translate
} from '@material-ui/icons';

import { merge, set } from 'lodash';

import { User } from '../../features/authSlice/interfaces';
import { Chain } from '../../interfaces';

import { LANGUAGES } from '../../constants/languages';

import ImagesDialog from '../ImagesDialog';
import { TITLE } from '../../constants';
import {
  createChain,
  pushExistingChain,
  updateChain
} from '../../features/chainSlice';
import { ChainTranslationsDialog } from './ChainTranslationsDialog';
import { ChainTagDialog } from './ChainTagDialog';
import { getByLanguageId, mergeLanguageModel } from '../../utils/model';
import { GRAMMATICAL_CATEGORIES } from '../../constants/grammatical-categories';
import { Chat } from '../Chat';
import { updateDefaultChainImage } from '../../api/chains';
import { translateText } from '../../api/deepl';

interface Props {
  user?: User | null;
  chain?: Chain;
}

const useStyles = makeStyles((theme: Theme) => ({
  green: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  container: {
    marginRight: theme.spacing(4)
  },
  imageContainer: {
    margin: theme.spacing(1, 0),
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '100%'
    }
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10
  },
  image: {
    width: theme.spacing(45),
    height: theme.spacing(45)
  },
  labelText: {
    display: 'flex',
    alignItems: 'center'
  },
  labelImage: {
    height: '1em'
  },

  previousButton: {
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  nextButton: {
    position: 'absolute',
    bottom: 0,
    right: 0
  },
  defaultButton: {
    position: 'absolute',
    bottom: 0,

    '&:disabled': {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.text.disabled
    }
  },
  chip: {
    marginBottom: theme.spacing(1),
    width: '50%'
  },
  iconButton: {
    marginBottom: theme.spacing(1)
  },

  icon: {
    fontSize: theme.spacing(6)
  }
}));

export const ChainInfo = ({ user, chain }: Props) => {
  const [changes, setChanges] = React.useState<Chain>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [imageIndex, setImageIndex] = React.useState<number>(() => {
    const index = chain?.chainImages.findIndex((ci) => ci.isDefault);
    return index !== -1 ? index ?? 0 : 0;
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentImage = React.useMemo(
    () => chain?.chainImages[imageIndex],
    [chain, imageIndex]
  );

  const saveDisabled = chain
    ? !changes
    : !changes?.chainLanguages || !changes?.chainLanguages.length;

  const previousImage = () => {
    if (imageIndex - 1 >= 0) {
      setImageIndex(imageIndex - 1);
    }
  };

  const nextImage = () => {
    if (imageIndex + 1 < (chain?.chainImages.length ?? 0)) {
      setImageIndex(imageIndex + 1);
    }
  };

  const updateDefaultImage = async () => {
    if (currentImage) {
      await updateDefaultChainImage(currentImage.id);
      const chainImages = chain?.chainImages.map((ci) => {
        const newChainImage = { ...ci };
        newChainImage.isDefault = ci.id === currentImage.id;
        return newChainImage;
      });
      dispatch(pushExistingChain(merge({ ...chain, chainImages })));
    }
  };

  const setChain = (chain?: Chain) => {
    dispatch(pushExistingChain(chain));
  };

  function handleChange(path: string, value: unknown): void {
    const newChanges = { ...changes } as Chain;
    set(newChanges, path, value);
    setChanges(newChanges);
  }

  const handleSubmit = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    const newChain = mergeLanguageModel(chain, changes);
    if (newChain) {
      if (chain) {
        await dispatch(updateChain(newChain));
      } else {
        await dispatch(createChain(newChain));
      }
      setChanges(undefined);
      document.title = `Chain ${newChain.id} | ${TITLE}`;
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      e.currentTarget.querySelector('textarea')?.blur();
      handleSubmit();
    }
  };

  const autoTranslate = async (
    change: (definition: String) => void,
    targetLanguageId?: number
  ) => {
    if (targetLanguageId) {
      const sourceChainLanguage =
        changes?.chainLanguages.find(
          (cl) => cl.languageId !== targetLanguageId
        ) ??
        chain?.chainLanguages.find((cl) => cl.languageId !== targetLanguageId);

      if (sourceChainLanguage) {
        const translation = await translateText(
          LANGUAGES[targetLanguageId].deeplLocale,
          sourceChainLanguage.definition
        );

        change(translation.translations[0].text);
      }
    }
  };

  const definitionTextField = (languageId?: number): React.ReactElement => {
    const language = languageId ? LANGUAGES[languageId] : undefined;

    const chainLanguageIndex = chain?.chainLanguages?.findIndex(
      (al) => al?.languageId === languageId
    );
    const changesLanguageIndex = changes?.chainLanguages?.findIndex(
      (al) => al?.languageId === languageId
    );

    const ali =
      chainLanguageIndex !== undefined && chainLanguageIndex !== -1
        ? chainLanguageIndex
        : chain?.chainLanguages?.length ?? 0;
    const cli =
      changesLanguageIndex !== undefined && changesLanguageIndex !== -1
        ? changesLanguageIndex
        : changes?.chainLanguages?.length ?? 0;

    const chainLanguage = chain?.chainLanguages
      ? chain?.chainLanguages[ali]
      : undefined;
    const changesLanguage = changes?.chainLanguages
      ? changes?.chainLanguages[cli]
      : undefined;

    return (
      <TextField
        variant="outlined"
        multiline
        disabled={!languageId}
        placeholder={!languageId ? 'No language assigned.' : undefined}
        rows={3}
        margin="normal"
        onKeyPress={onKeyPress}
        value={changesLanguage?.definition ?? chainLanguage?.definition}
        onChange={(e) =>
          handleChange(`chainLanguages[${cli ?? ali}]`, {
            languageId,
            definition: e.target.value
          })
        }
        InputProps={{
          endAdornment: (
            <Tooltip title="Auto-translate from DeepL" placement="top" arrow>
              <InputAdornment position="end">
                <IconButton
                  disabled={!languageId}
                  onClick={() =>
                    autoTranslate(
                      (definition) =>
                        handleChange(`chainLanguages[${cli ?? ali}]`, {
                          languageId,
                          definition
                        }),
                      languageId
                    )
                  }
                >
                  <Translate />
                </IconButton>
              </InputAdornment>
            </Tooltip>
          )
        }}
        label={
          <Typography className={classes.labelText} variant="caption">
            Definition for&nbsp;&nbsp;
            <img
              className={classes.labelImage}
              src={language?.flag ?? '/images/flags/INTL.svg'}
              alt={`${language?.name ?? 'INTL'} flag`}
            />
          </Typography>
        }
      />
    );
  };

  return (
    <Box>
      <Fab
        disabled={saveDisabled}
        className={classes.fab}
        color="secondary"
        onClick={handleSubmit}
      >
        <Save />
      </Fab>
      <Box display="flex" height={450}>
        <Box
          className={classes.container}
          flexBasis="20%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Select
            defaultValue={chain?.grammaticalCategoryId}
            fullWidth
            variant="outlined"
            onChange={(e) =>
              handleChange('grammaticalCategoryId', e.target.value)
            }
          >
            {Object.values(GRAMMATICAL_CATEGORIES).map((gc) => {
              const gcl = getByLanguageId(
                gc.grammaticalCategoryLanguages,
                user?.primaryLanguageId
              );
              return (
                <MenuItem key={gc.id} value={gc.id}>
                  {capitalize(gcl?.name ?? '-')}
                </MenuItem>
              );
            })}
          </Select>
          <Box className={classes.imageContainer}>
            <Avatar
              className={classes.image}
              variant="square"
              src={
                !!chain && !!chain.chainImages && !!chain.chainImages.length
                  ? currentImage?.imageUrl
                  : '/logo-bw.png'
              }
              alt="Chain"
            />
            <ImagesDialog
              chainId={chain?.id}
              images={chain?.chainImages}
              disabled={!chain}
            />

            <IconButton
              disabled={!(imageIndex - 1 >= 0)}
              onClick={previousImage}
              className={classes.previousButton}
              color="secondary"
            >
              <ArrowBackIos />
            </IconButton>

            <Button
              color="secondary"
              variant="contained"
              disableElevation
              disabled={currentImage?.isDefault}
              onClick={updateDefaultImage}
              className={classes.defaultButton}
            >
              DEFAULT
            </Button>

            <IconButton
              disabled={!(imageIndex + 1 < (chain?.chainImages.length ?? 0))}
              onClick={nextImage}
              className={classes.nextButton}
              color="secondary"
            >
              <ArrowForwardIos />
            </IconButton>
          </Box>
          <Tooltip title="Work In Progress!">
            <Typography variant="h3" color="textPrimary">
              SCIENTIFIC NAME
            </Typography>
          </Tooltip>
        </Box>
        <Box flexBasis="50%" display="flex" flexDirection="column">
          {definitionTextField(user?.primaryLanguageId)}
          {definitionTextField(user?.secondaryLanguageId)}
          {definitionTextField(user?.tertiaryLanguageId)}
          <ChainTranslationsDialog user={user} chain={chain} />
        </Box>
        <Box
          flexBasis="10%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Tooltip title="Is it enabled?">
            <span>
              <IconButton
                className={`classes.iconButton ${
                  changes?.isEnabled ?? chain?.isEnabled
                    ? classes.green
                    : classes.error
                }`}
                onClick={() =>
                  handleChange(
                    'isEnabled',
                    !changes?.isEnabled ?? !chain?.isEnabled
                  )
                }
              >
                <RadioButtonChecked className={classes.icon} />
              </IconButton>
            </span>
          </Tooltip>
          <ChainTagDialog
            open={open}
            setOpen={setOpen}
            chain={chain}
            setChain={setChain}
          />

          {(changes?.tags ?? chain?.tags)?.length ? (
            (changes?.tags ?? chain?.tags)?.map((tag) => (
              <Chip
                key={tag.id}
                className={classes.chip}
                color="secondary"
                label={tag.abbreviation}
                onClick={() => setOpen(true)}
              />
            ))
          ) : (
            <Chip
              className={classes.chip}
              color="secondary"
              label="Tags"
              onClick={() => setOpen(true)}
            />
          )}
        </Box>
        <Box flexBasis="20%" width="20%">
          <Chat roomId={chain?.id ? `c-${chain?.id}` : undefined} />
        </Box>
      </Box>
    </Box>
  );
};
