import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { AuthStateI, AuthGlobalStateI } from './interfaces';
import { postLogin, getLogout } from '../../api/authentication';
import { getMyData } from '../../api/users';

// Slice name
export const authSliceName = 'auth';

// Initial State
const authInitialState: AuthStateI = {
  user: null,
  isAuthenticated: false,
  authenticating: false
};

// Slice definition with reducers
const authSlice = createSlice({
  name: authSliceName,
  initialState: authInitialState,
  reducers: {
    setAuthenticationData: (state: AuthStateI, action) => {
      const payload = action.payload;
      state.user = payload;
      state.isAuthenticated = true;
    },
    resetAuth: (state: AuthStateI) => {
      state.user = authInitialState.user;
      state.isAuthenticated = false;
    }
  }
});

// Actions
const { setAuthenticationData, resetAuth } = authSlice.actions;

// Thunks
export const getuserData = () => async (dispatch: Dispatch) => {
  const userData = await getMyData();
  dispatch(setAuthenticationData(userData));
};

export const login =
  (formData: { email: string; password: string }) =>
  async (dispatch: Dispatch) => {
    await postLogin(formData);

    // We call the thunk like a currified function as it's being called by another thunk
    getuserData()(dispatch);
  };

export const logout = () => async (dispatch: Dispatch) => {
  await getLogout();
  dispatch(resetAuth());
};

// Selectors
export const selectIsAuthenticated = (state: AuthGlobalStateI) =>
  state[authSliceName].isAuthenticated;
export const selectUser = (state: AuthGlobalStateI) =>
  state[authSliceName].user;

// Reducer
export default authSlice.reducer;
