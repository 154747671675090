import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  CircularProgress,
  Typography,
  makeStyles,
  Theme,
  IconButton
} from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

import { selectUser } from '../features/authSlice';
import { ChainInfo, ChainPairs } from '../components/Chain';
import { TITLE } from '../constants';
import { useQuery } from '../utils/hooks';
import {
  loadChain,
  selectChainsId,
  selectLoading,
  selectError,
  setLoading
} from '../features/chainSlice';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    padding: theme.spacing(2)
  }
}));

export function Chain() {
  const { id } = useParams<{ id?: string }>();
  const chain = useSelector(selectChainsId)[id ?? 0];
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const chainIds = useQuery().get('chain-ids')?.split(',');
  const index = id ? chainIds?.indexOf(id) : -1;

  React.useEffect(() => {
    document.title = `${id ? `Chain ${id}` : 'Create Chain'} | ${TITLE}`;
    if (id) {
      dispatch(loadChain(Number(id)));
    } else {
      dispatch(setLoading(false));
    }

    return () => {
      dispatch(setLoading(true));
    };
  }, [dispatch, id]);

  function previousChain() {
    if (chain && chainIds && index !== undefined && chainIds[index - 1]) {
      if (chainIds && index && chainIds[index - 1]) {
        history.push(
          `/chain/${chainIds[index - 1]}?chain-ids=${chainIds.join(',')}`
        );
      }
    }
  }

  function nextChain() {
    if (chain && chainIds && index !== undefined && chainIds[index + 1]) {
      history.push(
        `/chain/${chainIds[index + 1]}?chain-ids=${chainIds.join(',')}`
      );
    }
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="center">
        <IconButton
          disabled={!chainIds || index === undefined || !chainIds[index - 1]}
          color="inherit"
          onClick={previousChain}
        >
          <ArrowBackIos fontSize="small" />
        </IconButton>

        <Typography variant="h1" color="textPrimary">
          {chain?.id ? `CHAIN ${chain.id}` : 'CREATE CHAIN'}
        </Typography>

        <IconButton
          disabled={!chainIds || index === undefined || !chainIds[index + 1]}
          color="inherit"
          onClick={nextChain}
        >
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </Box>
      {!error ? (
        <>
          <ChainInfo user={user} chain={chain} />
          <ChainPairs user={user} chain={chain} />
        </>
      ) : (
        <>
          <Typography variant="h1" color="textPrimary">
            {error}
          </Typography>
        </>
      )}
    </Box>
  );
}
