import React from 'react';
import { useDispatch } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  Theme,
  DialogActions,
  Button
} from '@material-ui/core';

import { breakpointDownSm } from '../../theme';
import { Chain } from '../../interfaces';
import { SearchInput, SearchTable } from '../Search';
import { mainSearch } from '../../api/search';
import { patchChainPairs } from '../../api/chains';
import { refetchCurrentChain } from '../../features/chainSlice';
import { postPair } from '../../api/pair';
import { Transition } from '../Transition';
import { useDebounce } from '../../utils/hooks';

interface Props {
  open: boolean;
  chainId?: number;
  languageId?: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100vh'
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  avatar: {
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    marginRight: theme.spacing(2)
  }
}));

export function ChainAddPairDialog({
  chainId,
  languageId,
  open,
  setOpen,
  maxWidth = 'lg'
}: Props) {
  const [chains, setChains] = React.useState<Chain[]>();
  const [term, setTerm] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const classes = useStyles();
  const fullScreen = useMediaQuery(breakpointDownSm);
  const dispatch = useDispatch();

  const debouncedTerm = useDebounce(term, 500);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (chainId) {
      if ((chains && chains.length) || debouncedTerm) {
        const pair = await postPair({
          name: debouncedTerm,
          isEnabled: true,
          chainId: Number(chainId),
          languageId: Number(languageId)
        });
        await patchChainPairs(chainId, [pair.id]);
        handleClose();

        await dispatch(refetchCurrentChain());
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      if (languageId && debouncedTerm.length > 2) {
        setLoading(true);
        const { chains } = await mainSearch(debouncedTerm, languageId);
        setChains(chains);
        setLoading(false);
      }
    })();
  }, [debouncedTerm, languageId]);

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Add Pair</DialogTitle>
      <DialogContent className={classes.container} dividers>
        <SearchInput className={classes.center} term={term} setTerm={setTerm} />
        <SearchTable chains={chains} term={debouncedTerm} loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          CLOSE
        </Button>
        <Button
          disabled={!debouncedTerm}
          color="primary"
          onClick={handleSubmit}
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
