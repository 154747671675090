import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  makeStyles,
  Theme,
  Card,
  CardActionArea,
  CardActions,
  Typography,
  Box,
  Icon,
  Tooltip
} from '@material-ui/core';
import { RadioButtonChecked } from '@material-ui/icons';

import { Section } from '../../interfaces';

interface Props {
  section: Section;
}

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: '30%',
    margin: theme.spacing(2, 'auto')
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },

  media: {
    height: 250
  },

  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  icon: {
    marginRight: theme.spacing(2)
  }
}));

export const SectionCard = ({ section }: Props) => {
  const classes = styles();
  const history = useHistory();

  const openSection = () => {
    history.push(`/section/${section.id}`);
  };

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={openSection}>
        <CardActions className={classes.cardActions}>
          <Typography noWrap variant="h5" component="h2">
            {section.name ?? '-'}
          </Typography>
          <Box className={classes.row}>
            <Tooltip title="Is it enabled?">
              <Icon
                className={section.isEnabled ? classes.success : classes.error}
              >
                <RadioButtonChecked />
              </Icon>
            </Tooltip>
            <Typography variant="h6">
              &nbsp;{section.foldersLength} folders
            </Typography>
          </Box>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};
