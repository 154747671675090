import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  handleQueryParams
} from './index';

import { List } from '../interfaces';

const LIST_ENDPOINT = '/list/';
const SIMPLE_LIST_ENDPOINT = '/list/simple/';
const LIST_CHAINS_ENDPOINT = '/list/chains/';
const LIST_TUTORIAL_ENDPOINT = '/list/tutorial/';
const LIST_LANGUAGE_ENDPOINT = '/list/list-language/';
const LIST_IMPORT_ENDPOINT = '/list/import/';

export const getTutorialLists = () => getRequest(LIST_TUTORIAL_ENDPOINT);
export const getListById = (
  id: number,
  languageId?: number,
  translationLanguageId?: number
) =>
  getRequest(
    LIST_ENDPOINT +
      id +
      handleQueryParams({ languageId, translationLanguageId })
  );
export const getSimpleListById = (
  id: number,
  languageId: number,
  translationLanguageId: number
) =>
  getRequest(
    SIMPLE_LIST_ENDPOINT +
      id +
      handleQueryParams({ languageId, translationLanguageId })
  );
export const getListChainsById = (
  id: number,
  languageId?: number,
  translationLanguageId?: number
) =>
  getRequest(
    LIST_CHAINS_ENDPOINT +
      id +
      handleQueryParams({ languageId, translationLanguageId })
  );
export const getListImportablePairsById = (
  id: number,
  sourceLanguageId?: number,
  destinationLanguageId?: number
) =>
  getRequest(
    LIST_IMPORT_ENDPOINT +
      id +
      handleQueryParams({ sourceLanguageId, destinationLanguageId })
  );

export const postList = (body: List) => postRequest(LIST_ENDPOINT, body);
export const patchList = (id: number, body: List) =>
  patchRequest(LIST_ENDPOINT + id, body);
export const deleteList = (id: number) => deleteRequest(LIST_ENDPOINT + id);

export const postListPair = (
  listLanguageId: number,
  body: { pairId: number; sort?: number }
) => postRequest(LIST_LANGUAGE_ENDPOINT + listLanguageId + '/pair', body);
export const patchListPair = (
  listLanguageId: number,
  body: { pairId: number; sort: number }
) => patchRequest(LIST_LANGUAGE_ENDPOINT + listLanguageId + '/pair', body);

export const patchListPairImage = (body: {
  listLanguageId1: number;
  listLanguageId2: number;
  pairId: number;
  pairIds: number[];
  chainImageId: number | null;
}) => patchRequest(LIST_LANGUAGE_ENDPOINT + 'pair/image', body);

export const deleteListPair = (
  listLanguageId: number,
  body: { pairId: number }
) => deleteRequest(LIST_LANGUAGE_ENDPOINT + listLanguageId + '/pair', body);
