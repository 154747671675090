import React from 'react';
import { useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Theme
} from '@material-ui/core';

import { selectUser } from '../../features/authSlice';
import { LanguageTableHead } from '../LanguageTableHead';
import { ListChainTableBody } from './ListChainTableBody';
import { getListChainsById } from '../../api/lists';

export interface ListChain {
  chainId: number;
  sort: number;
  isEnabled: boolean;
  imageUrl: string;
  definition1: string;
  pairsWithRegionIsMain1: string[][];
  definition2: string;
  pairsWithRegionIsMain2: string[][];
}

interface Props {
  chainMode: boolean;
  id: number;
}

const styles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    overflow: 'hidden'
  },

  tableCell: {
    border: `1px solid ${theme.palette.divider}`,
    width: '28%',
    fontSize: 16
  }
}));

export const ListChainTable = ({ id, chainMode }: Props) => {
  const [chains, setChains] = React.useState<ListChain[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const classes = styles();
  const user = useSelector(selectUser);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const fetchedChains = (
        await getListChainsById(
          id,
          user?.primaryLanguageId,
          user?.secondaryLanguageId
        )
      ).chains;
      setChains(fetchedChains);
      setLoading(false);
    })();
  }, [id, user, chainMode]);

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableCell}>
            <TableCell size="small" />
            <TableCell size="small" />
            <LanguageTableHead topLeft languageId={user?.primaryLanguageId} />
            <LanguageTableHead languageId={user?.secondaryLanguageId} />
            <LanguageTableHead topRight languageId={user?.tertiaryLanguageId} />
          </TableRow>
        </TableHead>
        <ListChainTableBody
          loading={loading}
          chains={chains}
          user={user}
          tableCellClass={classes.tableCell}
        />
      </Table>
    </TableContainer>
  );
};
