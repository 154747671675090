import React from 'react';

import {
  TableRow,
  TableCell,
  Box,
  CircularProgress,
  Typography,
  TableBody,
  Theme,
  makeStyles
} from '@material-ui/core';

import { getByLanguageId } from '../../utils/model';

import { SearchTableCell } from './SearchTableCell';

import { Chain, Pair } from '../../interfaces';
import { User } from '../../features/authSlice/interfaces';
import { IsEnabled } from '../IsEnabled';

interface Props {
  loading: boolean;
  chains?: Chain[];
  user?: User | null;
  term: string;
  tableCellClass: string;
  onSubmit?: (pairId: number) => Promise<void>;
  listPairs?: Pair[];
  tagId?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle'
  },
  definition: {
    fontWeight: 500
  },
  border: {
    border: `1px solid ${theme.palette.divider}`
  }
}));

export const SearchTableBody = ({
  loading,
  listPairs,
  tagId,
  chains,
  user,
  term,
  tableCellClass,
  onSubmit
}: Props) => {
  const classes = useStyles();

  if (loading) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={5}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {chains && chains.length
        ? chains.map((chain) => {
            const primaryChainLanguage = getByLanguageId(
              chain.chainLanguages,
              user?.primaryLanguageId
            );
            const secondaryChainLanguage = getByLanguageId(
              chain.chainLanguages,
              user?.secondaryLanguageId
            );
            const tertiaryChainLanguage = getByLanguageId(
              chain.chainLanguages,
              user?.tertiaryLanguageId
            );
            return (
              <React.Fragment key={chain.id}>
                <TableRow className={tableCellClass}>
                  <TableCell size="small" rowSpan={2}>
                    <IsEnabled
                      isEnabled={chain.isEnabled}
                      grammaticalCategoryId={chain.grammaticalCategoryId}
                    />
                  </TableCell>
                  <TableCell className={classes.border} rowSpan={2}>
                    <img
                      className={classes.image}
                      src={
                        chain.chainImages[
                          chain.chainImages.findIndex((ci) => ci.isDefault) ?? 0
                        ]?.imageUrl ?? '/logo-bw.png'
                      }
                      alt="chain"
                    />
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} ${classes.definition}`}
                  >
                    {(user && primaryChainLanguage?.definition) ?? ''}
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} ${classes.definition}`}
                  >
                    {(user && secondaryChainLanguage?.definition) ?? ''}
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} ${classes.definition}`}
                  ></TableCell>
                </TableRow>
                <TableRow className={tableCellClass}>
                  <SearchTableCell
                    onSubmit={onSubmit}
                    chainIds={chains.map((c) => c.id)}
                    listPairs={listPairs}
                    term={term}
                    tagId={tagId}
                    className={tableCellClass}
                    pairs={(user && primaryChainLanguage?.pairs) ?? []}
                  />
                  <SearchTableCell
                    onSubmit={onSubmit}
                    chainIds={chains.map((c) => c.id)}
                    listPairs={listPairs}
                    term={term}
                    tagId={tagId}
                    className={tableCellClass}
                    pairs={(user && secondaryChainLanguage?.pairs) ?? []}
                  />
                  <SearchTableCell
                    onSubmit={onSubmit}
                    chainIds={chains.map((c) => c.id)}
                    listPairs={listPairs}
                    term={term}
                    tagId={tagId}
                    className={tableCellClass}
                    pairs={(user && tertiaryChainLanguage?.pairs) ?? []}
                  />
                </TableRow>
              </React.Fragment>
            );
          })
        : term.length >= 2 && (
            <TableRow>
              <TableCell colSpan={5}>
                <Typography variant="h3">No Pairs Found.</Typography>
              </TableCell>
            </TableRow>
          )}
    </TableBody>
  );
};
