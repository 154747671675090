import MaterialTable, { Column } from '@material-table/core';
import {
  Button,
  Dialog,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  IconButton
} from '@material-ui/core';
import {
  Close,
  RotateLeft,
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from '@material-ui/icons';

import { capitalize } from 'lodash';
import React from 'react';
import {
  addUser,
  deleteUser,
  editUser,
  getAllUsers,
  resetUserPassword
} from '../api/users';
import { LANGUAGES } from '../constants/languages';
import { Roles, User } from '../features/authSlice/interfaces';

interface Props {
  user: User;
}

interface TableState {
  columns: Column<User>[];
  data: any;
}

const tableIcons: { [key: string]: any } = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowDownward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

export function ControlPanel({ user }: Props) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const usersPromise = React.useCallback(
    (newPage: number) => getAllUsers(newPage),
    []
  );
  const [table, setTable] = React.useState<TableState>({
    columns: [
      {
        title: 'Name',
        field: 'username',
        editable: 'always'
      },
      {
        title: 'Email',
        field: 'email',
        editable: 'always'
      },
      {
        title: 'Role',
        field: 'role',
        editable: 'always',
        render: (user) => capitalize(user.role),
        editComponent: (props) => (
          <Select
            value={props.rowData.role ?? ''}
            onChange={(e) => props.onChange(e.target.value)}
          >
            <MenuItem value={Roles.admin}>
              <ListItemText primary={capitalize(Roles.admin)} />
            </MenuItem>
            <MenuItem value={Roles.linguist}>
              <ListItemText primary={capitalize(Roles.linguist)} />
            </MenuItem>
          </Select>
        )
      },
      {
        title: 'Primary Language',
        field: 'primaryLanguageId',
        editable: 'always',
        editComponent: (props) => (
          <Select
            value={props.rowData.primaryLanguageId ?? ''}
            onChange={(e) => props.onChange(e.target.value)}
          >
            <MenuItem disabled={!props.rowData.primaryLanguageId} value="">
              <img width="20px" src={'/images/flags/INTL.svg'} alt="INTL" />
            </MenuItem>
            {Object.values(LANGUAGES).map((l) => (
              <MenuItem
                disabled={
                  props.rowData.secondaryLanguageId === l.id ||
                  props.rowData.tertiaryLanguageId === l.id
                }
                key={l.id}
                value={l.id}
              >
                <img width="20px" src={l.flag} alt="primaryFlag" />
              </MenuItem>
            ))}
          </Select>
        ),
        render: (user) => (
          <img
            width="20px"
            src={LANGUAGES[user.primaryLanguageId].flag}
            alt="primaryLanguageFlag"
          />
        )
      },
      {
        title: 'Secondary Language',
        field: 'secondaryLanguageId',
        editable: 'always',
        editComponent: (props) => (
          <Select
            value={props.rowData.secondaryLanguageId ?? ''}
            onChange={(e) => props.onChange(e.target.value)}
          >
            <MenuItem disabled={!props.rowData.secondaryLanguageId} value="">
              <img width="20px" src={'/images/flags/INTL.svg'} alt="INTL" />
            </MenuItem>
            {Object.values(LANGUAGES).map((l) => (
              <MenuItem
                disabled={
                  props.rowData.primaryLanguageId === l.id ||
                  props.rowData.tertiaryLanguageId === l.id
                }
                key={l.id}
                value={l.id}
              >
                <img width="20px" src={l.flag} alt="SecondaryLanguageFlag" />
              </MenuItem>
            ))}
          </Select>
        ),
        render: (user) => (
          <img
            width="20px"
            src={LANGUAGES[user.secondaryLanguageId].flag}
            alt="secondaryLanguageFlag"
          />
        )
      },
      {
        title: 'Tertiary Language',
        field: 'tertiaryLanguageId',
        editable: 'always',
        editComponent: (props) => (
          <Select
            value={props.rowData.tertiaryLanguageId ?? ''}
            onChange={(e) => props.onChange(e.target.value)}
          >
            <MenuItem disabled={!props.rowData.tertiaryLanguageId} value="">
              <img width="20px" src={'/images/flags/INTL.svg'} alt="INTL" />
            </MenuItem>
            {Object.values(LANGUAGES).map((l) => (
              <MenuItem
                disabled={
                  props.rowData.primaryLanguageId === l.id ||
                  props.rowData.secondaryLanguageId === l.id
                }
                key={l.id}
                value={l.id}
              >
                <img width="20px" src={l.flag} alt="TertiaryLanguageFlag" />
              </MenuItem>
            ))}
          </Select>
        ),
        render: (user) =>
          user.tertiaryLanguageId && (
            <img
              width="20px"
              src={LANGUAGES[user.tertiaryLanguageId].flag}
              alt="tertiaryLanguageFlag"
            />
          )
      }
    ],
    data: usersPromise
  });

  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  async function tableData(pageNum?: number) {
    if (pageNum) {
      setPage(pageNum);
    }
    const { users, totalCount } = await table.data(pageNum ?? page);
    return {
      data: users,
      totalCount,
      page: pageNum ?? page
    };
  }

  async function refreshTable(): Promise<void> {
    const data = usersPromise;
    setTable((prevTable) => ({
      ...prevTable,
      data
    }));
  }

  async function handleRowAdd(newUser: User) {
    await addUser(newUser);
    await refreshTable();
  }

  async function handleRowUpdate(newUser: User, oldUser?: User) {
    await editUser(oldUser?.id ?? newUser.id, newUser);
    await refreshTable();
  }

  async function handleRowDelete(oldUser: User) {
    await deleteUser(oldUser.id);
    await refreshTable();
  }

  async function handlePasswordReset(_e: any, user: User | User[]) {
    if (!Array.isArray(user)) {
      await resetUserPassword(user.id, user.email);
      setSnackbarOpen(true);
    }
  }

  function stopPropagationForTab(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  }

  if (user?.role === Roles.admin) {
    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          message="Password reset successfully."
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnackbarOpen(false)}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        />
        <Button variant="outlined" onClick={handleClick}>
          Control Panel
        </Button>
        <Dialog
          onKeyDown={stopPropagationForTab}
          maxWidth="xl"
          fullWidth
          open={open}
          onClose={handleClose}
        >
          <MaterialTable
            actions={[
              {
                icon: RotateLeft,
                tooltip: 'Reset Password',
                onClick: handlePasswordReset
              }
            ]}
            icons={tableIcons}
            title="Admin Control Panel"
            columns={table.columns}
            data={async (query) => tableData(query.page)}
            options={{
              filtering: false,
              sorting: false,
              search: false
            }}
            editable={{
              onRowAdd: handleRowAdd,
              onRowUpdate: handleRowUpdate,
              onRowDelete: handleRowDelete
            }}
          />
        </Dialog>
      </>
    );
  }

  return null;
}
