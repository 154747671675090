import React from 'react';
import {
  TableCell,
  Typography,
  makeStyles,
  Theme,
  IconButton
} from '@material-ui/core';
import { LANGUAGES } from '../constants/languages';
import { AddCircle } from '@material-ui/icons';

interface Props {
  languageId?: number;
  topLeft?: boolean;
  topRight?: boolean;
  onClick?: () => void;
  pairsLength?: number;
}

const styles = makeStyles((theme: Theme) => ({
  flag: {
    width: '20px',
    height: '16px',
    marginRight: theme.spacing(1)
  },
  tableHead: {
    // borderLeft: `4px solid ${theme.palette.background.default}`,
    // borderRight: `4px solid ${theme.palette.background.default}`,
    // backgroundColor: theme.palette.grey[300],
    width: '28%'
  },
  pairs: {
    fontSize: 14
  }
}));

export const LanguageTableHead = ({
  pairsLength,
  languageId,
  onClick,
  topLeft = false,
  topRight = false
}: Props) => {
  const classes = styles();

  const getLanguageName = (languageId: number): string =>
    LANGUAGES[languageId]?.name.toUpperCase();

  const borderRadius = {
    borderTopLeftRadius: topLeft ? '50px' : '0px',
    borderTopRightRadius: topRight ? '50px' : '0px'
  };

  return (
    <TableCell className={classes.tableHead} style={borderRadius}>
      <Typography align="center" variant="h5">
        <img
          className={classes.flag}
          src={
            languageId ? LANGUAGES[languageId]?.flag : '/images/flags/INTL.svg'
          }
          alt={languageId ? LANGUAGES[languageId]?.name : 'All languages'}
        />
        {(languageId && getLanguageName(languageId)) ?? 'LANGUAGE'}
        <span className={classes.pairs}>
          {pairsLength != null ? ` (${pairsLength} pairs)` : ''}
        </span>
        {Boolean(onClick) && (
          <IconButton color="secondary" onClick={onClick}>
            <AddCircle />
          </IconButton>
        )}
      </Typography>
    </TableCell>
  );
};
