import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { Close, Edit, Label } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { patchPair, patchPairInflexion, patchPairsLevel } from '../../api/pair';
import { Pair, Section } from '../../interfaces';
import { fetchVerbalForms } from '../../utils/inflexions';
import { getByLanguageId } from '../../utils/model';
import { ChainPairTagDialog } from '../Chain/ChainPairTagDialog';
import { PairChip } from '../PairChip';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  section: Section;
  refresh: () => Promise<void>;
}

const PAIR_LEVEL_COLORS = [
  'whitesmoke',
  'lightgreen',
  'lightblue',
  'lightcoral'
];

const useStyles = makeStyles((_theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& div > :first-child': {
      marginRight: '1rem'
    }
  },
  table: {
    '& th:nth-child(2)': {
      borderTopLeftRadius: '1rem'
    },
    '& th:not(:first-child)': {
      backgroundColor: 'lightgray',
      border: '2px solid white',
      textTransform: 'uppercase',
      verticalAlign: 'middle',
      textAlign: 'center',
      fontWeight: 'bold',
      color: 'gray'
    },
    '& th:last-child': {
      borderTopRightRadius: '1rem'
    },
    '& td': {
      backgroundColor: 'whitesmoke',
      border: '2px solid white',
      verticalAlign: 'middle',
      textAlign: 'center',
      fontWeight: 'bold'
    },
    '& tbody tr:first-child td:first-child': {
      borderTopLeftRadius: '1rem'
    }
  }
}));

export const SectionDialog = ({ open, setOpen, section, refresh }: Props) => {
  const [pairIds, setPairIds] = React.useState<number[]>([]);
  const [tagPair, setTagPair] = React.useState<Pair>();
  const [newLevel, setNewLevel] = React.useState<number>(0);
  const [level, setLevel] = React.useState<number>(0);
  const classes = useStyles();

  const pairs: Pair[] = React.useMemo(
    () =>
      section?.folders
        ?.map((folder) =>
          folder.lists?.map((l) =>
            (getByLanguageId(l?.listLanguages, 2)?.pairs ?? []).filter((p) =>
              level ? p.level === level : true
            )
          )
        )
        .flat(3)
        .sort((a, b) => (a.name > b.name ? 1 : -1)) ?? [],
    [section, level]
  );

  const handleCheckboxChange = (pairId: number) => {
    const newPairIds = pairIds.includes(pairId)
      ? pairIds.filter((p) => p !== pairId)
      : [...pairIds, pairId];

    setPairIds(newPairIds);
  };

  const handleLevelEdit = async () => {
    await patchPairsLevel(pairIds, newLevel);
    await refresh();
  };

  const handleRegionChange =
    (id: number, main = false) =>
    async (regionId: number | null) => {
      if (main) {
        await patchPair(id, {
          regionId
        });
      } else {
        await patchPairInflexion(id, {
          regionId
        });
      }

      await refresh();
    };

  const handleFilter = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    _child: React.ReactNode
  ) => {
    setLevel(Number(event.target.value));
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog maxWidth="xl" open={open}>
        <DialogTitle className={classes.title} disableTypography>
          <Box display="flex" alignItems="center">
            <Typography variant="h6">{section.name}</Typography>
            <Select
              value={level}
              label={<p>Filter by tag</p>}
              onChange={handleFilter}
            >
              <MenuItem value={0}>No filter</MenuItem>
              <MenuItem value={1}>Basic</MenuItem>
              <MenuItem value={2}>Intermediate</MenuItem>
              <MenuItem value={3}>Advanced</MenuItem>
            </Select>
          </Box>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  <IconButton
                    disabled={!pairIds.length}
                    onClick={handleLevelEdit}
                  >
                    <Edit />
                  </IconButton>
                  <Select
                    variant="outlined"
                    label="Update levels"
                    value={newLevel}
                    onChange={(e) => setNewLevel(Number(e.target.value))}
                  >
                    <MenuItem value={0}>No level</MenuItem>
                    <MenuItem value={1}>Basic</MenuItem>
                    <MenuItem value={2}>Intermediate</MenuItem>
                    <MenuItem value={3}>Advanced</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>Infinitive (main pair)</TableCell>
                <TableCell>Present</TableCell>
                <TableCell>Past simple</TableCell>
                <TableCell>Past participle</TableCell>
                <TableCell>Gerund</TableCell>
                <TableCell>1st present</TableCell>
                <TableCell>3rd present</TableCell>
                <TableCell>1st past simple</TableCell>
                <TableCell>3rd past simple</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pairs.map((p) => {
                return (
                  <TableRow key={p.id}>
                    <TableCell padding="checkbox" size="small">
                      <Checkbox onChange={() => handleCheckboxChange(p.id)} />
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: PAIR_LEVEL_COLORS[p.level ?? 0]
                      }}
                    >
                      <Link to={`/chain/${p.chainId}`}>
                        <img
                          src={p.imageUrl != null ? p.imageUrl : '/logo-bw.png'}
                          alt={p.name}
                          width="100"
                          height="100"
                        />
                      </Link>
                    </TableCell>
                    <TableCell>
                      <PairChip
                        deleteIcon={<Label />}
                        onDelete={() => setTagPair(p)}
                        name={p.name}
                        languageId={p.languageId}
                        regionId={p.regionId}
                        onRegionChange={handleRegionChange(p.id, true)}
                        isEnabled
                        column
                        main
                      />
                    </TableCell>
                    <TableCell>
                      {fetchVerbalForms(p.inflexions, 1, null).map((i) => (
                        <PairChip
                          key={i.id}
                          deleteIcon={<Label />}
                          onDelete={() => setTagPair(i)}
                          name={i.name}
                          languageId={i.languageId}
                          regionId={i.regionId}
                          onRegionChange={handleRegionChange(i.id)}
                          isEnabled
                          column
                        />
                      ))}
                    </TableCell>
                    <TableCell>
                      {fetchVerbalForms(p.inflexions, 2, null).map((i) => (
                        <PairChip
                          key={i.id}
                          deleteIcon={<Label />}
                          onDelete={() => setTagPair(i)}
                          name={i.name}
                          languageId={i.languageId}
                          regionId={i.regionId}
                          onRegionChange={handleRegionChange(i.id)}
                          isEnabled
                          column
                        />
                      ))}
                    </TableCell>
                    <TableCell>
                      {fetchVerbalForms(p.inflexions, 3, null).map((i) => (
                        <PairChip
                          key={i.id}
                          deleteIcon={<Label />}
                          onDelete={() => setTagPair(i)}
                          name={i.name}
                          languageId={i.languageId}
                          regionId={i.regionId}
                          onRegionChange={handleRegionChange(i.id)}
                          isEnabled
                          column
                        />
                      ))}
                    </TableCell>
                    <TableCell>
                      {fetchVerbalForms(p.inflexions, 4, null).map((i) => (
                        <PairChip
                          key={i.id}
                          deleteIcon={<Label />}
                          onDelete={() => setTagPair(i)}
                          name={i.name}
                          languageId={i.languageId}
                          regionId={i.regionId}
                          onRegionChange={handleRegionChange(i.id)}
                          isEnabled
                          column
                        />
                      ))}
                    </TableCell>
                    <TableCell>
                      {fetchVerbalForms(p.inflexions, 1, 1).map((i) => (
                        <PairChip
                          key={i.id}
                          deleteIcon={<Label />}
                          onDelete={() => setTagPair(i)}
                          name={i.name}
                          languageId={i.languageId}
                          regionId={i.regionId}
                          onRegionChange={handleRegionChange(i.id)}
                          isEnabled
                          column
                        />
                      ))}
                    </TableCell>
                    <TableCell>
                      {fetchVerbalForms(p.inflexions, 1, 3).map((i) => (
                        <PairChip
                          key={i.id}
                          deleteIcon={<Label />}
                          onDelete={() => setTagPair(i)}
                          name={i.name}
                          languageId={i.languageId}
                          regionId={i.regionId}
                          onRegionChange={handleRegionChange(i.id)}
                          isEnabled
                          column
                        />
                      ))}
                    </TableCell>
                    <TableCell>
                      {fetchVerbalForms(p.inflexions, 2, 1).map((i) => (
                        <PairChip
                          key={i.id}
                          deleteIcon={<Label />}
                          onDelete={() => setTagPair(i)}
                          name={i.name}
                          languageId={i.languageId}
                          regionId={i.regionId}
                          onRegionChange={handleRegionChange(i.id)}
                          isEnabled
                          column
                        />
                      ))}
                    </TableCell>
                    <TableCell>
                      {fetchVerbalForms(p.inflexions, 2, 3).map((i) => (
                        <PairChip
                          key={i.id}
                          deleteIcon={<Label />}
                          onDelete={() => setTagPair(i)}
                          name={i.name}
                          languageId={i.languageId}
                          regionId={i.regionId}
                          onRegionChange={handleRegionChange(i.id)}
                          isEnabled
                          column
                        />
                      ))}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
      <ChainPairTagDialog
        open={Boolean(tagPair)}
        setOpen={(_) => setTagPair(undefined)}
        pair={tagPair}
        refresh={false}
        setPair={(_) => refresh()}
        levelControl={!tagPair?.mainPairId}
      />
    </>
  );
};
