import { getRequest, postRequest, patchRequest, deleteRequest } from './index';

import { Chain } from '../interfaces';

const GET_CHAIN_ENDPOINT = '/chain/';

const POST_CHAIN_ENDPOINT = '/chain';
const PATCH_CHAIN_ENDPOINT = '/chain/';

const POST_CHAIN_PAIRS_ENDPOINT = '/chain/pairs/';

const POST_CHAIN_IMAGES_ENDPOINT = '/chain/images/';
const UPDATE_DEFAULT_CHAIN_IMAGE_ENDPOINT = '/chain/images/default/';
const DELETE_CHAIN_IMAGE_ENDPOINT = '/chain/images/';

export const getChainById = (id: Number) => getRequest(GET_CHAIN_ENDPOINT + id);
export const postChain = (data: Chain) =>
  postRequest(POST_CHAIN_ENDPOINT, data);
export const patchChain = (id: number, data?: Chain) =>
  patchRequest(PATCH_CHAIN_ENDPOINT + id, data);

export const patchChainPairs = (id: number, pairIds: number[]) =>
  patchRequest(POST_CHAIN_PAIRS_ENDPOINT + id, { pairIds });

export const postChainImages = (data: { pictures: File[] }, id: number) =>
  postRequest(
    POST_CHAIN_IMAGES_ENDPOINT + id,
    data,
    data.pictures && data.pictures.length > 0
  );
export const updateDefaultChainImage = (chainImageId: number) =>
  patchRequest(UPDATE_DEFAULT_CHAIN_IMAGE_ENDPOINT + chainImageId);
export const deleteChainImage = (id: Number) =>
  deleteRequest(DELETE_CHAIN_IMAGE_ENDPOINT + id);
