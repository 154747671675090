import React from 'react';

import { TextField, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { autocompleteSearch, getByModelId } from '../api/search';

import { AutocompleteModel } from '../interfaces';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/authSlice';

interface Props<T> {
  label: string;
  nameGetter: (model: T) => string | undefined;
  onChange: (value: T | null) => void;
  defaultValueId?: number;
}

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(2)
  },
  labelText: {
    display: 'flex',
    alignItems: 'center'
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
}));

export function AsyncAutocomplete<T>({
  label,
  defaultValueId,
  nameGetter,
  onChange
}: Props<T>) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<T[]>([]);
  const user = useSelector(selectUser);

  React.useEffect(() => {
    if (defaultValueId) {
      (async () => {
        const fetchedDefaultValue = await getByModelId<T>(
          defaultValueId,
          label.toLowerCase() as AutocompleteModel
        );
        setInputValue(nameGetter(fetchedDefaultValue) ?? '');
        setOptions(fetchedDefaultValue ? [fetchedDefaultValue] : []);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValueId]);

  React.useEffect(() => {
    if (inputValue !== '') {
      (async () => {
        const results = await autocompleteSearch<T>(
          inputValue,
          label.toLowerCase() as AutocompleteModel,
          user?.primaryLanguageId
        );

        let newOptions = [] as T[];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      })();
    }
  }, [label, user?.primaryLanguageId, inputValue]);

  return (
    <Autocomplete
      fullWidth
      classes={{ option: classes.option }}
      getOptionLabel={(option) => nameGetter(option) ?? ''}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      onChange={(_event: unknown, newValue: T | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(_event: unknown, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      renderOption={(option: any) => (
        <React.Fragment>
          <span>{option.id.toString()}</span>
          {nameGetter(option)}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="none"
          className={classes.input}
          variant="outlined"
          label={label}
        />
      )}
    />
  );
}
