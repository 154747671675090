import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  makeStyles,
  Theme,
  IconButton,
  Typography,
  Avatar,
  Card,
  CircularProgress,
  CardContent,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme
} from '@material-ui/core';
import { Add, ArrowBackIos, Close } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import { UnitLanguage } from '../interfaces';
import { selectUser } from '../features/authSlice';
import { getByLanguageId } from '../utils/model';
import { ListForm } from '../components/List/ListForm';
import { UnitForm } from '../components/Unit/UnitForm';
import { DialogResponsive } from '../components/DialogResponsive';
import {
  loadUnitById,
  selectLoading,
  selectUnitsById,
  setCurrentUnitId,
  refetchCurrentUnit
} from '../features/unitSlice';
import { ListCard } from '../components/List/ListCard';
import { TITLE } from '../constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    margin: theme.spacing(2, 0)
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    verticalAlign: 'middle',
    padding: theme.spacing(2)
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    verticalAlign: 'middle',
    padding: theme.spacing(2, 2)
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  headerWrapper: {
    margin: theme.spacing(4, 0),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0),
    color: theme.palette.common.white
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 12),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark
  },
  imageContainer: {
    position: 'absolute',
    right: '5%'
  },
  avatar: {
    position: 'relative',
    overflow: 'visible',
    borderRadius: '100%',
    width: '12.5rem',
    height: '12.5rem',
    backgroundColor: theme.palette.background.default,
    border: `1.5rem solid ${theme.palette.background.default}`
  },
  image: {
    position: 'absolute',
    borderRadius: '100%',
    objectFit: 'cover',
    display: 'block',
    width: '12rem',
    height: '12rem',
    padding: '0.25rem'
  },
  listsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  listWrapper: {
    width: '80%',
    margin: theme.spacing(2, 2)
  },
  folderCard: {
    margin: theme.spacing(2, 0),
    width: '100%'
  }
}));

export const Unit = () => {
  const { id } = useParams<{ id: string }>();
  const [open, setOpen] = React.useState<boolean>(false);
  const unit = useSelector(selectUnitsById)[id];
  const loading = useSelector(selectLoading);
  const user = useSelector(selectUser);
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goBack = () => {
    history.push(`/course/${unit.courseId}#${unit.levelId}`);
  };

  const goToList = (listId: number) => {
    history.push(`/list/${listId}`);
  };

  const afterSubmit = async () => {
    await dispatch(refetchCurrentUnit());
  };

  const contrastColor = React.useMemo(
    () =>
      unit?.levelPrimaryColor
        ? theme.palette.getContrastText(unit?.levelPrimaryColor)
        : '#000000',
    [theme.palette, unit?.levelPrimaryColor]
  );

  React.useEffect(() => {
    dispatch(setCurrentUnitId(Number(id)));
    dispatch(loadUnitById(Number(id)));
    document.title = `Unit ${id} | ${TITLE}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  if (loading) {
    return (
      <Box m={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box
        className={classes.headerWrapper}
        style={{ backgroundColor: unit?.levelTertiaryColor }}
      >
        <Box
          className={classes.headerContainer}
          style={{ backgroundColor: unit?.levelSecondaryColor }}
        >
          <Box
            className={classes.header}
            style={{ backgroundColor: unit?.levelPrimaryColor }}
          >
            <Box display="flex" alignItems="center" flexBasis="20%">
              <IconButton onClick={goBack} style={{ color: contrastColor }}>
                <ArrowBackIos color="inherit" />
              </IconButton>
              <DialogResponsive
                color="secondary"
                variant="contained"
                afterSubmit={afterSubmit}
                title="Edit Unit"
                maxWidth="md"
                FormComponent={(formProps) => (
                  <UnitForm unit={unit} {...formProps} />
                )}
              />
            </Box>
            <Typography variant="h3" style={{ color: contrastColor }}>
              {
                getByLanguageId<UnitLanguage>(
                  unit?.unitLanguages,
                  user?.primaryLanguageId
                )?.name
              }
            </Typography>
            <Box flexBasis="20%" />
            <Box className={classes.imageContainer}>
              <Avatar
                className={classes.avatar}
                src={unit?.imageUrl ?? '/logo-bw.png'}
                imgProps={{
                  className: classes.image,
                  style: {
                    border: `1rem solid ${unit?.levelPrimaryColor ?? grey[500]}`
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.listWrapper}>
        <Card className={classes.folderCard}>
          <CardContent className={classes.listsWrapper}>
            {unit?.lists && unit.lists.length
              ? unit.lists.map((list) => (
                  <ListCard
                    key={list.id}
                    user={user}
                    list={list}
                    onClick={() => goToList(list.id)}
                  />
                ))
              : undefined}
            <Tooltip title="Add">
              <IconButton color="primary" size="medium" onClick={handleOpen}>
                <Add />
              </IconButton>
            </Tooltip>
          </CardContent>
        </Card>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Create List
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <ListForm
            afterSubmit={handleClose}
            onClose={handleClose}
            unitId={Number(id)}
            maxSort={Math.max(...(unit?.lists?.map((l) => l.sort) ?? [0, 1]))}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
