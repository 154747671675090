import React from 'react';

import {
  Box,
  TableRow,
  TableCell,
  IconButton,
  TextField,
  Tooltip
} from '@material-ui/core';
import {
  CloseOutlined,
  ArrowBackIos,
  ArrowForwardIos,
  RotateLeft
} from '@material-ui/icons';

import { SimpleListPair } from '../../interfaces';
import { PairChip } from '../PairChip';
import { patchListPairImage } from '../../api/lists';

interface Props {
  row: SimpleListPair;
  classes: Record<string, string>;
  tableCellClass?: string;
  mainClass?: string;
  handleChange?: (pairId: number, sort: number) => void;
  handleDelete?: (pairId: number) => void;
  primaryListLanguageId?: number;
  secondaryListLanguageId?: number;
}

export const ListTableSimpleCell = ({
  row,
  classes,
  tableCellClass,
  mainClass,
  handleChange,
  handleDelete,
  primaryListLanguageId,
  secondaryListLanguageId
}: Props) => {
  const defaultImageIndex = React.useMemo(
    () => row.chainImages.findIndex((ci) => ci[2] === 'true'),
    [row.chainImages]
  );

  const isReset = React.useMemo(
    () => row.chainImageId == null,
    [row.chainImageId]
  );

  const [imageIndex, setImageIndex] = React.useState<number>(() => {
    const selectedImageIndex = row.chainImages.findIndex(
      (ci) => ci[1] === row.imageUrl
    );
    return selectedImageIndex > -1
      ? selectedImageIndex
      : defaultImageIndex ?? 0;
  });

  const currentImage = React.useMemo(
    () => row.chainImages[imageIndex],
    [row, imageIndex]
  );

  const previousImage = async () => {
    const previousImageIndex = imageIndex - 1;
    const chainImageId = Number(row.chainImages[previousImageIndex][0]);
    if (previousImageIndex >= 0) {
      row.chainImageId = chainImageId;
      setImageIndex(previousImageIndex);
      if (primaryListLanguageId && secondaryListLanguageId) {
        await patchListPairImage({
          listLanguageId1: primaryListLanguageId,
          listLanguageId2: secondaryListLanguageId,
          pairId: row.pairId,
          pairIds:
            row.translationPairsWithRegion?.map((pair) => Number(pair[0])) ??
            [],
          chainImageId
        });
      }
    }
  };

  const resetImage = async () => {
    setImageIndex(defaultImageIndex);
    row.chainImageId = null;
    if (primaryListLanguageId && secondaryListLanguageId) {
      await patchListPairImage({
        listLanguageId1: primaryListLanguageId,
        listLanguageId2: secondaryListLanguageId,
        pairId: row.pairId,
        pairIds:
          row.translationPairsWithRegion?.map((pair) => Number(pair[0])) ?? [],
        chainImageId: null
      });
    }
  };

  const nextImage = async () => {
    const nextImageIndex = imageIndex + 1;
    const chainImageId = Number(row.chainImages[nextImageIndex][0]);
    if (nextImageIndex < (row.chainImages.length ?? 0)) {
      setImageIndex(nextImageIndex);
      row.chainImageId = chainImageId;
      if (primaryListLanguageId && secondaryListLanguageId) {
        await patchListPairImage({
          listLanguageId1: primaryListLanguageId,
          listLanguageId2: secondaryListLanguageId,
          pairId: row.pairId,
          pairIds:
            row.translationPairsWithRegion?.map((pair) => Number(pair[0])) ??
            [],
          chainImageId
        });
      }
    }
  };

  return (
    <React.Fragment key={row.pairId}>
      <TableRow className={classes.border}>
        <TableCell size="small">
          <IconButton
            color="secondary"
            onClick={() => handleDelete && handleDelete(row.pairId)}
          >
            <CloseOutlined />
          </IconButton>
        </TableCell>
        <TableCell className={classes.border} size="small">
          <TextField
            onChange={(e) =>
              handleChange && handleChange(row.pairId, Number(e.target.value))
            }
            variant="outlined"
            type="number"
            defaultValue={row.pairSort}
          />
        </TableCell>
        <TableCell className={classes.imageCell}>
          <Box className={classes.imageContainer}>
            <img
              className={classes.image}
              src={currentImage ? currentImage[1] : '/logo-bw.png'}
              alt="chain"
            />
            <IconButton
              disabled={!(imageIndex - 1 >= 0)}
              onClick={previousImage}
              className={classes.previousButton}
              color="secondary"
            >
              <ArrowBackIos />
            </IconButton>
            <Tooltip arrow title="Reset default image">
              <span className={classes.resetButton}>
                <IconButton
                  disabled={isReset}
                  onClick={resetImage}
                  color="secondary"
                >
                  <RotateLeft />
                </IconButton>
              </span>
            </Tooltip>
            <IconButton
              disabled={!(imageIndex + 1 < (row?.chainImages.length ?? 0))}
              onClick={nextImage}
              className={classes.nextButton}
              color="secondary"
            >
              <ArrowForwardIos />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell
          className={`${tableCellClass ?? classes.tableCell} ${mainClass}`}
        >
          <PairChip
            chainId={row.chainId}
            name={row.pairName}
            regionId={Number(row.pairRegionId)}
            main={row.pairMain}
            isEnabled
          />
        </TableCell>
        <TableCell className={tableCellClass ?? classes.tableCell}>
          <Box display="flex" alignItems="start" flexDirection="column">
            {row.translationPairsWithRegion?.length ? (
              row.translationPairsWithRegion?.map((translation) => {
                const [id, name, regionId, main] = translation;
                return (
                  <PairChip
                    chainId={row.chainId}
                    key={id}
                    name={name}
                    regionId={Number(regionId)}
                    main={main === 'true'}
                    isEnabled
                    column
                  />
                );
              })
            ) : (
              <PairChip isEnabled column />
            )}
          </Box>
        </TableCell>
        <TableCell className={tableCellClass ?? classes.tableCell}>
          <PairChip isEnabled />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
