import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  selectLoading,
  selectError,
  selectAllCoursesIds,
  selectCoursesById,
  loadCourses
} from '../features/courseSlice';
import { CourseCard, CourseForm } from '../components/Course';
import { FabDialog } from '../components/FabDialog';
import { TITLE } from '../constants';
import { Link } from 'react-router-dom';

export function Courses() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const allCoursesIds = useSelector(selectAllCoursesIds);
  const coursesById = useSelector(selectCoursesById);

  React.useEffect(() => {
    document.title = `Courses | ${TITLE}`;
    dispatch(loadCourses());
  }, [dispatch]);

  if (loading) {
    return (
      <Box p={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={2}>
        <Typography variant="h1" color="textPrimary">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={2} textAlign="center">
      <Typography variant="h1" color="textPrimary">
        Courses
      </Typography>

      <FabDialog
        isNew
        title="Create a new Course"
        maxWidth="md"
        FormComponent={(formProps) => (
          <CourseForm
            {...formProps}
            maxSort={Math.max(
              ...((allCoursesIds ?? []).map((id) => coursesById[id].sort) ?? [])
            )}
          />
        )}
      />

      <Link to="/tutorial">
        <Button variant="contained" color="primary" size="large">
          Tutorial
        </Button>
      </Link>

      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        p={2}
        marginLeft={4}
        marginRight={4}
      >
        {(allCoursesIds ?? []).map((id) => {
          const course = coursesById[id];
          return <CourseCard key={id} {...course} />;
        })}
      </Box>
    </Box>
  );
}
