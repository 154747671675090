import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  loadAreas,
  selectAllAreasIds,
  selectAreasById,
  selectLoading,
  selectError
} from '../features/areaSlice';
import { FabDialog } from '../components/FabDialog';
import { TITLE } from '../constants';
import { AreaCard, AreaForm } from '../components/Area';

export function Areas() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const allAreasIds = useSelector(selectAllAreasIds);
  const areasById = useSelector(selectAreasById);

  React.useEffect(() => {
    document.title = `Areas | ${TITLE}`;
    dispatch(loadAreas());
  }, [dispatch]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography variant="h1" color="textPrimary">
        {error}
      </Typography>
    );
  }

  return (
    <Box p={2} textAlign="center">
      <Typography variant="h1" color="textPrimary">
        Areas
      </Typography>

      <FabDialog
        isNew
        title="Create a new Area"
        maxWidth="md"
        FormComponent={(formProps) => (
          <AreaForm
            {...formProps}
            maxSort={Math.max(
              ...((allAreasIds ?? []).map((id) => areasById[id].sort) ?? [])
            )}
          />
        )}
      />

      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        p={2}
        marginLeft={4}
        marginRight={4}
      >
        {(allAreasIds ?? []).map((id) => {
          const area = areasById[id];

          return <AreaCard key={id} {...area} />;
        })}
      </Box>
    </Box>
  );
}
