import React from 'react';

import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

import { List } from '../../interfaces';

interface Props {
  list?: List;
}

export const ListUri = ({ list }: Props) => {
  const uri = React.useMemo(
    () => (list ? `https://www.wordlist.com/r/list/${list.id}` : ''),
    [list]
  );

  const copyUri = () => navigator.clipboard?.writeText(uri);

  return (
    <Box>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="list-uri">URI</InputLabel>
        <OutlinedInput
          id="list-uri"
          type="text"
          value={uri}
          readOnly
          endAdornment={
            <Box>
              <Divider orientation="vertical" />
              <InputAdornment position="end">
                <IconButton
                  aria-label="copy list uri"
                  onClick={copyUri}
                  edge="end"
                >
                  <FileCopy />
                </IconButton>
              </InputAdornment>
            </Box>
          }
        />
      </FormControl>
    </Box>
  );
};
