import React, { ReactNode, FunctionComponent } from 'react';
import { IconButton, Menu, Button } from '@material-ui/core';

interface Props {
  children: ReactNode;
  Icon?: FunctionComponent;
  text?: string;
}

export const AppBarButton = ({ children, Icon, text }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {Icon ? (
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <Icon />
        </IconButton>
      ) : (
        <Button
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          {text}
        </Button>
      )}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={openMenu}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </>
  );
};
