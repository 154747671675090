import React, { useState, ReactElement } from 'react';

import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  useMediaQuery,
  Theme,
  Tooltip
} from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';

import { breakpointDownSm } from '../theme';
import { Transition } from './Transition';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10
  }
}));

interface PropsI {
  FormComponent: (props: any) => ReactElement;
  title: string;
  isNew?: boolean;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

export function FabDialog({
  FormComponent,
  isNew = false,
  title,
  maxWidth
}: PropsI) {
  const fullScreen = useMediaQuery(breakpointDownSm);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    handleClose();
  };

  return (
    <div>
      <Tooltip title="Add" aria-label="add">
        <Fab
          className={classes.fab}
          color="secondary"
          aria-label="add"
          onClick={handleClickOpen}
        >
          {isNew ? <Add /> : <Edit />}
        </Fab>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <FormComponent afterSubmit={handleSubmit} onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
