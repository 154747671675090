import { Area } from '../interfaces';
import { getRequest, postRequest, patchRequest, deleteRequest } from './index';

const GET_AREAS_ENDPOINT = '/area/all';
const GET_AREA_ENDPOINT = '/area/';
const POST_AREAS_ENDPOINT = '/area/';
const PATCH_AREA_ENDPOINT = '/area/';
const DELETE_AREA_ENDPOINT = '/area/';

export const getAreas = () => getRequest(GET_AREAS_ENDPOINT);
export const getAreaById = (id: Number) => getRequest(GET_AREA_ENDPOINT + id);
export const postArea = (data: Area) =>
  postRequest(POST_AREAS_ENDPOINT, data, true);
export const patchArea = (data: Area, id: Number) =>
  patchRequest(PATCH_AREA_ENDPOINT + id, data, true);
export const deleteArea = (id: Number) =>
  deleteRequest(DELETE_AREA_ENDPOINT + id);
