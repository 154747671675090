interface GrammaticalCategory {
  id: number;
  abbreviation: string;
  grammaticalCategoryLanguages: GrammaticalCategoryLanguage[];
}

interface GrammaticalCategoryLanguage {
  id: number;
  languageId: number;
  name: string;
}

export const GRAMMATICAL_CATEGORIES: { [id: string]: GrammaticalCategory } = {
  '1': {
    id: 1,
    abbreviation: 'n.',
    grammaticalCategoryLanguages: [
      {
        id: 1,
        name: 'nombre',
        languageId: 1
      },
      {
        id: 2,
        name: 'noun',
        languageId: 2
      }
    ]
  },
  '2': {
    id: 2,
    abbreviation: 'v.',
    grammaticalCategoryLanguages: [
      {
        id: 3,
        name: 'verbo',
        languageId: 1
      },
      {
        id: 4,
        name: 'verb',
        languageId: 2
      }
    ]
  },
  '3': {
    id: 3,
    abbreviation: 'adv.',
    grammaticalCategoryLanguages: [
      {
        id: 5,
        name: 'adverbio',
        languageId: 1
      },
      {
        id: 6,
        name: 'adverb',
        languageId: 2
      }
    ]
  },
  '4': {
    id: 4,
    abbreviation: 'adj.',
    grammaticalCategoryLanguages: [
      {
        id: 7,
        name: 'adjetivo',
        languageId: 1
      },
      {
        id: 8,
        name: 'adjective',
        languageId: 2
      }
    ]
  },
  '5': {
    id: 5,
    abbreviation: 'art.',
    grammaticalCategoryLanguages: [
      {
        id: 9,
        name: 'articulo',
        languageId: 1
      },
      {
        id: 10,
        name: 'article',
        languageId: 2
      }
    ]
  },
  '6': {
    id: 6,
    abbreviation: 'pr.',
    grammaticalCategoryLanguages: [
      {
        id: 11,
        name: 'pronombre',
        languageId: 1
      },
      {
        id: 12,
        name: 'pronoun',
        languageId: 2
      }
    ]
  },
  '7': {
    id: 7,
    abbreviation: 'cl.',
    grammaticalCategoryLanguages: [
      {
        id: 13,
        name: 'clasificador',
        languageId: 1
      },
      {
        id: 14,
        name: 'classifier',
        languageId: 2
      }
    ]
  },
  '8': {
    id: 8,
    abbreviation: 'exp.',
    grammaticalCategoryLanguages: [
      {
        id: 15,
        name: 'expresión',
        languageId: 1
      },
      {
        id: 16,
        name: 'phrase',
        languageId: 2
      }
    ]
  },
  '9': {
    id: 9,
    abbreviation: 'int.',
    grammaticalCategoryLanguages: [
      {
        id: 17,
        name: 'interjección',
        languageId: 1
      },
      {
        id: 18,
        name: 'interjection',
        languageId: 2
      }
    ]
  },
  '10': {
    id: 10,
    abbreviation: 'part.',
    grammaticalCategoryLanguages: [
      {
        id: 19,
        name: 'partícula',
        languageId: 1
      },
      {
        id: 20,
        name: 'particle',
        languageId: 2
      }
    ]
  },
  '11': {
    id: 11,
    abbreviation: 'conj.',
    grammaticalCategoryLanguages: [
      {
        id: 21,
        name: 'conjunción',
        languageId: 1
      },
      {
        id: 22,
        name: 'conjunction',
        languageId: 2
      }
    ]
  },
  '12': {
    id: 12,
    abbreviation: 'loc.',
    grammaticalCategoryLanguages: [
      {
        id: 23,
        name: 'locución',
        languageId: 1
      },
      {
        id: 24,
        name: 'phrase',
        languageId: 2
      }
    ]
  },
  '13': {
    id: 13,
    abbreviation: 'prep.',
    grammaticalCategoryLanguages: [
      {
        id: 26,
        name: 'preposition',
        languageId: 2
      },
      {
        id: 25,
        name: 'preposición',
        languageId: 1
      }
    ]
  },
  '14': {
    id: 14,
    abbreviation: 'det.',
    grammaticalCategoryLanguages: [
      {
        id: 27,
        name: 'determinante',
        languageId: 1
      },
      {
        id: 28,
        name: 'determiner',
        languageId: 2
      }
    ]
  },
  '15': {
    id: 15,
    abbreviation: 'exc.',
    grammaticalCategoryLanguages: [
      {
        id: 29,
        name: 'exclamación',
        languageId: 1
      },
      {
        id: 30,
        name: 'exclamation',
        languageId: 2
      }
    ]
  },
  '16': {
    id: 16,
    abbreviation: 'phr.',
    grammaticalCategoryLanguages: [
      {
        id: 31,
        name: 'phrase',
        languageId: 1
      },
      {
        id: 32,
        name: 'phrase',
        languageId: 2
      }
    ]
  },
  '17': {
    id: 17,
    abbreviation: 'idm.',
    grammaticalCategoryLanguages: [
      {
        id: 33,
        name: 'modismo',
        languageId: 1
      },
      {
        id: 34,
        name: 'idiom',
        languageId: 2
      }
    ]
  },
  '18': {
    id: 18,
    abbreviation: '-',
    grammaticalCategoryLanguages: [
      {
        id: 35,
        name: 'Sin Categoría Gramatical',
        languageId: 1
      },
      {
        id: 36,
        name: 'No Grammatical Category',
        languageId: 2
      }
    ]
  }
};
