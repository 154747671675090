import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import socketIOClient from 'socket.io-client';

import { selectUser } from '../features/authSlice';
import { ChatMessage, ChatEvent } from '../interfaces/socket';
import { SERVER_URL, SOCKET_PATH } from '../constants';

/**
 * This hook was created from Dan Abramov's official blog recommendations.
 * Running the debounce function when changing inputs so we can properly wait for values to be set in the state.
 */
export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

/**
 * Get query parameters out of the URL.
 */
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

/**
 * Use a Socket connection to provide a functional chat on each roomId.
 * @param roomId The id of the room.
 * @param fetch A function to fetch messages.
 */
export const useChat = (
  roomId?: string,
  fetch?: (roomId: string) => Promise<ChatMessage[]>,
  fetchedMessages?: ChatMessage[]
) => {
  const [messages, setMessages] = React.useState<ChatMessage[]>(
    fetchedMessages ?? []
  );
  const [loading, setLoading] = React.useState<boolean>(Boolean(roomId));
  const socketRef = React.useRef<typeof socketIOClient.Socket>();
  const user = useSelector(selectUser);

  /**
   * Fetch previous messages.
   */
  const fetchMessages = React.useCallback(async (): Promise<void> => {
    setLoading(true);
    if (roomId && fetch) {
      const messages: ChatMessage[] = await fetch(roomId);
      setMessages(messages);
    }
    setLoading(false);
  }, [fetch, roomId]);

  /**
   * Sends a message to the server that forwards it to all users in the same room.
   * @param {string} messageText Message to be sent.
   */
  const sendMessage = (messageText: string) => {
    if (roomId) {
      socketRef.current?.emit(ChatEvent.MESSAGE, {
        text: messageText,
        username: user?.username,
        userId: user?.id
      });
    }
  };

  /**
   * Deletes a message on the DB and the socket scenarios.
   * @param {string} messageId The id of the message to be deleted.
   */
  const deleteMessage = (messageId: string) => {
    if (roomId) {
      socketRef.current?.emit(ChatEvent.DELETE, messageId);
    }
  };

  React.useEffect(() => {
    if (roomId) {
      socketRef.current = socketIOClient(SERVER_URL, {
        path: SOCKET_PATH,
        transports: ['websocket'],
        query: { roomId }
      });

      socketRef.current.on(ChatEvent.MESSAGE, (message: ChatMessage) => {
        const incomingMessage = {
          ...message,
          owned: message.userId === user?.id
        };
        setMessages((messages) => [...messages, incomingMessage]);
      });

      socketRef.current.on(ChatEvent.DELETE, (messageId: string) => {
        setMessages((messages) =>
          messages.filter((msg) => msg.id !== messageId)
        );
      });

      fetchMessages();

      return () => {
        socketRef.current?.disconnect();
      };
    }
  }, [user?.id, fetchMessages, roomId]);

  return { messages, sendMessage, deleteMessage, loading };
};
