import React from 'react';
import { useDispatch } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import { Apps, Delete } from '@material-ui/icons';
import {
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip
} from '@material-ui/core';
import { postChainImages, deleteChainImage } from '../api/chains';
import { ChainImage } from '../interfaces';
import { updateChainAction } from '../features/chainSlice';

interface Props {
  chainId?: number;
  images?: ChainImage[];
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper
    },
    uploadButton: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    gridList: {
      width: 500,
      height: 450
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)'
    }
  })
);

export default function ImagesDialog({
  chainId,
  images: imagesProps = [],
  disabled = false
}: Props) {
  const classes = useStyles();
  const [images, setImages] = React.useState(imagesProps);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (chainId && files && files.length > 0) {
      const newImages = await postChainImages(
        { pictures: Array.from(files) },
        chainId
      );
      const chainImages = [...images, ...newImages];
      setImages(chainImages);
      dispatch(updateChainAction({ id: chainId, chainImages }));
    }
  };

  const handleFileClick = async (imageId: number) => {
    await deleteChainImage(imageId);
    const chainImages = images.filter((i: any) => i.id !== imageId);
    setImages(chainImages);
    dispatch(updateChainAction({ id: chainId, chainImages }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip
        title={
          disabled ? 'Save the chain first to manage images' : 'Manage images'
        }
      >
        <span>
          <IconButton
            disabled={disabled}
            className={classes.uploadButton}
            color="primary"
            onClick={handleClickOpen}
          >
            <Apps />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>Images</DialogTitle>
        <DialogContent className={classes.root}>
          <GridList cellHeight={180} className={classes.gridList}>
            {images.map(({ id, imageUrl }: any) => (
              <GridListTile key={imageUrl}>
                <img src={imageUrl} alt="ChainImages" />
                <GridListTileBar
                  actionIcon={
                    <IconButton
                      onClick={() => handleFileClick(id)}
                      className={classes.icon}
                    >
                      <Delete />
                    </IconButton>
                  }
                />
              </GridListTile>
            ))}
          </GridList>
        </DialogContent>
        <Button fullWidth variant="contained" component="label">
          Upload Image
          <input
            onChange={handleFileChange}
            type="file"
            multiple
            style={{ display: 'none' }}
            accept="image/*"
          />
        </Button>
      </Dialog>
    </div>
  );
}
