export const formatTimestampToTime = (timestamp: number): string => {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit'
  };
  return Intl.DateTimeFormat('es', options).format(timestamp);
};

export const formatTimestampToDate = (timestamp: number): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };
  return Intl.DateTimeFormat('es', options).format(timestamp);
};
