import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ResponsiveDrawer } from './components/Drawer';
import { MainRouter } from './components/MainRouter';
import { appRoutes } from './constants/routes';
import { ProtectedRoute } from './components/ProtectedRoute';
import { selectAllCoursesIds, loadCourses } from './features/courseSlice';
import { selectAllAreasIds, loadAreas } from './features/areaSlice';
import { selectIsAuthenticated } from './features/authSlice';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { muiTheme } from './theme';
import { Snackbar, ThemeProvider, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { clearError, selectError } from './features/errorSlice';
import {
  fetchChainTags,
  fetchPairTags,
  selectChainTags,
  selectPairTags
} from './features/tagSlice';
import {
  loadDefinitions,
  selectDefinitions
} from './features/definitionsSlice';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const error = useSelector(selectError);

  const allCoursesIds = useSelector(selectAllCoursesIds);
  const allAreasIds = useSelector(selectAllAreasIds);
  const pairTags = useSelector(selectPairTags);
  const chainTags = useSelector(selectChainTags);
  const definitions = useSelector(selectDefinitions);

  const handleError = () => {
    dispatch(clearError());
  };

  // Load definitions when user logs in so we have areas and definitions loaded beforehand
  useEffect(() => {
    if (isAuthenticated && (!definitions || !definitions.genders.length)) {
      dispatch(loadDefinitions());
    }
  }, [dispatch, definitions, isAuthenticated]);

  // Load tags when user logs in so we have areas and tags loaded beforehand
  useEffect(() => {
    if (isAuthenticated && (!pairTags || !pairTags.length)) {
      dispatch(fetchPairTags());
    }
  }, [dispatch, pairTags, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && (!chainTags || !chainTags.length)) {
      dispatch(fetchChainTags());
    }
  }, [dispatch, chainTags, isAuthenticated]);

  // Load courses when user logs in so we have areas and courses loaded beforehand
  useEffect(() => {
    if (isAuthenticated && !allCoursesIds) {
      dispatch(loadCourses());
    }
  }, [dispatch, allCoursesIds, isAuthenticated]);

  // Load areas when user logs in so we have areas and courses loaded beforehand
  useEffect(() => {
    if (isAuthenticated && !allAreasIds) {
      dispatch(loadAreas());
    }
  }, [dispatch, allAreasIds, isAuthenticated]);

  const theme = React.useMemo(
    () => muiTheme(prefersDarkMode),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ResponsiveDrawer />

        <MainRouter>
          <Switch>
            {appRoutes.map(({ path, page, isProtected }) => {
              const RouteComp = isProtected ? ProtectedRoute : Route;

              return (
                <RouteComp key={path} path={path} component={page} exact />
              );
            })}
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              open={error !== null}
              autoHideDuration={6000}
              onClose={handleError}
              color="error.main"
              message={error}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleError}
                >
                  <Close fontSize="small" />
                </IconButton>
              }
            />
          </Switch>
        </MainRouter>
      </Router>
    </ThemeProvider>
  );
}

export default App;
