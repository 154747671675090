import MaterialTable, { Column } from '@material-table/core';
import { Label } from '@material-ui/icons';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  deletePairInflexion,
  patchPairInflexion,
  postPairInflexion
} from '../../api/pair';
import { selectDefinitions } from '../../features/definitionsSlice';
import { Pair } from '../../interfaces';
import { PairChip } from '../PairChip';
import { InflexionCell } from './InflexionCell';

interface Props {
  pair: Pair;
  setPair: React.Dispatch<React.SetStateAction<Pair | undefined>>;
  setTagDialogInflexion: (inflexion?: Pair) => void;
}

interface TableState {
  columns: Column<Pair>[];
  data: Pair[];
}

const tableIcons: { [key: string]: any } = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowDownward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

export function InflexionsTable({
  pair,
  setPair,
  setTagDialogInflexion
}: Props) {
  const definitions = useSelector(selectDefinitions);

  const [table, setTable] = React.useState<TableState>({
    columns: [
      {
        title: 'Name',
        field: 'name',
        editable: 'always',
        render: (inflexion) => {
          return (
            <PairChip
              isEnabled
              name={inflexion.name}
              languageId={pair.languageId}
              regionId={inflexion.regionId}
              onRegionChange={(regionId) =>
                handleRowUpdate({
                  id: inflexion.id,
                  regionId: regionId
                })
              }
              size="s"
            />
          );
        }
      },
      {
        title: 'Subcategory',
        field: 'subcategoryId',
        editable: 'always',
        render: (inflexion) => (
          <InflexionCell
            disabled
            field="subcategory"
            value={inflexion.subcategoryId}
            options={definitions.subcategories.filter(
              (sc) =>
                sc.grammaticalCategoryId === pair.grammaticalCategoryId &&
                sc.languageId === pair.languageId
            )}
          />
        ),
        editComponent: (props) => (
          <InflexionCell
            field="subcategory"
            value={props.rowData.subcategoryId}
            onChange={props.onChange}
            options={definitions.subcategories.filter(
              (sc) =>
                sc.grammaticalCategoryId === pair.grammaticalCategoryId &&
                sc.languageId === pair.languageId
            )}
          />
        )
      },
      {
        title: 'Gender',
        field: 'genderId',
        editable: 'always',
        render: (inflexion) => (
          <InflexionCell
            disabled
            field="gender"
            value={inflexion.genderId}
            options={definitions.genders}
          />
        ),
        editComponent: (props) => (
          <InflexionCell
            field="gender"
            value={props.rowData.genderId}
            onChange={props.onChange}
            options={definitions.genders}
          />
        )
      },
      {
        title: 'Number',
        field: 'numberId',
        editable: 'always',
        render: (inflexion) => (
          <InflexionCell
            disabled
            field="number"
            value={inflexion.numberId}
            options={definitions.numbers}
          />
        ),
        editComponent: (props) => (
          <InflexionCell
            field="number"
            value={props.rowData.numberId}
            onChange={props.onChange}
            options={definitions.numbers}
          />
        )
      },
      {
        title: 'Sex',
        field: 'sexId',
        editable: 'always',
        render: (inflexion) => (
          <InflexionCell
            disabled
            field="sex"
            value={inflexion.sexId}
            options={definitions.sexes}
          />
        ),
        editComponent: (props) => (
          <InflexionCell
            field="sex"
            value={props.rowData.sexId}
            onChange={props.onChange}
            options={definitions.sexes}
          />
        )
      },
      {
        title: 'Verb Mode',
        field: 'verbModeId',
        editable: 'always',
        render: (inflexion) => (
          <InflexionCell
            disabled
            field="verbMode"
            value={inflexion.verbModeId}
            options={definitions.verbModes}
          />
        ),
        editComponent: (props) => (
          <InflexionCell
            field="verbMode"
            value={props.rowData.verbModeId}
            onChange={props.onChange}
            options={definitions.verbModes}
          />
        )
      },
      {
        title: 'Verb Person',
        field: 'verbPersonId',
        editable: 'always',
        render: (inflexion) => (
          <InflexionCell
            disabled
            field="verbPerson"
            value={inflexion.verbPersonId}
            options={definitions.verbPersons}
          />
        ),
        editComponent: (props) => (
          <InflexionCell
            field="verbPerson"
            value={props.rowData.verbPersonId}
            onChange={props.onChange}
            options={definitions.verbPersons}
          />
        )
      },
      {
        title: 'Verb Number',
        field: 'verbNumberId',
        editable: 'always',
        render: (inflexion) => (
          <InflexionCell
            disabled
            field="verbNumber"
            value={inflexion.verbNumberId}
            options={definitions.verbNumbers}
          />
        ),
        editComponent: (props) => (
          <InflexionCell
            field="verbNumber"
            value={props.rowData.verbNumberId}
            onChange={props.onChange}
            options={definitions.verbNumbers}
          />
        )
      }
    ],
    data: pair.inflexions ?? []
  });

  React.useEffect(() => {
    setTable((prevTable) => ({
      ...prevTable,
      data: pair.inflexions ?? []
    }));
  }, [pair]);

  function refreshTable(newData: Pair[]): void {
    setPair({ ...pair, inflexions: newData });
  }

  async function handleRowAdd(newInflexionData: Pair) {
    const { id: mainPairId, languageId, grammaticalCategoryId } = pair;
    const {
      name,
      subcategoryId,
      genderId,
      numberId,
      sexId,
      verbModeId,
      verbPersonId,
      verbNumberId
    } = newInflexionData;
    const newInflexion = await postPairInflexion(mainPairId, {
      name,
      languageId,
      grammaticalCategoryId,
      subcategoryId,
      genderId,
      numberId,
      sexId,
      verbModeId,
      verbPersonId,
      verbNumberId
    });

    refreshTable([...table.data, newInflexion]);
  }

  async function handleRowUpdate(
    newInflexionData: Partial<Pair>,
    oldInflexion?: Pair
  ) {
    const {
      name,
      regionId,
      subcategoryId,
      genderId,
      numberId,
      sexId,
      verbModeId,
      verbPersonId,
      verbNumberId
    } = newInflexionData;
    const id = oldInflexion?.id ?? newInflexionData.id;

    if (id) {
      const newInflexion = await patchPairInflexion(id, {
        name,
        regionId,
        subcategoryId,
        genderId,
        numberId,
        sexId,
        verbModeId,
        verbPersonId,
        verbNumberId
      });
      const newTableData = [...table.data];
      const newIndex = newTableData.findIndex((p) => newInflexion.id === p.id);
      newTableData[newIndex] = { ...newInflexion, regionId };

      refreshTable(newTableData);
    }
  }

  async function handleRowDelete(oldInflexion: Pair) {
    await deletePairInflexion(oldInflexion.id);
    refreshTable(table.data.filter((p) => oldInflexion.id !== p.id));
  }

  return (
    <MaterialTable
      icons={tableIcons}
      title="Admin Control Panel"
      style={{ boxShadow: '0 0 0' }}
      columns={table.columns}
      data={table.data}
      options={{
        showTitle: false,
        paging: false,
        filtering: false,
        sorting: false,
        search: false,
        draggable: false
      }}
      actions={[
        {
          icon: Label,
          tooltip: 'Tags',
          onClick: (_, p) =>
            setTagDialogInflexion(!Array.isArray(p) ? p : undefined)
        }
      ]}
      editable={{
        onRowAdd: handleRowAdd,
        onRowUpdate: handleRowUpdate,
        onRowDelete: handleRowDelete
      }}
    />
  );
}
