import { getRequest, handleQueryParams } from './index';

import { SearchModel } from '../components/Search';
import { AutocompleteModel, Chain } from '../interfaces';

const MAIN_SEARCH_ENDPOINT = '/search';
const AUTOCOMPLETE_SEARCH_ENDPOINT = '/search/autocomplete';
const GET_MODEL_ENDPOINT = '/search/model';

export const mainSearch = (
  term: string,
  languageId?: number,
  tagId?: number,
  model: SearchModel = SearchModel.pair
): Promise<{ chains: Chain[] }> =>
  getRequest(
    MAIN_SEARCH_ENDPOINT + handleQueryParams({ term, languageId, tagId, model })
  );

export const autocompleteSearch = <T>(
  term: string,
  model: AutocompleteModel,
  languageId?: number
): Promise<T[]> =>
  getRequest(
    AUTOCOMPLETE_SEARCH_ENDPOINT +
      handleQueryParams({ term, model, languageId })
  );

export const getByModelId = <T>(
  id: number,
  model: AutocompleteModel
): Promise<T> =>
  getRequest(GET_MODEL_ENDPOINT + handleQueryParams({ id, model }));
