import { User } from '../features/authSlice/interfaces';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
  handleQueryParams
} from './index';

const USERS_ENDPOINT = '/user/';
const ME_ENDPOINT = '/user/me';

export const getMyData = () => getRequest(ME_ENDPOINT, false);
export const getAllUsers = (page: number) =>
  getRequest(USERS_ENDPOINT + handleQueryParams({ page }));
export const addUser = (user: User) => postRequest(USERS_ENDPOINT, user);
export const editUser = (id: string, user: User) =>
  patchRequest(USERS_ENDPOINT + id, user);
export const resetUserPassword = (id: string, email: string) =>
  putRequest(USERS_ENDPOINT + id, { email });
export const deleteUser = (id: string) => deleteRequest(USERS_ENDPOINT + id);
