import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { List } from '../../interfaces';

interface Props {
  list?: List;
}

export const ListParent = ({ list }: Props) => {
  if (list?.folderId) {
    const model = list.folder?.section ? 'Section' : 'Area';
    const id = list.folder?.sectionId ?? list.folder?.areaId;
    return (
      <>
        <Typography variant="h4">
          {model}:&nbsp;
          {list?.folder?.area?.name ?? list.folder?.section?.name ?? '-'}
        </Typography>
        <Link to={`/${model.toLowerCase()}/${id}#${list.folderId}`}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!list?.folderId}
          >
            Folder: {list?.folder?.name}
          </Button>
        </Link>
      </>
    );
  } else if (list?.unitId) {
    return (
      <>
        <Typography variant="h4">
          Course: {list?.unit?.level.course.name}
        </Typography>
        <Typography variant="h4">Level: {list?.unit?.level.name}</Typography>
        <Link to={`/unit/${list.unitId}`}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!list?.unitId}
          >
            Unit: {list?.unit?.name}
          </Button>
        </Link>
      </>
    );
  }
  return <React.Fragment />;
};
