import React from 'react';

import {
  CircularProgress,
  Typography,
  makeStyles,
  Theme,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  ButtonGroup
} from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';

import {
  deleteListPair,
  getSimpleListById,
  patchListPair,
  postListPair
} from '../../api/lists';
import { List } from '../../interfaces';
import { ListTitle } from './ListTitle';
import { getByLanguageId } from '../../utils/model';
import { ListTable } from './ListTable';
import { Search } from '../../pages/Search';
import { ListPairImport } from './ListPairImport';
import { User } from '../../features/authSlice/interfaces';

interface Props {
  listId: number;
  languageId: number;
  translationLanguageId: number;
  onClose: () => void;
  refetchList: () => Promise<void>;
  user?: User | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2)
  }
}));

export const ListPairForm = ({
  listId,
  languageId,
  translationLanguageId,
  refetchList,
  user
}: Props) => {
  const [list, setList] = React.useState<List>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState<boolean>(false);
  const [importOpen, setImportOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const classes = useStyles();

  const listLanguage = React.useMemo(
    () => getByLanguageId(list?.listLanguages, languageId),
    [list, languageId]
  );

  const handleAdd = async (pairId: number) => {
    if (listLanguage) {
      await postListPair(listLanguage.id, {
        pairId,
        sort: list?.pairs?.length
          ? Math.max(...list.pairs.map<number>((p) => p.pairSort)) + 10
          : 0
      });
      await fetchSimpleList();
      await refetchList();
      setOpen(false);
    }
  };

  const handleChange = async (pairId: number, sort: number) => {
    if (listLanguage) {
      await patchListPair(listLanguage.id, { pairId, sort });
    }
  };

  const handleDelete = async (pairId: number) => {
    if (listLanguage) {
      await deleteListPair(listLanguage.id, { pairId });
      await fetchSimpleList();
      await refetchList();
    }
  };

  const fetchSimpleList = async () => {
    try {
      const fetchedSimpleList = await getSimpleListById(
        listId,
        languageId,
        translationLanguageId
      );
      setList(fetchedSimpleList);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchSimpleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography variant="h1" color="textPrimary">
        {error}
      </Typography>
    );
  }

  return (
    <Box className={classes.wrapper}>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Add Pair
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Search
            listPairs={list?.pairs ?? []}
            onSubmit={handleAdd}
            languageId={languageId}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={importOpen} fullWidth maxWidth="lg">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Import
            <IconButton onClick={() => setImportOpen(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <ListPairImport
            handleAdd={handleAdd}
            handleClose={() => setImportOpen(false)}
            listId={list?.id}
            languageId={languageId}
            translationLanguageId={translationLanguageId}
          />
        </DialogContent>
      </Dialog>
      <Box className={classes.header}>
        <ListTitle listLanguage={listLanguage} />
        <ButtonGroup>
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            color="secondary"
            startIcon={<Add />}
          >
            ADD PAIR
          </Button>
          <Button
            onClick={() => setImportOpen(true)}
            variant="contained"
            color="secondary"
            startIcon={<Add />}
          >
            IMPORT
          </Button>
        </ButtonGroup>
      </Box>
      <ListTable
        handleChange={handleChange}
        list={list}
        user={user}
        loading={loading}
        languageId={languageId}
        translationLanguageId={translationLanguageId}
        handleDelete={handleDelete}
      />
    </Box>
  );
};
