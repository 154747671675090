import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { LevelState } from './interfaces';

import {
  postLevel,
  patchLevel,
  deleteLevel as deleteLevelAPI
} from '../../api/level';
import { Level } from '../../interfaces';
import { reorderIds } from '../../utils/reoderIds';
import { refetchCurrentCourse } from '../courseSlice';

// Slice name
export const levelSliceName = 'level';

// Initial State
const levelInitialState: LevelState = {
  allLevelIds: [],
  levelsById: {},
  simpleLevels: [],
  loading: true,
  error: null
};

// Slice definition with reducers
const levelSlice = createSlice({
  name: levelSliceName,
  initialState: levelInitialState,
  reducers: {
    setLoading: (state: LevelState, action) => {
      state.loading = action.payload;
    },
    setError: (state: LevelState, action) => {
      const errorMessage: string = action.payload;
      state.error = errorMessage;
    },
    pushNewLevel: (state: LevelState, action) => {
      const levelFromApi: Level = action.payload;

      state.levelsById[levelFromApi.id] = levelFromApi;

      state.allLevelIds.push(levelFromApi.id.toString());
      state.allLevelIds = reorderIds(state.allLevelIds, state.levelsById);
    },
    pushExistingLevel: (state: LevelState, action) => {
      const levelFromApi: Level = action.payload;
      state.levelsById[levelFromApi.id] = levelFromApi;
      state.allLevelIds = reorderIds(state.allLevelIds, state.levelsById);
    },
    removeLevel: (state: LevelState, action) => {
      const id = action.payload;
      delete state.levelsById[id];
      state.allLevelIds = state.allLevelIds.filter((fId) => fId !== id);
    }
  }
});

// Actions
const { setLoading, setError, removeLevel, pushNewLevel, pushExistingLevel } =
  levelSlice.actions;

export const createLevel =
  (level: Level) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const fetchedLevel = await postLevel(level);
      dispatch(pushNewLevel(fetchedLevel));
      dispatch(refetchCurrentCourse());
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateLevel =
  (level: Level) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    try {
      const fetchedLevel = await patchLevel(level, level.id);
      dispatch(pushExistingLevel(fetchedLevel));
      dispatch(refetchCurrentCourse());
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteLevel =
  (levelId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    try {
      await deleteLevelAPI(levelId);
      dispatch(removeLevel(levelId));
      dispatch(refetchCurrentCourse());
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

// Reducer
export default levelSlice.reducer;
