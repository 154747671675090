import {
  Avatar,
  Card,
  CardActionArea,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { LANGUAGES } from '../../constants/languages';
import { User } from '../../features/authSlice/interfaces';
import { List, ListLanguage } from '../../interfaces';
import { getByLanguageId } from '../../utils/model';
import { RegionButton } from '../RegionButton';

interface Props {
  onClick?: () => void;
  user?: User | null;
  list: List;
}

interface PairsProps {
  listLanguage?: ListLanguage;
  secondaryListLanguage?: ListLanguage;
  user?: User | null;
  classes: Record<
    'column' | 'marginBottom' | 'listImage' | 'listCard' | 'pairs' | 'flag',
    string
  >;
}

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    verticalAlign: 'middle',
    padding: theme.spacing(2, 2)
  },
  listImage: {
    width: '9rem',
    height: '9rem',
    marginBottom: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  listCard: {
    margin: theme.spacing(2, 1),
    width: '15%',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  pairs: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  flag: {
    width: theme.spacing(2.25),
    height: theme.spacing(1.5),
    display: 'inline-block',
    fontSize: 1
  }
}));

const PairsSubHeader = ({
  listLanguage,
  secondaryListLanguage,
  user,
  classes
}: PairsProps) => {
  if (listLanguage?.pairsLength === secondaryListLanguage?.pairsLength) {
    return (
      <span className={classes.pairs}>({listLanguage?.pairsLength} pairs)</span>
    );
  }

  return (
    <span className={classes.pairs}>
      (
      {listLanguage ? (
        <>
          {listLanguage?.pairsLength}&nbsp;
          <Avatar
            className={classes.flag}
            variant="rounded"
            src={LANGUAGES[user?.primaryLanguageId ?? 1].flag}
          />
        </>
      ) : (
        ''
      )}
      {listLanguage && secondaryListLanguage ? ' / ' : ''}
      {secondaryListLanguage ? (
        <>
          {secondaryListLanguage?.pairsLength}&nbsp;
          <Avatar
            className={classes.flag}
            variant="rounded"
            src={LANGUAGES[user?.secondaryLanguageId ?? 2].flag}
          />
        </>
      ) : (
        ''
      )}
      )
    </span>
  );
};

export const ListCard = ({ list, user }: Props) => {
  const listLanguage = getByLanguageId<ListLanguage>(
    list.listLanguages,
    user?.primaryLanguageId
  );
  const secondaryListLanguage = getByLanguageId<ListLanguage>(
    list.listLanguages,
    user?.secondaryLanguageId
  );

  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card key={list.id} elevation={0} className={classes.listCard}>
      <Link to={`/list/${list.id}`}>
        <CardActionArea className={classes.column}>
          <Avatar
            className={classes.listImage}
            src={listLanguage?.imageUrl ?? ''}
            style={{
              border: `0.5rem solid ${
                list.listLanguages.some((ll) => ll.isEnabled)
                  ? theme.palette.success.main
                  : theme.palette.error.main
              }`
            }}
          />
          <Typography className={classes.marginBottom} variant="h5">
            {listLanguage?.name ?? '-'}
          </Typography>
          <PairsSubHeader
            listLanguage={listLanguage}
            secondaryListLanguage={secondaryListLanguage}
            user={user}
            classes={classes}
          />
          {list.listLanguages.some((ll) => !ll.isEnabled)
            ? list.listLanguages.map((ll) =>
                ll.isEnabled ? (
                  <RegionButton
                    key={ll.id}
                    size="m"
                    disabled
                    onlyLanguage
                    languageId={ll.languageId}
                  />
                ) : undefined
              )
            : undefined}
        </CardActionArea>
      </Link>
    </Card>
  );
};
