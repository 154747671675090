import React from 'react';
import { useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Theme
} from '@material-ui/core';

import { selectUser } from '../../features/authSlice';
import { Chain, Pair } from '../../interfaces';
import { LanguageTableHead } from '../LanguageTableHead';
import { SearchTableFooter } from './SearchTableFooter';
import { SearchTableBody } from './SearchTableBody';

interface Props {
  chains?: Chain[];
  page?: number;
  term: string;
  loading: boolean;
  onSubmit?: (pairId: number) => Promise<void>;
  handlePageChange?: (newPage: number) => void;
  tagId?: number;
  listPairs?: Pair[];
}

const styles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    overflow: 'hidden'
  },

  tableCell: {
    border: `1px solid ${theme.palette.divider}`,
    width: '28%',
    fontSize: 16
  }
}));

export const SearchTable = ({
  listPairs,
  tagId,
  chains,
  page,
  loading,
  handlePageChange,
  term,
  onSubmit
}: Props) => {
  const classes = styles();
  const user = useSelector(selectUser);

  const parsedChains = React.useMemo(() => {
    if (chains && chains.length) {
      if (chains.length > 20) {
        if (page !== null && page !== undefined) {
          return chains?.slice(20 * (page - 1), 20 * page);
        }
        return chains;
      }
      return chains;
    }
    return [];
  }, [chains, page]);

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableCell}>
            <TableCell size="small" />
            <TableCell />
            <LanguageTableHead topLeft languageId={user?.primaryLanguageId} />
            <LanguageTableHead languageId={user?.secondaryLanguageId} />
            <LanguageTableHead topRight languageId={user?.tertiaryLanguageId} />
          </TableRow>
        </TableHead>
        <SearchTableBody
          tagId={tagId}
          loading={loading}
          chains={parsedChains}
          user={user}
          term={term}
          listPairs={listPairs}
          tableCellClass={classes.tableCell}
          onSubmit={onSubmit}
        />
        {page && handlePageChange && chains && chains.length ? (
          <SearchTableFooter
            page={page}
            total={chains.length}
            handleChangePage={(_, newPage) => handlePageChange(newPage)}
          />
        ) : null}
      </Table>
    </TableContainer>
  );
};
