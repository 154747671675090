import React from 'react';

import {
  TableCell,
  makeStyles,
  createStyles,
  Theme,
  Box
} from '@material-ui/core';

import { PairChip } from '../PairChip';

interface Props {
  pairs?: string[][];
  className: string;
  chainIds: number[];
  chainId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'justify',
      alignItems: 'start',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(1)
    },
    container: {
      marginBottom: theme.spacing(1)
    },
    chip: {
      marginRight: theme.spacing(0.5)
    }
  })
);

export const ListChainTableCell = ({
  pairs = [],
  className,
  chainIds,
  chainId
}: Props) => {
  const classes = useStyles();

  return (
    <TableCell className={className}>
      <Box className={classes.root}>
        {pairs &&
          pairs.map(([id, name, regionId, main, isEnabled]) => (
            <Box className={classes.container} flexBasis="20%" key={id}>
              <PairChip
                regionId={Number(regionId)}
                main={main === 'true'}
                isEnabled={isEnabled === 'true'}
                name={name}
                chainId={chainId}
                chainIds={chainIds}
              />
            </Box>
          ))}
      </Box>
    </TableCell>
  );
};
