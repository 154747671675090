import { cloneDeep, mergeWith, merge } from 'lodash';

export function getByLanguageId<T>(
  array?: T[],
  languageId?: number
): T | undefined {
  return array?.find((al: any) => al?.languageId === languageId);
}

export function mergeLanguageModel<T>(model: T, changes: T): T {
  return mergeWith(cloneDeep(model), changes, (objValue, srcValue) => {
    if (Array.isArray(objValue)) {
      const languages = cloneDeep(objValue);
      srcValue.forEach((v: any) => {
        const languageIndex = languages.findIndex(
          (al) => al?.languageId === v?.languageId
        );
        if (languageIndex !== -1) {
          languages[languageIndex] = merge(languages[languageIndex], v);
        } else {
          languages.push(v);
        }
      });
      return languages;
    }
    return srcValue ?? objValue;
  });
}
