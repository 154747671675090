import React from 'react';

import {
  Box,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Avatar
} from '@material-ui/core';

import { LANGUAGES } from '../../constants/languages';
import { ListLanguage } from '../../interfaces';

import { IsEnabled } from '../IsEnabled';

interface Props {
  listLanguage?: ListLanguage;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  isEnabled: {
    marginRight: theme.spacing(1),
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  input: {
    color: theme.palette.text.primary,
    width: '100%'
  },
  avatar: {
    height: theme.spacing(4)
  }
}));

export const ListTitle = ({ listLanguage }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {listLanguage ? (
        <IsEnabled
          className={classes.isEnabled}
          isEnabled={listLanguage.isEnabled}
        />
      ) : (
        <Box className={classes.isEnabled} />
      )}
      <TextField
        variant="outlined"
        aria-readonly
        size="small"
        value={listLanguage?.name}
        className={classes.input}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Avatar
                className={classes.avatar}
                variant="rounded"
                src={
                  listLanguage
                    ? LANGUAGES[listLanguage.languageId].flag
                    : 'flags/INTL.svg'
                }
                alt={
                  listLanguage
                    ? LANGUAGES[listLanguage.languageId].name
                    : 'None'
                }
              />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};
