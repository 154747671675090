import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  FormGroup,
  FormControlLabel,
  makeStyles,
  Theme,
  DialogActions,
  Button,
  Checkbox
} from '@material-ui/core';

import { breakpointDownSm } from '../../theme';
import { Chain } from '../../interfaces';
import { selectChainTags } from '../../features/tagSlice';
import { postChainTags, TagState } from '../../api/tag';
import { Transition } from '../Transition';
import { refetchCurrentChain } from '../../features/chainSlice';

interface Props {
  open: boolean;
  chain?: Chain;
  setChain: (chain: Chain | undefined) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

const useStyles = makeStyles((_theme: Theme) => ({
  container: {
    height: '100vh'
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%'
  }
}));

export function ChainTagDialog({
  open,
  setOpen,
  chain,
  setChain,
  maxWidth = 'lg'
}: Props) {
  const classes = useStyles();
  const tags = useSelector(selectChainTags);
  const fullScreen = useMediaQuery(breakpointDownSm);
  const [chainTags, setChainTags] = React.useState<TagState>();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { name: id } = event.target;
    const tag = tags.find((t) => t.id === Number(id));
    if (tag) {
      setChainTags({ ...chainTags, [id]: { ...tag, checked } });
    }
  };

  const handleSubmit = async () => {
    if (chain && chainTags) {
      await postChainTags(chain.id, chainTags);
      await dispatch(refetchCurrentChain());
    }
    handleClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      open={open}
      onClose={handleSubmit}
    >
      <DialogTitle>Tags</DialogTitle>
      <DialogContent className={classes.container} dividers>
        <FormGroup className={classes.wrap}>
          {tags?.map((t) => (
            <FormControlLabel
              key={t.id}
              control={
                <Checkbox
                  name={t.id.toString()}
                  defaultChecked={Boolean(
                    chain?.tags?.find((pt) => pt.id === t.id)
                  )}
                  onChange={handleChange}
                />
              }
              label={t.name}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSubmit}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
