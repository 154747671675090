import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { AddCircle, Cancel, CheckCircle, Label } from '@material-ui/icons';
import React from 'react';
import { patchPairInflexion, postPairInflexion } from '../../api/pair';
import { LANGUAGES } from '../../constants/languages';
import { Pair } from '../../interfaces';
import { PairChip } from '../PairChip';

interface Props {
  pair: Pair;
  setPair: React.Dispatch<React.SetStateAction<Pair | undefined>>;
  onClick: (inflexion?: Pair) => void;
}

const useStyles = makeStyles((theme) => ({
  flag: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle'
  }
}));

export const InflexionEnglishCustomVerb = ({
  pair,
  setPair,
  onClick
}: Props) => {
  const classes = useStyles();

  const divide = (inflexions: Pair[], verbModeId: number) => {
    return [
      inflexions.find((p) => p.verbModeId === verbModeId && !p.verbPersonId),
      inflexions.find(
        (p) => p.verbModeId === verbModeId && p.verbPersonId === 1
      ),
      inflexions.find(
        (p) => p.verbModeId === verbModeId && p.verbPersonId === 3
      )
    ];
  };

  const presents = React.useMemo(
    () => divide(pair.inflexions ?? [], 1),
    [pair]
  );

  const pastSimples = React.useMemo(
    () => divide(pair.inflexions ?? [], 2),
    [pair]
  );

  const pastParticiples = React.useMemo(
    () => divide(pair.inflexions ?? [], 3),
    [pair]
  );

  const gerunds = React.useMemo(() => divide(pair.inflexions ?? [], 4), [pair]);

  const handleRegionChange =
    (id: number) => async (regionId: number | null) => {
      const newInflexion = await patchPairInflexion(id, {
        regionId
      });

      const newInflexions = [...(pair.inflexions ?? [])];
      const newIndex = newInflexions.findIndex((p) => newInflexion.id === p.id);
      newInflexions[newIndex] = { ...newInflexion, regionId };
      setPair({
        ...pair,
        inflexions: newInflexions
      });
    };

  const handleSubmit =
    (verbModeId: number) =>
    (verbPersonId?: number, verbNumberId?: number) =>
    async (name: string) => {
      const { id, languageId, grammaticalCategoryId } = pair;
      const newInflexion = await postPairInflexion(id, {
        name,
        languageId,
        grammaticalCategoryId,
        verbModeId,
        verbPersonId,
        verbNumberId
      });

      setPair({
        ...pair,
        inflexions: [...(pair.inflexions ?? []), newInflexion]
      });
    };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="subtitle2">
              <img
                className={classes.flag}
                width="20px"
                src={LANGUAGES[pair.languageId].flag}
                alt={LANGUAGES[pair.languageId].name}
              />
              {pair.id}
            </Typography>
          </TableCell>
          <TableCell>GENERAL INFLEXION</TableCell>
          <TableCell>1RD PERSON</TableCell>
          <TableCell>3RD PERSON</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRowTitle
          title="PRESENT"
          inflexions={presents}
          onClick={onClick}
          onRegionChange={handleRegionChange}
          onSubmit={handleSubmit(1)}
        />
        <TableRowTitle
          title="PAST SIMPLE"
          inflexions={pastSimples}
          onClick={onClick}
          onRegionChange={handleRegionChange}
          onSubmit={handleSubmit(2)}
        />
        <TableRowTitle
          title="PAST PARTICIPLE"
          inflexions={pastParticiples}
          onClick={onClick}
          onRegionChange={handleRegionChange}
          onSubmit={handleSubmit(3)}
        />
        <TableRowTitle
          title="GERUND"
          inflexions={gerunds}
          onClick={onClick}
          onRegionChange={handleRegionChange}
          onSubmit={handleSubmit(4)}
        />
      </TableBody>
    </Table>
  );
};

//#region Subcomponents

interface TableRowTitleProps {
  title: string;
  inflexions: (Pair | undefined)[];
  onSubmit: (
    verbPersonId?: number,
    verbNumberId?: number
  ) => (name: string) => Promise<void>;
  onClick: (inflexion?: Pair) => void;
  onRegionChange: (id: number) => (regionId: number | null) => void;
}

const TableRowTitle = ({
  title,
  inflexions,
  onSubmit,
  onClick,
  onRegionChange
}: TableRowTitleProps) => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">{title}</Typography>
      </TableCell>
      <TableRowCell
        inflexion={inflexions[0]}
        onClick={onClick}
        onRegionChange={onRegionChange}
        onSubmit={onSubmit()}
      />
      <TableRowCell
        inflexion={inflexions[1]}
        onClick={onClick}
        onRegionChange={onRegionChange}
        onSubmit={onSubmit(1, 1)}
      />
      <TableRowCell
        inflexion={inflexions[2]}
        onClick={onClick}
        onRegionChange={onRegionChange}
        onSubmit={onSubmit(3, 1)}
      />
    </TableRow>
  );
};

interface TableRowCellProps {
  inflexion?: Pair;
  onSubmit: (name: string) => Promise<void>;
  onClick: (inflexion?: Pair) => void;
  onRegionChange: (id: number) => (regionId: number | null) => void;
}

const TableRowCell = ({
  inflexion,
  onSubmit,
  onClick,
  onRegionChange
}: TableRowCellProps) => {
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [newName, setNewName] = React.useState<string>('');

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey && newName?.trim()) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleEdit = () => setEditMode(true);

  const handleCancel = () => {
    setEditMode(false);
    setNewName('');
  };

  const handleSubmit = async () => {
    await onSubmit(newName!.trim());
    handleCancel();
  };

  return (
    <TableCell>
      {inflexion ? (
        <PairChip
          regionId={inflexion.regionId}
          languageId={inflexion.languageId}
          onRegionChange={onRegionChange(inflexion.id)}
          isEnabled
          name={inflexion.name}
          deleteIcon={<Label />}
          onDelete={() => onClick(inflexion)}
        />
      ) : editMode ? (
        <>
          <TextField
            onKeyPress={handleKeyPress}
            autoFocus
            placeholder="Name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <IconButton onClick={handleCancel}>
            <Cancel />
          </IconButton>
          <IconButton disabled={!newName?.trim()} onClick={handleSubmit}>
            <CheckCircle />
          </IconButton>
        </>
      ) : (
        <IconButton onClick={handleEdit}>
          <AddCircle />
        </IconButton>
      )}
    </TableCell>
  );
};

//#endregion
