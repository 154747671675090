import React from 'react';

import {
  TableRow,
  TableCell,
  Box,
  CircularProgress,
  TableBody,
  Theme,
  makeStyles
} from '@material-ui/core';

import { User } from '../../features/authSlice/interfaces';
import { IsEnabled } from '../IsEnabled';
import { ListChain } from './ListChainTable';
import { ListChainTableCell } from './ListChainTableCell';

interface Props {
  loading: boolean;
  chains: ListChain[];
  user?: User | null;
  tableCellClass: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle'
  },
  border: {
    border: `1px solid ${theme.palette.divider}`
  }
}));

export const ListChainTableBody = ({
  loading,
  chains,
  user,
  tableCellClass
}: Props) => {
  const classes = useStyles();

  if (loading) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={5}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {(chains ?? []).map(
        ({
          chainId,
          isEnabled,
          imageUrl,
          definition1,
          pairsWithRegionIsMain1,
          definition2,
          pairsWithRegionIsMain2
        }) => {
          return (
            <React.Fragment key={chainId}>
              <TableRow className={tableCellClass}>
                <TableCell size="small" rowSpan={2}>
                  <IsEnabled isEnabled={isEnabled} />
                </TableCell>
                <TableCell className={classes.border} rowSpan={2}>
                  <img
                    className={classes.image}
                    src={imageUrl ?? '/logo-bw.png'}
                    alt="chain"
                  />
                </TableCell>
                <TableCell className={tableCellClass}>
                  {(user && definition1) ?? ''}
                </TableCell>
                <TableCell className={tableCellClass}>
                  {(user && definition2) ?? ''}
                </TableCell>
                <TableCell className={tableCellClass}></TableCell>
              </TableRow>
              <TableRow className={tableCellClass}>
                <ListChainTableCell
                  chainId={chainId}
                  chainIds={chains.map((c) => c.chainId)}
                  className={tableCellClass}
                  pairs={(user && pairsWithRegionIsMain1) ?? []}
                />
                <ListChainTableCell
                  chainId={chainId}
                  chainIds={chains.map((c) => c.chainId)}
                  className={tableCellClass}
                  pairs={(user && pairsWithRegionIsMain2) ?? []}
                />
                <ListChainTableCell
                  chainId={chainId}
                  chainIds={chains.map((c) => c.chainId)}
                  className={tableCellClass}
                />
              </TableRow>
            </React.Fragment>
          );
        }
      )}
    </TableBody>
  );
};
