import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { getDefinitions } from '../../api/definitions';
import { Definitions } from '../../interfaces';
import { RootState } from '../../store';
import { DefinitionsState } from './interfaces';

// Slice name
export const definitionsSliceName = 'definitions';

// Initial State
const definitionsInitialState: DefinitionsState = {
  subcategories: [],
  genders: [],
  numbers: [],
  sexes: [],
  verbModes: [],
  verbNumbers: [],
  verbPersons: [],
  loading: false,
  error: null
};

// Slice definitions with reducers
const definitionsSlice = createSlice({
  name: definitionsSliceName,
  initialState: definitionsInitialState,
  reducers: {
    setDefinitions: (state: DefinitionsState, action) => {
      const payload: Definitions = action.payload;
      state.subcategories = payload.subcategories;
      state.genders = payload.genders;
      state.numbers = payload.numbers;
      state.sexes = payload.sexes;
      state.verbModes = payload.verbModes;
      state.verbNumbers = payload.verbNumbers;
      state.verbPersons = payload.verbPersons;
    },
    setLoading: (state: DefinitionsState, action) => {
      const payload: boolean = action.payload;
      state.loading = payload;
    },
    setError: (state: DefinitionsState, action) => {
      const payload: string = action.payload;
      state.error = payload;
    }
  }
});

// Actions
const { setDefinitions, setLoading, setError } = definitionsSlice.actions;

// Thunks
export const loadDefinitions = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));

  try {
    const definitions = await getDefinitions();
    dispatch(setDefinitions(definitions));
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

// Selectors
export const selectDefinitions = (state: RootState) =>
  state[definitionsSliceName];

// Reducer
export default definitionsSlice.reducer;
