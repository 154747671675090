export interface AuthStateI {
  user?: User | null;
  isAuthenticated: boolean;
  authenticating: boolean;
}

export interface User {
  id: string;
  username: string;
  email: string;
  role: Roles;
  primaryLanguageId: number;
  secondaryLanguageId: number;
  tertiaryLanguageId?: number;
}

export enum Roles {
  admin = 'admin',
  linguist = 'linguist'
}

export interface AuthGlobalStateI {
  auth: AuthStateI;
}
