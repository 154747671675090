import React from 'react';
import { User } from '../../features/authSlice/interfaces';
import { Folder, Unit } from '../../interfaces';
import { getByLanguageId } from '../../utils/model';

import { AsyncAutocomplete } from '../AsyncAutocomplete';

interface Props {
  user?: User | null;
  unitId?: number;
  folderId?: number;
  handleChange: (key: string, value: unknown) => void;
}

export const ListAutocomplete = ({
  user,
  unitId,
  folderId,
  handleChange
}: Props) => {
  if (unitId) {
    return (
      <AsyncAutocomplete
        label="Unit"
        defaultValueId={unitId}
        nameGetter={(unit: Unit) =>
          getByLanguageId(unit.unitLanguages, user?.primaryLanguageId)?.name
        }
        onChange={(value) => handleChange('unitId', value?.id)}
      />
    );
  } else if (folderId) {
    return (
      <AsyncAutocomplete
        label="Folder"
        defaultValueId={folderId}
        nameGetter={(folder: Folder) =>
          getByLanguageId(folder.folderLanguages, user?.primaryLanguageId)?.name
        }
        onChange={(value) => handleChange('folderId', value?.id)}
      />
    );
  }
  return <React.Fragment />;
};
