import { getRequest, postRequest, patchRequest, deleteRequest } from './index';

const GET_ALL_ENDPOINT = '/course/all';
const GET_COURSE_BY_ID = '/course';
const POST_NEW_COURSE = '/course/';
const PUT_EDIT_COURSE = '/course/';
const DELETE_COURSE = '/course/';

export const getCourses = () => getRequest(GET_ALL_ENDPOINT);
export const getCourseById = (id: number | string) =>
  getRequest(`${GET_COURSE_BY_ID}/${id}`);
export const postCourse = (data: object) =>
  postRequest(POST_NEW_COURSE, data, true);
export const patchCourse = (data: object, id: number | string) =>
  patchRequest(`${PUT_EDIT_COURSE}/${id}`, data, true);
export const deleteCourse = (id: Number) => deleteRequest(DELETE_COURSE + id);
