import React from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  makeStyles,
  Theme,
  Typography,
  Avatar,
  IconButton,
  TextField,
  Button,
  ButtonGroup,
  Tooltip
} from '@material-ui/core';
import {
  Close,
  Delete,
  RadioButtonChecked,
  Publish,
  Save
} from '@material-ui/icons';

import { set } from 'lodash';

import { Area } from '../../interfaces';
import { LANGUAGES } from '../../constants/languages';
import { createArea, updateArea, deleteArea } from '../../features/areaSlice';
import { mergeLanguageModel } from '../../utils/model';
import { confirmHOC } from '../hocs/confirmHOC';

interface Props {
  afterSubmit: () => void;
  onClose: () => void;
  area?: Area;
  maxSort?: number;
  confirm?: (onConfirm: () => void, contentLength?: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    margin: theme.spacing(0, 2)
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0)
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%'
  },

  deleteImage: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    top: -10,
    right: -10,

    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    },
    '&:disabled': {
      display: 'none'
    }
  },
  textFields: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    marginLeft: theme.spacing(4)
  },
  textField: {
    marginTop: theme.spacing(0)
  },
  image: {
    width: '100%',
    height: theme.spacing(30),
    marginBottom: theme.spacing(2)
  },
  inputLeft: {
    marginRight: theme.spacing(1)
  },
  input: {
    marginBottom: theme.spacing(2)
  },
  inputRight: {
    marginLeft: theme.spacing(1),
    width: '20%'
  },
  color: {
    padding: theme.spacing(0)
  },
  labelText: {
    display: 'flex',
    alignItems: 'center'
  },
  labelImage: {
    height: '1em'
  }
}));

const AreaFormComp = ({ maxSort, onClose, area: areaProp, confirm }: Props) => {
  const [area, setArea] = React.useState(areaProp);
  const [changes, setChanges] = React.useState<Area>();
  const [picture, setPicture] = React.useState<File>();
  const dispatch = useDispatch();
  const classes = useStyles();

  const saveDisabled = area
    ? !changes && !picture
    : !changes?.primaryColor ||
      !changes?.secondaryColor ||
      !changes?.tertiaryColor ||
      !changes?.sort ||
      !changes?.areaLanguages;

  const handleChange = React.useCallback(
    (path: string, value: unknown) => {
      const newChanges = { ...changes } as Area;
      set(newChanges, path, value);
      setChanges(newChanges);
    },
    [changes]
  );

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      setPicture(files[0]);
    }
  };

  const handleDelete = () => {
    if (area && confirm) {
      confirm(() => {
        dispatch(deleteArea(area.id));
        onClose();
      }, area.foldersLength);
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const newArea = mergeLanguageModel(area, changes);
    if (newArea) {
      if (picture) newArea.picture = picture;
      if (area) {
        dispatch(updateArea({ ...newArea }));
      } else {
        dispatch(createArea({ ...newArea }));
      }
      setArea(newArea);
      setChanges(undefined);
      onClose();
    }
  };

  React.useEffect(() => {
    if (!area && !changes) {
      handleChange(
        'sort',
        (maxSort !== Number.NEGATIVE_INFINITY ? maxSort ?? 0 : 0) + 10
      );
    }
  }, [area, maxSort, changes, handleChange]);

  return (
    <Box className={classes.root}>
      <Box className={classes.row}>
        <Button
          startIcon={<RadioButtonChecked />}
          className={
            changes?.isEnabled ?? area?.isEnabled
              ? classes.success
              : classes.error
          }
          onClick={(_e) =>
            handleChange(
              'isEnabled',
              !(changes?.isEnabled ?? area?.isEnabled ?? false)
            )
          }
        >
          <Typography
            className={
              changes?.isEnabled ?? area?.isEnabled
                ? classes.success
                : classes.error
            }
            variant="subtitle2"
          >
            {changes?.isEnabled ?? area?.isEnabled
              ? 'PUBLISHED'
              : 'NOT PUBLISHED'}
          </Typography>
        </Button>
        <ButtonGroup>
          <Button
            disabled={saveDisabled}
            color="primary"
            variant="contained"
            endIcon={<Save />}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            disabled={Boolean(area?.foldersLength ?? true)}
            color="secondary"
            variant="contained"
            endIcon={<Delete />}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Box>

      <Box className={classes.row}>
        <Box className={classes.column}>
          <Box style={{ position: 'relative' }}>
            <Avatar
              className={classes.image}
              variant="rounded"
              src={
                picture
                  ? URL.createObjectURL(picture)
                  : changes?.imageUrl ?? area?.imageUrl ?? ''
              }
              alt="area"
            >
              <IconButton component="label">
                <input
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  accept="image/*"
                  id="upload-file"
                  multiple
                  type="file"
                />
                <Publish />
              </IconButton>
            </Avatar>
            <Tooltip title="Delete image">
              <span>
                <IconButton
                  size="small"
                  className={classes.deleteImage}
                  onClick={() => {
                    setPicture(undefined);
                    handleChange('imageUrl', '');
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <Box className={classes.row}>
            <Box
              flexBasis="40%"
              className={`${classes.column} ${classes.inputLeft}`}
            >
              <TextField
                margin="none"
                className={classes.input}
                type="color"
                variant="outlined"
                value={changes?.primaryColor ?? area?.primaryColor}
                onChange={(e) => handleChange('primaryColor', e.target.value)}
                label={
                  <Typography className={classes.labelText} variant="caption">
                    Primary Color
                  </Typography>
                }
              />
              <TextField
                margin="none"
                className={classes.input}
                type="color"
                variant="outlined"
                value={changes?.secondaryColor ?? area?.secondaryColor}
                onChange={(e) => handleChange('secondaryColor', e.target.value)}
                label={
                  <Typography className={classes.labelText} variant="caption">
                    Secondary Color
                  </Typography>
                }
              />
              <TextField
                margin="none"
                className={classes.input}
                type="color"
                variant="outlined"
                value={changes?.tertiaryColor ?? area?.tertiaryColor}
                onChange={(e) => handleChange('tertiaryColor', e.target.value)}
                label={
                  <Typography className={classes.labelText} variant="caption">
                    Tertiary Color
                  </Typography>
                }
              />
            </Box>
            <TextField
              margin="none"
              className={classes.inputRight}
              type="number"
              variant="outlined"
              value={changes?.sort ?? area?.sort}
              onChange={(e) => handleChange('sort', Number(e.target.value))}
              label={
                <Typography className={classes.labelText} variant="caption">
                  Sort
                </Typography>
              }
            />
          </Box>
        </Box>
        <Box className={classes.textFields}>
          {Object.values(LANGUAGES).map((language) => {
            const languageId = language.id;
            const areaLanguageIndex = area?.areaLanguages?.findIndex(
              (al) => al?.languageId === languageId
            );
            const changesLanguageIndex = changes?.areaLanguages?.findIndex(
              (al) => al?.languageId === languageId
            );

            const ali =
              areaLanguageIndex !== undefined && areaLanguageIndex !== -1
                ? areaLanguageIndex
                : area?.areaLanguages?.length ?? 0;
            const cli =
              changesLanguageIndex !== undefined && changesLanguageIndex !== -1
                ? changesLanguageIndex
                : changes?.areaLanguages?.length ?? 0;

            const areaLanguage = area?.areaLanguages
              ? area?.areaLanguages[ali]
              : undefined;
            const changesLanguage = changes?.areaLanguages
              ? changes?.areaLanguages[cli]
              : undefined;

            return (
              <TextField
                key={languageId}
                className={classes.textField}
                fullWidth
                margin="normal"
                variant="outlined"
                value={changesLanguage?.name ?? areaLanguage?.name}
                onChange={(e) =>
                  handleChange(`areaLanguages[${cli ?? ali}]`, {
                    languageId,
                    name: e.target.value
                  })
                }
                label={
                  <Typography className={classes.labelText} variant="caption">
                    Name for&nbsp;&nbsp;
                    <img
                      className={classes.labelImage}
                      src={language?.flag ?? '/images/flags/INTL.svg'}
                      alt={`${language?.name ?? 'INTL'} flag`}
                    />
                  </Typography>
                }
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export const AreaForm = confirmHOC(AreaFormComp);
