import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  Link,
  Tooltip,
  makeStyles,
  Theme,
  createStyles,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  IconButton
} from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';

import { drawerRoutes } from '../constants/routes';
import { selectUser, logout } from '../features/authSlice';
import { drawerWidth } from '../theme';
import { AppBarButton } from './AppBarButton';
import { LANGUAGES } from '../constants/languages';
import { ControlPanel } from './ControlPanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    toolbar: {
      ...theme.mixins.toolbar,
      display: 'flex',
      justifyContent: 'space-between'
    },
    logo: {
      flexBasis: '10%'
    },
    divider: {
      backgroundColor: theme.palette.background.default
    },
    drawerPaper: {
      width: drawerWidth,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    drawerLink: {
      textDecoration: 'none',
      color: theme.palette.background.default,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  })
);

interface Props {
  window?: () => Window;
}

export function ResponsiveDrawer(props: Props) {
  const classes = useStyles();

  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <Box flexBasis="10%" display="flex">
            <img alt="Logo" height={50} src="/logo512.png" />
            {process.env.NODE_ENV === 'development' ? (
              <Typography variant="h5">DEV</Typography>
            ) : null}
          </Box>

          {user ? (
            <Box display="flex" justifyContent="center">
              {drawerRoutes
                .map<React.ReactNode>((route) => (
                  <NavLink
                    className={classes.drawerLink}
                    to={route.path}
                    key={route.path}
                  >
                    <Typography variant="h4">{route.text}</Typography>
                  </NavLink>
                ))
                .reduce((prev, curr, i) => [
                  prev,
                  <Divider
                    key={`divider${i}`}
                    className={classes.divider}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />,
                  curr
                ])}
            </Box>
          ) : null}

          {user ? (
            <Box flexBasis="10%" display="flex" justifyContent="flex-end">
              <AppBarButton text={user?.username}>
                <Box padding={2} display="flex" flexDirection="column">
                  <Typography align="center" variant="overline">
                    {user?.role ?? ''}
                  </Typography>
                  <Typography align="center" variant="subtitle1">
                    {user?.username.toUpperCase()}
                  </Typography>
                  <Link href={`mailto:${user?.email}`}>
                    <Typography align="center">{user?.email}</Typography>
                  </Link>
                  <Box>
                    <Box
                      display="flex"
                      margin={2}
                      justifyContent="space-between"
                    >
                      <Typography variant="subtitle2">
                        Primary Language:
                      </Typography>
                      <img
                        width="20px"
                        alt="primaryLanguageFlag"
                        src={LANGUAGES[user.primaryLanguageId].flag}
                      />
                    </Box>
                    <Box
                      display="flex"
                      margin={2}
                      justifyContent="space-between"
                    >
                      <Typography variant="subtitle2">
                        Secondary Language:
                      </Typography>
                      <img
                        width="20px"
                        alt="secondaryLanguageFlag"
                        src={LANGUAGES[user.secondaryLanguageId].flag}
                      />
                    </Box>
                    {user.tertiaryLanguageId && (
                      <Box
                        display="flex"
                        margin={2}
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          Tertiary Language:
                        </Typography>
                        <img
                          width="20px"
                          alt="tertiaryLanguageFlag"
                          src={
                            LANGUAGES[user.tertiaryLanguageId].flag ??
                            '/flags/INTL.svg'
                          }
                        />
                      </Box>
                    )}
                  </Box>

                  <ControlPanel user={user} />
                </Box>
              </AppBarButton>

              <Tooltip title="Logout">
                <IconButton color="inherit" onClick={() => dispatch(logout())}>
                  <PowerSettingsNew />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}
        </Toolbar>
      </AppBar>

      <div className={classes.toolbar} />
    </div>
  );
}
