import React from 'react';
import {
  TableCell,
  makeStyles,
  createStyles,
  Theme,
  Box
} from '@material-ui/core';

import { Pair } from '../../interfaces';
import { PairChip } from '../PairChip';

interface Props {
  pairs: Pair[];
  listPairs?: any[];
  className: string;
  term: string;
  chainIds: number[];
  onSubmit?: (pairId: number) => Promise<void>;
  tagId?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'justify',
      alignItems: 'start',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(1)
    },
    container: {
      marginBottom: theme.spacing(1)
    },
    chip: {
      marginRight: theme.spacing(0.5)
    },
    redMark: {
      backgroundColor: theme.palette.error.main
    }
  })
);

export const SearchTableCell = ({
  pairs,
  listPairs,
  tagId,
  className,
  term,
  chainIds,
  onSubmit
}: Props) => {
  const classes = useStyles();

  const normalize = (string: string) =>
    string
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

  const findTerm = (pair: Pair): React.ReactNode => {
    const normalizedName = normalize(pair.name);
    const normalizedTerm = normalize(term);
    if (listPairs && listPairs.some((p) => p.pairId === pair.id)) {
      return <mark className={classes.redMark}>{pair.name}</mark>;
    } else if (term && normalizedName.includes(normalizedTerm)) {
      const markedName = normalizedName.replace(
        normalizedTerm,
        `|${normalizedTerm}|`
      );
      const name = pair.name.slice(
        markedName.indexOf('|'),
        markedName.lastIndexOf('|') - 1
      );
      const [nameStart, nameEnd] = pair.name.split(name);

      return (
        <span>
          {nameStart}
          <mark>{name}</mark>
          {nameEnd}
        </span>
      );
    } else if (pair.tags?.some((tag) => tag.id === tagId)) {
      return <mark>{pair.name}</mark>;
    }
    return pair.name;
  };

  return (
    <TableCell className={className}>
      <Box className={classes.root}>
        {pairs &&
          pairs.map((pair) => (
            <Box className={classes.container} flexBasis="20%" key={pair.id}>
              <PairChip
                regionId={pair.regionId}
                main={pair.main}
                isEnabled={pair.isEnabled}
                name={findTerm(pair)}
                chainId={pair.chainId}
                chainIds={chainIds}
                onClick={onSubmit ? () => onSubmit(pair.id) : undefined}
              />
            </Box>
          ))}
      </Box>
    </TableCell>
  );
};
