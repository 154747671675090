import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  makeStyles,
  Theme,
  IconButton,
  Typography,
  Avatar,
  Card,
  CircularProgress,
  CardContent,
  Tooltip,
  Icon,
  DialogTitle,
  Dialog,
  DialogContent,
  useTheme
} from '@material-ui/core';
import {
  Add,
  ArrowBackIos,
  RadioButtonChecked,
  Close
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import { AreaLanguage, Folder, FolderLanguage } from '../interfaces';
import { selectUser } from '../features/authSlice';
import { DialogResponsive } from '../components/DialogResponsive';
import { FolderForm } from '../components/Folder';
import { getByLanguageId } from '../utils/model';
import {
  loadArea,
  refetchCurrentArea,
  selectAreasById,
  selectLoading,
  setCurrentAreaId
} from '../features/areaSlice';
import { ListForm } from '../components/List/ListForm';
import { TITLE } from '../constants';
import { ListCard } from '../components/List/ListCard';
import { AreaForm } from '../components/Area';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    margin: theme.spacing(2, 0)
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    verticalAlign: 'middle',
    padding: theme.spacing(2)
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    verticalAlign: 'middle',
    padding: theme.spacing(2, 2)
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  headerWrapper: {
    margin: theme.spacing(4, 0),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0),
    color: theme.palette.common.white
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 12),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark
  },
  imageContainer: {
    position: 'absolute',
    right: '5%'
  },
  avatar: {
    position: 'relative',
    overflow: 'visible',
    borderRadius: '100%',
    width: '12.5rem',
    height: '12.5rem',
    backgroundColor: theme.palette.background.default,
    border: `1.5rem solid ${theme.palette.background.default}`
  },
  image: {
    position: 'absolute',
    borderRadius: '100%',
    objectFit: 'cover',
    display: 'block',
    width: '12rem',
    height: '12rem',
    padding: '0.25rem'
  },
  listsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  listWrapper: {
    width: '80%',
    margin: theme.spacing(2, 2)
  },
  folderCard: {
    margin: theme.spacing(2, 0),
    width: '100%'
  }
}));

export const Area = () => {
  const [folder, setFolder] = React.useState<Folder>();
  const user = useSelector(selectUser);
  const { id } = useParams<{ id: string }>();
  const area = useSelector(selectAreasById)[id];
  const loading = useSelector(selectLoading);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const afterSubmit = () => {
    dispatch(refetchCurrentArea());
  };

  const handleOpen = (folder: Folder) => {
    setFolder(folder);
  };

  const handleClose = () => {
    setFolder(undefined);
  };

  const goBack = () => {
    history.goBack();
  };

  const goToList = (listId: number) => {
    history.push(`/list/${listId}`);
  };

  const contrastColor = React.useMemo(
    () =>
      area?.primaryColor
        ? theme.palette.getContrastText(area?.primaryColor)
        : '#000000',
    [theme.palette, area?.primaryColor]
  );

  React.useEffect(() => {
    if (location.hash && !loading) {
      document
        .getElementById(location.hash.slice(1))
        ?.scrollIntoView({ block: 'center' });
    }
  }, [location.hash, loading]);

  React.useEffect(() => {
    dispatch(setCurrentAreaId(Number(id)));
    document.title = `Area ${id} | ${TITLE}`;
    dispatch(loadArea(Number(id)));
  }, [dispatch, id]);

  if (loading) {
    return (
      <Box m={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box
        className={classes.headerWrapper}
        style={{ backgroundColor: area?.tertiaryColor }}
      >
        <Box
          className={classes.headerContainer}
          style={{ backgroundColor: area?.secondaryColor }}
        >
          <Box
            className={classes.header}
            style={{ backgroundColor: area?.primaryColor }}
          >
            <Box display="flex" alignItems="center" flexBasis="20%">
              <IconButton onClick={goBack} style={{ color: contrastColor }}>
                <ArrowBackIos color="inherit" />
              </IconButton>
              <DialogResponsive
                color="secondary"
                variant="contained"
                afterSubmit={afterSubmit}
                title="Edit Area"
                maxWidth="md"
                FormComponent={(formProps) => (
                  <AreaForm area={area} {...formProps} />
                )}
              />
            </Box>
            <Typography variant="h3" style={{ color: contrastColor }}>
              {getByLanguageId<AreaLanguage>(
                area?.areaLanguages,
                user?.primaryLanguageId
              )?.name ?? '-'}
            </Typography>
            <Box flexBasis="20%" />
            <Box className={classes.imageContainer}>
              <Avatar
                className={classes.avatar}
                src={area?.imageUrl ?? '/logo-bw.png'}
                imgProps={{
                  className: classes.image,
                  style: {
                    border: `1rem solid ${area?.primaryColor ?? grey[500]}`
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.listWrapper}>
        {(area?.folders ?? []).map((f) => (
          <Box key={f.id} id={f.id.toString()}>
            <Card className={classes.folderCard}>
              <Box className={classes.cardHeader}>
                <DialogResponsive
                  title="Edit Folder"
                  maxWidth="md"
                  FormComponent={(formProps) => (
                    <FolderForm
                      {...formProps}
                      folder={f}
                      areaId={id}
                      languageId={user?.primaryLanguageId}
                    />
                  )}
                />
                <Typography variant="h3">
                  {getByLanguageId<FolderLanguage>(
                    f.folderLanguages,
                    user?.primaryLanguageId
                  )?.name ?? '-'}
                </Typography>
                <Box className={classes.row}>
                  <Tooltip title="Is it enabled?">
                    <Icon
                      className={f.isEnabled ? classes.success : classes.error}
                    >
                      <RadioButtonChecked />
                    </Icon>
                  </Tooltip>
                  <Typography variant="h6">
                    &nbsp;{f.lists.length} lists
                  </Typography>
                </Box>
              </Box>
              <CardContent className={classes.listsWrapper}>
                {f.lists && f.lists.length
                  ? f.lists.map((list) => (
                      <ListCard
                        key={list.id}
                        user={user}
                        list={list}
                        onClick={() => goToList(list.id)}
                      />
                    ))
                  : undefined}
                <Tooltip title="Add">
                  <IconButton
                    color="primary"
                    size="medium"
                    onClick={() => handleOpen(f)}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </CardContent>
            </Card>
          </Box>
        ))}
        <DialogResponsive
          isNew
          title="New Folder"
          maxWidth="md"
          FormComponent={(formProps) => (
            <FolderForm
              {...formProps}
              areaId={id}
              languageId={user?.primaryLanguageId}
              maxSort={Math.max(...(area?.folders?.map((f) => f.sort) ?? []))}
            />
          )}
        />
        <Dialog open={Boolean(folder)} onClose={handleClose}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              Create List
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <ListForm
              afterSubmit={handleClose}
              onClose={handleClose}
              folderId={folder?.id}
              maxSort={Math.max(...(folder?.lists?.map((l) => l.sort) ?? []))}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};
