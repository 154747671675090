import React from 'react';
import {
  Avatar,
  Box,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  useTheme
} from '@material-ui/core';
import { LANGUAGES, REGIONS } from '../constants/languages';

export type Size = 's' | 'm' | 'l';

interface Props {
  disabled?: boolean;
  regionId?: number | null;
  languageId?: number;
  onlyLanguage?: boolean;
  onChange?: (regionId: number | null) => void;
  size?: Size;
}

const useStyles = makeStyles((theme: Theme) => ({
  flagButton: {
    padding: theme.spacing(0),
    minWidth: theme.spacing(0),
    marginRight: theme.spacing(1)
  },
  flex: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  flag: {
    width: theme.spacing(4.5),
    height: theme.spacing(3)
  }
}));

export const RegionButton = ({
  disabled = false,
  regionId,
  languageId,
  onlyLanguage = false,
  onChange,
  size = 'm'
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const language = languageId ? LANGUAGES[languageId] : undefined;
  const region = regionId ? REGIONS[regionId] : undefined;
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Component: React.ElementType = React.useMemo(
    () => (disabled || !onChange ? Box : Button),
    [disabled, onChange]
  );

  const flagDimensions = React.useMemo(() => {
    const parseDimensions = (value: number) => {
      const sizes: { [key: string]: number } = {
        s: 0.5,
        m: 1,
        l: 2
      };
      return value * sizes[size];
    };
    return {
      width: theme.spacing(parseDimensions(4.5)),
      height: theme.spacing(parseDimensions(3))
    };
  }, [theme, size]);

  const flag = React.useMemo(() => {
    if (onlyLanguage) {
      return language ? language.flag : '/images/flags/INTL.svg';
    }
    return region ? region.flag : '/images/flags/INTL.svg';
  }, [region, language, onlyLanguage]);

  return (
    <>
      <Tooltip
        arrow
        title={
          onlyLanguage
            ? language?.name ?? 'No language'
            : region?.name ?? 'No region'
        }
      >
        <span className={classes.flex}>
          <Component
            disabled={disabled || !onChange}
            className={classes.flagButton}
            onClick={handleClick}
          >
            <Avatar style={flagDimensions} variant="rounded" src={flag} />
          </Component>
        </span>
      </Tooltip>
      {languageId && onChange ? (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem
            selected={!region?.id}
            onClick={
              onChange
                ? () => {
                    onChange(null);
                    handleClose();
                  }
                : undefined
            }
          >
            All
          </MenuItem>
          {LANGUAGES[languageId.toString()].regions.map((languageRegion) => (
            <MenuItem
              key={languageRegion.id}
              selected={languageRegion.id === region?.id}
              onClick={
                onChange
                  ? () => {
                      onChange(languageRegion.id);
                      handleClose();
                    }
                  : undefined
              }
            >
              {languageRegion.name}
            </MenuItem>
          ))}
        </Menu>
      ) : undefined}
    </>
  );
};
