import React from 'react';
import { cloneDeep, merge } from 'lodash';

import {
  Box,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  Button,
  CardActions,
  Tooltip,
  Chip
} from '@material-ui/core';
import { FormatListBulleted, ExpandMore, ExpandLess } from '@material-ui/icons';

import { Pair, PairStateColor } from '../../interfaces';
import { ChainPairTagDialog } from './ChainPairTagDialog';
import { ChainPairListDialog } from './ChainPairListDialog';
import { ChainInflexionsDialog } from './ChainInflexionsDialog';
import { patchPair } from '../../api/pair';
import { RegionButton } from '../RegionButton';

interface Props {
  pair: Pair;
  pairIds: number[];
  hasMainPairRegions: { [languageId: number]: boolean };
  setHasMainPairRegions: React.Dispatch<
    React.SetStateAction<{ [languageId: number]: boolean }>
  >;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  textWrapper: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0, 3),
    color: theme.palette.common.white,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  name: {
    '&::selection': {
      backgroundColor: theme.palette.common.white
    }
  },
  button: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:disabled': {
      backgroundColor: theme.palette.grey[400]
    },
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  iconButton: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  info: {
    '&:hover': {}
  },
  icon: {
    color: theme.palette.common.white,
    width: theme.spacing(2)
  },

  cardActions: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  }
}));

enum PairStateName {
  enabled = 'Enabled',
  disabled = 'Disabled'
}

interface PairState {
  name: PairStateName;
  color: PairStateColor;
}

export const ChainPair = ({
  pair: pairProps,
  pairIds,
  hasMainPairRegions,
  setHasMainPairRegions
}: Props) => {
  const [pair, setPair] = React.useState(pairProps);
  const [changes, setChanges] = React.useState<any>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [tagDialogOpen, setTagDialogOpen] = React.useState<boolean>(false);
  const [inflexionsDialogOpen, setInflexionsDialogOpen] =
    React.useState<boolean>(false);
  const classes = useStyles({
    hasLists: !!pair.lists && pair.lists.length,
    hasInflexions: !!pair.inflexions && pair.inflexions.length
  });
  const hasMainPair = hasMainPairRegions[pair.regionId ?? 0];

  const pairState: PairState = React.useMemo(() => {
    if (pair.isEnabled) {
      return {
        name: PairStateName.enabled,
        color: pair.main
          ? PairStateColor.enabledMainColor
          : PairStateColor.enabledColor
      };
    }
    return {
      name: PairStateName.disabled,
      color: pair.main
        ? PairStateColor.disabledMainColor
        : PairStateColor.disabledColor
    };
  }, [pair]);

  React.useEffect(() => {
    const saveChanges = async () => {
      if (changes) {
        await patchPair(pair.id, changes);
        const clonedChanges = cloneDeep(changes);
        setChanges(undefined);
        const merged = merge(cloneDeep(pair), clonedChanges);
        setPair(merged);
      }
    };
    saveChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes]);

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Box className={classes.row}>
            <Tooltip
              title={
                !pair.listsLength
                  ? pairState.name
                  : "Can't disable pair in list"
              }
              placement="top"
              arrow
            >
              <Box
                onClick={() => {
                  if (!pair.listsLength) {
                    setChanges({ ...changes, isEnabled: !pair.isEnabled });
                  }
                }}
                className={classes.textWrapper}
                style={{ backgroundColor: pairState.color }}
              >
                <Typography className={classes.name} variant="h6">
                  {pair.name}
                </Typography>
              </Box>
            </Tooltip>

            <Tooltip
              title={
                hasMainPair && !pair.main
                  ? 'There is already a main pair'
                  : pair.main
                  ? 'Set Not Main'
                  : 'Set Main'
              }
              placement="top"
              arrow
            >
              <span>
                <IconButton
                  className={classes.iconButton + ' ' + classes.info}
                  onClick={async () => {
                    setChanges({ ...changes, main: !pair.main });
                    setHasMainPairRegions({
                      ...hasMainPairRegions,
                      [pair.regionId ?? 0]: !pair.main
                    });
                  }}
                  disabled={
                    (hasMainPair && !pair.main) ||
                    (Boolean(pair.regionId) &&
                      hasMainPairRegions[0] &&
                      !pair.main) ||
                    (!pair.regionId &&
                      Object.values(hasMainPairRegions).some((p) => p) &&
                      !pair.main)
                  }
                >
                  {pair.main ? <ExpandMore /> : <ExpandLess />}
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <Tooltip title="Inflexions" placement="top" arrow>
            <span>
              <IconButton
                className={
                  classes.iconButton +
                  ' ' +
                  (pair.inflexionsLength ? classes.success : classes.error)
                }
                onClick={() => setInflexionsDialogOpen(true)}
              >
                <FormatListBulleted className={classes.icon} />
              </IconButton>
            </span>
          </Tooltip>
        </CardContent>
        <CardActions className={classes.row}>
          <Box display="flex" alignItems="center">
            <RegionButton
              languageId={pair.languageId}
              regionId={pair.regionId}
              onChange={(regionId) => {
                setHasMainPairRegions({
                  ...hasMainPairRegions,
                  [pair.regionId ?? 0]: false
                });
                setChanges({ ...changes, regionId, main: false });
              }}
            />
            {pair.tags && pair.tags.length ? (
              pair.tags.map((tag) => (
                <Chip
                  key={tag.id}
                  clickable
                  onClick={() => setTagDialogOpen(true)}
                  className={classes.chip}
                  color="primary"
                  label={tag.abbreviation}
                />
              ))
            ) : (
              <Chip
                clickable
                onClick={() => setTagDialogOpen(true)}
                className={classes.chip}
                color="secondary"
                label="Tags"
              />
            )}
          </Box>
          <Button
            disabled={!pair.listsLength}
            className={classes.button}
            onClick={() => setOpen(true)}
          >
            {pair.listsLength} LISTS
          </Button>
        </CardActions>
      </Card>
      <ChainPairListDialog
        pairId={pair.id}
        open={open}
        setOpen={setOpen}
        pairLanguageId={pair.languageId}
      />
      <ChainPairTagDialog
        open={tagDialogOpen}
        setOpen={setTagDialogOpen}
        pair={pair}
        setPair={setPair}
      />
      <ChainInflexionsDialog
        open={inflexionsDialogOpen}
        setOpen={setInflexionsDialogOpen}
        pairId={pair.id}
        pairIds={pairIds}
      />
    </>
  );
};
