import React from 'react';
import { useSelector } from 'react-redux';

import {
  capitalize,
  FormControl,
  InputLabel,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';

import { selectChainTags, selectPairTags } from '../../features/tagSlice';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
}));

interface IProps {
  className?: string;
  onFilterTag(tagId: number): void;
  tagId: number;
  isChain: boolean;
}

export const TagFilter = ({
  className,
  onFilterTag,
  isChain,
  tagId
}: IProps) => {
  const chainTags = useSelector(selectChainTags);
  const pairTags = useSelector(selectPairTags);
  const classes = useStyles();

  const handleChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void => {
    const { value: tagId } = e.target;
    onFilterTag(tagId as number);
  };

  const tags = React.useMemo(
    () => (isChain ? chainTags : pairTags),
    [isChain, chainTags, pairTags]
  );

  return (
    <FormControl className={className} variant="outlined">
      <InputLabel htmlFor="tag-select">Tag</InputLabel>
      <Select
        classes={{ selectMenu: classes.option }}
        id="tag-select"
        value={tagId}
        onChange={handleChange}
      >
        <ListSubheader className={classes.header}>
          {isChain ? 'Chain Tags' : 'Pair Tags'}
        </ListSubheader>
        <MenuItem className={classes.option} alignItems="center" value={0}>
          None
        </MenuItem>
        {tags.map(({ id, name, abbreviation }) => {
          return (
            <MenuItem key={id} className={classes.option} value={id}>
              <span>{abbreviation}</span>
              {capitalize(name)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
