import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getuserData } from '../features/authSlice';
import { HOME_ROUTE } from '../constants/routes';

interface MainRouterProps {
  children: ReactNode;
}
export const MainRouter = ({ children }: MainRouterProps) => {
  const [isChecking, setIsChecking] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getuserData());
      } catch (err: any) {
        history.push(HOME_ROUTE);
      } finally {
        setIsChecking(false);
      }
    })();
  }, [history, dispatch]);

  return (
    <Box textAlign="center" p={0}>
      {isChecking ? (
        <Box p={2}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};
