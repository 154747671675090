import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  makeStyles,
  Theme,
  Typography,
  TextField,
  Button,
  ButtonGroup
} from '@material-ui/core';
import { Delete, Save } from '@material-ui/icons';

import { set } from 'lodash';

import { List } from '../../interfaces';
import { LANGUAGES } from '../../constants/languages';
import { createList, updateList, deleteList } from '../../features/listSlice';
import { refetchCurrentUnit } from '../../features/unitSlice';
import { mergeLanguageModel } from '../../utils/model';
import { selectUser } from '../../features/authSlice';
import { IsEnabled } from '../IsEnabled';
import { useHistory } from 'react-router-dom';
import { refetchCurrentArea } from '../../features/areaSlice';
import { confirmHOC } from '../hocs/confirmHOC';
import { ListAutocomplete } from './ListAutocomplete';

interface Props {
  afterSubmit: () => void;
  onClose: () => void;
  list?: List;
  languageId?: number;
  folderId?: number;
  maxSort?: number;
  unitId?: number;
  sectionId?: number;
  isTutorial?: boolean;
  confirm?: (onConfirm: () => void, contentLength?: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    margin: theme.spacing(0, 2)
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0)
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%'
  },

  deleteImage: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    top: -10,
    right: -10,

    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    },
    '&:disabled': {
      display: 'none'
    }
  },
  textFields: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    marginLeft: theme.spacing(4)
  },
  isEnabled: {
    marginRight: theme.spacing(1)
  },
  textField: {
    marginTop: theme.spacing(0),
    flexBasis: '90%'
  },
  image: {
    width: '100%',
    height: theme.spacing(30),
    marginBottom: theme.spacing(2)
  },
  inputLeft: {
    marginRight: theme.spacing(1)
  },
  input: {
    marginBottom: theme.spacing(2)
  },
  inputRight: {
    marginLeft: theme.spacing(1),
    width: '40%'
  },
  color: {
    padding: theme.spacing(0)
  },
  labelText: {
    display: 'flex',
    alignItems: 'center'
  },
  labelImage: {
    height: '1em'
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
}));

const ListFormComp = ({
  afterSubmit,
  maxSort,
  isTutorial,
  onClose,
  folderId,
  sectionId,
  unitId,
  list: listProp,
  confirm
}: Props) => {
  const [list, setList] = React.useState(listProp);
  const [changes, setChanges] = React.useState<List>();
  const user = useSelector(selectUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const saveDisabled = list
    ? !changes
    : (!changes?.folderId &&
        !changes?.isTutorial &&
        !changes?.unitId &&
        !sectionId) ||
      !changes?.sort ||
      !changes?.listLanguages;

  const handleChange = React.useCallback(
    (path: string, value: unknown, path2?: string, value2?: unknown) => {
      const newChanges = { ...changes } as List;
      set(newChanges, path, value);
      if (path2) {
        set(newChanges, path2, value2);
      }
      setChanges(newChanges);
    },
    [changes]
  );

  const handleDelete = () => {
    if (list && confirm) {
      confirm(() => {
        dispatch(deleteList(list.id));
        onClose();
        if (!list.folderId && !list.unitId) {
          history.replace('/sections');
        } else {
          const model = list.folderId ? 'folder' : 'unit';
          history.replace(`/${model}/${list.folderId ?? list.unitId}`);
        }
      });
    }
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const newList = mergeLanguageModel(list, changes);
    if (newList) {
      if (list && changes) {
        await dispatch(updateList(list.id, newList));
      } else {
        await dispatch(createList(newList));
        await dispatch(refetchCurrentUnit());
        await dispatch(refetchCurrentArea());
        if (afterSubmit) {
          afterSubmit();
        } else {
          onClose();
        }
      }
      setList(newList);
      setChanges(undefined);
      onClose();
    }
  };

  React.useEffect(() => {
    if (!list && !changes) {
      if (folderId) {
        handleChange(
          'folderId',
          folderId,
          'sort',
          (maxSort !== Number.NEGATIVE_INFINITY ? maxSort ?? 0 : 0) + 10
        );
      } else if (unitId) {
        handleChange(
          'unitId',
          unitId,
          'sort',
          (maxSort !== Number.NEGATIVE_INFINITY ? maxSort ?? 0 : 0) + 10
        );
      } else if (isTutorial) {
        handleChange(
          'isTutorial',
          true,
          'sort',
          (maxSort !== Number.NEGATIVE_INFINITY ? maxSort ?? 0 : 0) + 10
        );
      } else {
        handleChange(
          'sort',
          (maxSort !== Number.NEGATIVE_INFINITY ? maxSort ?? 0 : 0) + 10
        );
      }
    }
  }, [folderId, maxSort, unitId, isTutorial, list, changes, handleChange]);

  return (
    <Box className={classes.root}>
      <Box className={classes.row}>
        <Box />
        <ButtonGroup>
          <Button
            disabled={saveDisabled}
            color="primary"
            variant="contained"
            endIcon={<Save />}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            disabled={Boolean(list?.pairs?.length ?? true)}
            color="secondary"
            variant="contained"
            endIcon={<Delete />}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Box>

      <Box className={classes.row}>
        <Box className={classes.column}>
          <Box className={classes.row}>
            <ListAutocomplete
              user={user}
              unitId={list?.unitId ?? unitId}
              folderId={list?.folderId ?? folderId}
              handleChange={handleChange}
            />
            <TextField
              margin="none"
              className={classes.inputRight}
              type="number"
              variant="outlined"
              value={changes?.sort ?? list?.sort ?? ''}
              onChange={(e) => handleChange('sort', Number(e.target.value))}
              label={
                <Typography className={classes.labelText} variant="caption">
                  Sort
                </Typography>
              }
            />
          </Box>
        </Box>
        <Box className={classes.textFields}>
          {Object.values(LANGUAGES).map((language) => {
            const languageId = language.id;
            const listLanguageIndex = list?.listLanguages?.findIndex(
              (al) => al?.languageId === languageId
            );
            const changesLanguageIndex = changes?.listLanguages?.findIndex(
              (al) => al?.languageId === languageId
            );

            const ali =
              listLanguageIndex !== undefined && listLanguageIndex !== -1
                ? listLanguageIndex
                : list?.listLanguages?.length ?? 0;
            const cli =
              changesLanguageIndex !== undefined && changesLanguageIndex !== -1
                ? changesLanguageIndex
                : changes?.listLanguages?.length ?? 0;

            const listLanguage = list?.listLanguages
              ? list?.listLanguages[ali]
              : undefined;
            const changesLanguage = changes?.listLanguages
              ? changes?.listLanguages[cli]
              : undefined;

            return (
              <Box display="flex" alignItems="center" key={languageId}>
                <IsEnabled
                  className={classes.isEnabled}
                  isEnabled={
                    changesLanguage?.isEnabled ??
                    listLanguage?.isEnabled ??
                    false
                  }
                  onClick={(isEnabled) =>
                    handleChange(`listLanguages[${cli ?? ali}]`, {
                      languageId,
                      isEnabled,
                      name: changesLanguage?.name ?? listLanguage?.name ?? ''
                    })
                  }
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={changesLanguage?.name ?? listLanguage?.name ?? ''}
                  onChange={(e) =>
                    handleChange(`listLanguages[${cli ?? ali}]`, {
                      languageId,
                      isEnabled:
                        changesLanguage?.isEnabled ??
                        listLanguage?.isEnabled ??
                        false,
                      name: e.target.value
                    })
                  }
                  label={
                    <Typography className={classes.labelText} variant="caption">
                      Name for&nbsp;&nbsp;
                      <img
                        className={classes.labelImage}
                        src={language?.flag ?? '/images/flags/INTL.svg'}
                        alt={`${language?.name ?? 'INTL'} flag`}
                      />
                    </Typography>
                  }
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export const ListForm = confirmHOC(ListFormComp);
