import React from 'react';

import { makeStyles, Theme, Box } from '@material-ui/core';

import { Chain } from '../../interfaces';
import { User } from '../../features/authSlice/interfaces';

import { ChainPairList } from './ChainPairList';

interface Props {
  user?: User | null;
  chain?: Chain;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    justifyContent: 'space-around',
    flexFlow: 'row wrap'
  }
}));

export const ChainPairs = ({ user, chain }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <ChainPairList chain={chain} languageId={user?.primaryLanguageId} />
      <ChainPairList chain={chain} languageId={user?.secondaryLanguageId} />
      <ChainPairList chain={chain} languageId={user?.tertiaryLanguageId} />
    </Box>
  );
};
