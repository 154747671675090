import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import {
  Add,
  ArrowBackIos,
  Close,
  RadioButtonChecked
} from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DialogResponsive } from '../components/DialogResponsive';
import { FolderForm } from '../components/Folder';
import { ListCard } from '../components/List/ListCard';
import { ListForm } from '../components/List/ListForm';
import { SectionDialog } from '../components/Section/SectionDialog';
import { TITLE } from '../constants';
import { selectUser } from '../features/authSlice';
import {
  loadSection,
  loadSections,
  selectLoading,
  selectSections,
  setLoading
} from '../features/sectionSlice';
import { FolderLanguage } from '../interfaces';
import { getByLanguageId } from '../utils/model';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    margin: theme.spacing(2, 0)
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    verticalAlign: 'middle',
    padding: theme.spacing(2)
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    verticalAlign: 'middle',
    padding: theme.spacing(2, 2)
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  headerWrapper: {
    margin: theme.spacing(4, 0),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0),
    color: theme.palette.common.white
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 12),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark
  },
  imageContainer: {
    position: 'absolute',
    right: '5%'
  },
  avatar: {
    position: 'relative',
    overflow: 'visible',
    borderRadius: '100%',
    width: '12.5rem',
    height: '12.5rem',
    backgroundColor: theme.palette.background.default,
    border: `1.5rem solid ${theme.palette.background.default}`
  },
  image: {
    position: 'absolute',
    borderRadius: '100%',
    objectFit: 'cover',
    display: 'block',
    width: '12rem',
    height: '12rem',
    padding: '0.25rem'
  },
  listsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  listWrapper: {
    width: '80%',
    margin: theme.spacing(2, 2)
  },
  folderCard: {
    margin: theme.spacing(2, 0),
    width: '100%'
  }
}));

export const Section = () => {
  const { id } = useParams<{ id: string }>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [viewerOpen, setViewerOpen] = React.useState<boolean>(false);
  const section = useSelector(selectSections)[id];
  const loading = useSelector(selectLoading);
  const user = useSelector(selectUser);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goBack = () => {
    history.push('/sections');
  };

  const goToList = (listId: number) => {
    history.push(`/list/${listId}`);
  };

  const fetchSection = async () => {
    dispatch(setLoading(true));
    dispatch(loadSections(user!.primaryLanguageId, false));
    await dispatch(loadSection(Number(id), user!.primaryLanguageId));
    dispatch(setLoading(false));
  };

  const afterSubmit = async () => {
    await fetchSection();
    handleClose();
  };

  React.useEffect(() => {
    (async () => {
      await fetchSection();
      if (section?.name) document.title = `${section.name} | ${TITLE}`;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, user]);

  if (loading) {
    return (
      <Box m={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box
        className={classes.headerWrapper}
        style={{ backgroundColor: grey[400] }}
      >
        <Box
          className={classes.headerContainer}
          style={{ backgroundColor: grey[600] }}
        >
          <Box
            className={classes.header}
            style={{ backgroundColor: grey[800] }}
          >
            <Box display="flex" alignItems="center" flexBasis="20%">
              <IconButton onClick={goBack}>
                <ArrowBackIos color="inherit" />
              </IconButton>
            </Box>
            <Typography variant="h3">{section.name}</Typography>
            <Box flexBasis="20%">
              <Tooltip title="Only available in English at the moment">
                <Button variant="contained" onClick={() => setViewerOpen(true)}>
                  View list
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.listWrapper}>
        {section.folders?.map((folder) => (
          <React.Fragment key={folder.id}>
            <Card key={folder.id} className={classes.folderCard}>
              <Box className={classes.cardHeader}>
                <DialogResponsive
                  afterSubmit={fetchSection}
                  title="Edit Folder"
                  maxWidth="md"
                  FormComponent={(formProps) => (
                    <FolderForm
                      {...formProps}
                      folder={folder}
                      sectionId={id}
                      languageId={user?.primaryLanguageId}
                    />
                  )}
                />
                <Typography variant="h3">
                  {getByLanguageId<FolderLanguage>(
                    folder.folderLanguages,
                    user?.primaryLanguageId
                  )?.name ?? '-'}
                </Typography>
                <Box className={classes.row}>
                  <Tooltip title="Is it enabled?">
                    <Icon
                      className={
                        folder.isEnabled ? classes.success : classes.error
                      }
                    >
                      <RadioButtonChecked />
                    </Icon>
                  </Tooltip>
                  <Typography variant="h6">
                    &nbsp;{folder.lists.length} lists
                  </Typography>
                </Box>
              </Box>
              <CardContent className={classes.listsWrapper}>
                {folder.lists && folder.lists.length
                  ? folder.lists.map((list) => (
                      <ListCard
                        key={list.id}
                        user={user}
                        list={list}
                        onClick={() => goToList(list.id)}
                      />
                    ))
                  : undefined}
                <Tooltip title="Add">
                  <IconButton
                    color="primary"
                    size="medium"
                    onClick={handleOpen}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </CardContent>
            </Card>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  Create List
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent dividers>
                <ListForm
                  folderId={folder.id}
                  afterSubmit={afterSubmit}
                  onClose={handleClose}
                  maxSort={Math.max(
                    ...(folder?.lists?.map((l) => l.sort) ?? [0, 1])
                  )}
                />
              </DialogContent>
            </Dialog>
          </React.Fragment>
        ))}
      </Box>
      <SectionDialog
        open={viewerOpen}
        setOpen={setViewerOpen}
        section={section}
        refresh={fetchSection}
      />
      <DialogResponsive
        afterSubmit={fetchSection}
        isNew
        title="New Folder"
        maxWidth="md"
        FormComponent={(formProps) => (
          <FolderForm
            {...formProps}
            sectionId={id}
            languageId={user?.primaryLanguageId}
          />
        )}
      />
    </Box>
  );
};
