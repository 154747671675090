import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { getSectionById, getSections } from '../../api/section';
import { Section } from '../../interfaces';
import { RootState } from '../../store';

import { SectionState } from './interfaces';

// Slice name
export const sectionSliceName = 'section';

// Initial State
const sectionInitialState: SectionState = {
  sections: {},
  loading: true,
  error: null
};

// Slice definition with reducers
const sectionSlice = createSlice({
  name: sectionSliceName,
  initialState: sectionInitialState,
  reducers: {
    setSections: (state: SectionState, action) => {
      const sections: Section[] = action.payload;
      state.sections = Object.values(sections).reduce(
        (acc, nextVal) => ({
          ...acc,
          [nextVal.id]: nextVal
        }),
        {}
      );
    },
    updateSection: (state: SectionState, action) => {
      const section: Section = action.payload;
      state.sections[section.id] = section;
    },
    setLoading: (state: SectionState, action) => {
      const payload: boolean = action.payload;
      state.loading = payload;
    },
    setError: (state: SectionState, action) => {
      const payload: string = action.payload;
      state.error = payload;
    }
  }
});

export const { setLoading } = sectionSlice.actions;

// Actions
const { setSections, updateSection, setError } = sectionSlice.actions;

// Thunks
export const loadSections =
  (languageId: number, endLoading = true) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true));

    try {
      const sections = await getSections(languageId);
      dispatch(setSections(sections));
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      if (endLoading) dispatch(setLoading(false));
    }
  };

export const loadSection =
  (sectionId: number, languageId: number) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true));

    try {
      const section = await getSectionById(sectionId, languageId);
      dispatch(updateSection(section));
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

// Selectors
export const selectSections = (state: RootState) =>
  state[sectionSliceName].sections;
export const selectLoading = (state: RootState) =>
  state[sectionSliceName].loading;
export const selectError = (state: RootState) => state[sectionSliceName].error;

// Reducer
export default sectionSlice.reducer;
