import React from 'react';

import {
  Box,
  Card,
  IconButton,
  LinearProgress,
  makeStyles,
  OutlinedInput,
  Typography
} from '@material-ui/core';
import { Delete, Send } from '@material-ui/icons';

import { fetchChatByRoomId } from '../api/chat';
import { useChat } from '../utils/hooks';
import { formatTimestampToDate, formatTimestampToTime } from '../utils/date';
import { ChatMessage } from '../interfaces/socket';

interface Props {
  roomId?: string;
  fetcher?: (roomId: string) => Promise<ChatMessage[]>;
  fetchedMessages?: ChatMessage[];
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: '20rem',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  author: { fontWeight: 'bold' },
  delete: { fontSize: '1rem' },
  progress: { width: '100%' },
  chat: {
    width: '100%',
    minHeight: '20rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflowY: 'scroll'
  },
  dateDivider: {
    alignSelf: 'center',
    fontWeight: 'bold',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 1),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)'
  },
  message: {
    textAlign: 'left',
    boxShadow: '-2px 2px 5px rgba(0, 0, 0, 0.5)',
    borderRadius: theme.spacing(0.5),
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    maxWidth: '70%',
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(1)
  },
  owned: {
    alignSelf: 'flex-end',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText
  },
  date: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'right',
    alignSelf: 'flex-end'
  },
  textArea: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: theme.palette.background.paper,
    ...theme.typography.body1
  }
}));

export const Chat = ({ roomId, fetchedMessages }: Props) => {
  const [message, setMessage] = React.useState<string>('');
  const { messages, sendMessage, deleteMessage, loading } = useChat(
    roomId,
    !fetchedMessages ? fetchChatByRoomId : undefined,
    fetchedMessages
  );
  const chatRef = React.useRef<HTMLDivElement | null>(null);
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setMessage(value);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey && message.trim()) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    sendMessage(message.trim());
    setMessage('');
  };

  React.useEffect(() => {
    if (!loading) {
      chatRef.current?.scrollTo(0, chatRef.current?.scrollHeight);
    }
  }, [messages, loading]);

  let currentDate: string;

  return (
    <Card className={classes.wrapper}>
      <div ref={chatRef} className={classes.chat}>
        {!loading ? (
          messages.map((msg) => {
            const formattedDate = formatTimestampToDate(msg.timestamp);
            const newDate = formattedDate !== currentDate;
            if (newDate) currentDate = formattedDate;
            return (
              <React.Fragment key={msg.id}>
                {newDate ? (
                  <Box className={classes.dateDivider}>
                    {formatTimestampToDate(msg.timestamp)}
                  </Box>
                ) : null}
                <Box
                  className={`${classes.message} ${
                    msg.owned ? classes.owned : ''
                  }`}
                >
                  <Box>
                    <Typography className={classes.author} variant="caption">
                      {msg.username}&nbsp;
                    </Typography>
                    {msg.owned ? (
                      <IconButton
                        edge="end"
                        onClick={() => deleteMessage(msg.id)}
                        size="small"
                      >
                        <Delete className={classes.delete} />
                      </IconButton>
                    ) : null}
                  </Box>
                  <Typography variant="body1">{msg.text}</Typography>
                  <Typography className={classes.date} variant="caption">
                    {formatTimestampToTime(msg.timestamp)}
                  </Typography>
                </Box>
              </React.Fragment>
            );
          })
        ) : (
          <LinearProgress classes={{ root: classes.progress }} />
        )}
      </div>
      <OutlinedInput
        className={classes.textArea}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        disabled={!roomId}
        multiline
        rowsMax={2}
        fullWidth
        value={message}
        placeholder="Type a message..."
        endAdornment={
          <IconButton disabled={!roomId} onClick={handleSubmit} size="small">
            <Send />
          </IconButton>
        }
      />
    </Card>
  );
};
