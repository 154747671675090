interface Language {
  name: string;
  flag: string;
  abbreviation: string;
  deeplLocale: string;
  id: number;
  regions: Region[];
}

interface Region {
  id: number;
  name: string;
  abbreviation: string;
  languageId: number;
  flag: string;
}

export const REGIONS: { [id: string]: Region } = {
  '1': {
    id: 1,
    name: 'Castilian',
    abbreviation: 'ES',
    flag: '/images/flags/ES.svg',
    languageId: 1
  },
  '2': {
    id: 2,
    name: 'Mexican',
    abbreviation: 'MX',
    flag: '/images/flags/MX.svg',
    languageId: 1
  },
  '3': {
    id: 3,
    name: 'United States',
    abbreviation: 'US',
    flag: '/images/flags/US.svg',
    languageId: 2
  },
  '4': {
    id: 4,
    name: 'United Kingdom',
    abbreviation: 'GB',
    flag: '/images/flags/GB.svg',
    languageId: 2
  },
  '5': {
    id: 5,
    name: 'Brazil',
    abbreviation: 'BR',
    flag: '/images/flags/BR.svg',
    languageId: 3
  },
  '6': {
    id: 6,
    name: 'France',
    abbreviation: 'FR',
    flag: 'images/flags/FR.svg',
    languageId: 4
  },
  '7': {
    id: 7,
    name: 'Germany',
    abbreviation: 'DE',
    flag: 'images/flags/DE.svg',
    languageId: 5
  },
  '8': {
    id: 8,
    name: 'Italy',
    abbreviation: 'IT',
    flag: 'images/flags/IT.svg',
    languageId: 6
  }
};

export const LANGUAGES: { [id: string]: Language } = {
  '1': {
    id: 1,
    name: 'Spanish',
    abbreviation: 'es',
    deeplLocale: 'ES',
    flag: '/images/flags/ES.svg',
    regions: [REGIONS[1], REGIONS[2]]
  },
  '2': {
    id: 2,
    name: 'English',
    abbreviation: 'en',
    deeplLocale: 'EN-US',
    flag: '/images/flags/GB.svg',
    regions: [REGIONS[3], REGIONS[4]]
  },
  '3': {
    id: 3,
    name: 'Portuguese',
    abbreviation: 'pt',
    deeplLocale: 'PT-BR',
    flag: '/images/flags/PT.svg',
    regions: [REGIONS[5]]
  },
  '4': {
    id: 4,
    name: 'French',
    abbreviation: 'fr',
    deeplLocale: 'FR',
    flag: '/images/flags/FR.svg',
    regions: [REGIONS[6]]
  },
  '5': {
    id: 5,
    name: 'German',
    abbreviation: 'de',
    deeplLocale: 'DE',
    flag: '/images/flags/DE.svg',
    regions: [REGIONS[7]]
  },
  '6': {
    id: 6,
    name: 'Italian',
    abbreviation: 'it',
    deeplLocale: 'IT',
    flag: '/images/flags/IT.svg',
    regions: [REGIONS[8]]
  }
};
