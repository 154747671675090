import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { ChainState } from '../chainSlice/interfaces';
import { getChainById, patchChain, postChain } from '../../api/chains';
import { Chain } from '../../interfaces';
import { RootState } from '../../store';
import { TITLE } from '../../constants';

// Slice name
export const chainSliceName = 'chain';

// Initial State
const chainInitialState: ChainState = {
  allChainIds: [],
  chainsIdByPage: { 0: [] },
  chainsId: {},
  currentId: undefined,
  totalPages: 1,
  loading: true,
  error: null
};

// Slice definition with reducers
const chainSlice = createSlice({
  name: chainSliceName,
  initialState: chainInitialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      const errorMessage: string = action.payload;
      state.error = errorMessage;
    },

    pushNewChain: (state, action) => {
      const chainFromApi: Chain = action.payload;
      state.chainsId = { ...state.chainsId, [chainFromApi.id]: chainFromApi };
      state.chainsIdByPage[0].unshift(chainFromApi.id.toString());
    },
    setCurrentChain: (state, action) => {
      const chainId: number = action.payload;
      state.currentId = chainId;
    },

    pushExistingChain: (state, action) => {
      const chainFromApi: Chain = action.payload;
      state.chainsId = { ...state.chainsId, [chainFromApi.id]: chainFromApi };
    },
    updateChain: (state, action) => {
      const changes: Chain = action.payload;
      const currentChain = state.chainsId[changes.id];
      state.chainsId[changes.id] = merge(currentChain, changes);
    }
  }
});

// Actions
// TODO: Remove when [this PR](https://github.com/benmosher/eslint-plugin-import/pull/2038) is merged.
// eslint-disable-next-line import/no-unused-modules
export const {
  setLoading,
  setError,
  updateChain: updateChainAction,
  setCurrentChain,
  pushExistingChain,
  pushNewChain
} = chainSlice.actions;

export const loadChain =
  (chainId: number, load: boolean = true) =>
  async (dispatch: Dispatch) => {
    try {
      if (load) dispatch(setLoading(true));
      const fetchedChain = await getChainById(chainId);
      dispatch(pushExistingChain(fetchedChain));
      dispatch(setCurrentChain(chainId));
      dispatch(setError(undefined));
    } catch (_err) {
      dispatch(setError('Chain not found'));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createChain = (chainData: Chain) => async (dispatch: Dispatch) => {
  const newChain = await postChain(chainData);
  dispatch(pushNewChain(newChain));
  document.title = `Chain ${newChain.id} | ${TITLE}`;
  const newUrl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    `/${newChain.id}`;
  window.history.pushState({ path: newUrl }, '', newUrl);
  window.location.reload();
};

export const updateChain = (chainData: Chain) => async (dispatch: Dispatch) => {
  await patchChain(chainData.id, chainData);
  dispatch(updateChainAction(chainData));
};

export const refetchCurrentChain =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setLoading(true));

    try {
      const { currentId } = getState()[chainSliceName];
      if (currentId) {
        const chain = await getChainById(currentId);
        dispatch(pushExistingChain(chain));
      }
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

// Selectors
export const selectChainsId = (state: RootState) =>
  state[chainSliceName].chainsId;
export const selectLoading = (state: RootState) =>
  state[chainSliceName].loading;
export const selectError = (state: RootState) => state[chainSliceName].error;

// Reducer
export default chainSlice.reducer;
