import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import {
  Box,
  makeStyles,
  Theme,
  Typography,
  TextField,
  Button,
  ButtonGroup,
  Avatar,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { Close, Delete, Publish, Save } from '@material-ui/icons';

import { set } from 'lodash';

import { Level, Unit } from '../../interfaces';
import { LANGUAGES } from '../../constants/languages';
import { createUnit, updateUnit, deleteUnit } from '../../features/unitSlice';
import { refetchCurrentCourse } from '../../features/courseSlice';
import { getByLanguageId, mergeLanguageModel } from '../../utils/model';
import { AsyncAutocomplete } from '../AsyncAutocomplete';
import { selectUser } from '../../features/authSlice';
import { confirmHOC } from '../hocs/confirmHOC';
import { IsEnabled } from '../IsEnabled';

interface Props {
  afterSubmit: () => void;
  onClose: () => void;
  unit?: Unit;
  maxSort?: number;
  languageId?: number;
  levelId?: number;
  confirm?: (onConfirm: () => void, contentLength?: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    margin: theme.spacing(0, 2)
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0)
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%'
  },

  deleteImage: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    top: -10,
    right: -10,

    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    },
    '&:disabled': {
      display: 'none'
    }
  },
  textFields: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    marginLeft: theme.spacing(4)
  },
  textField: {
    marginTop: theme.spacing(0)
  },
  image: {
    width: '100%',
    height: theme.spacing(30),
    marginBottom: theme.spacing(2)
  },
  inputLeft: {
    marginRight: theme.spacing(1)
  },
  input: {
    marginBottom: theme.spacing(2)
  },
  inputRight: {
    marginLeft: theme.spacing(1),
    width: '40%'
  },
  color: {
    padding: theme.spacing(0)
  },
  labelText: {
    display: 'flex',
    alignItems: 'center'
  },
  isEnabled: {
    marginRight: theme.spacing(1)
  },
  labelImage: {
    height: '1em'
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
}));

const UnitFormComp = ({
  afterSubmit,
  maxSort,
  onClose,
  levelId,
  unit: unitProp,
  confirm
}: Props) => {
  const [unit, setUnit] = React.useState(unitProp);
  const [changes, setChanges] = React.useState<Unit>();
  const [picture, setPicture] = React.useState<File>();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const saveDisabled = unit
    ? !changes && !picture
    : !changes?.levelId || !changes?.sort || !changes?.unitLanguages;

  const handleChange = React.useCallback(
    (path: string, value: unknown, path2?: string, value2?: unknown) => {
      const newChanges = { ...changes } as Unit;
      set(newChanges, path, value);
      if (path2) {
        set(newChanges, path2, value2);
      }
      setChanges(newChanges);
    },
    [changes]
  );

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      setPicture(files[0]);
    }
  };

  const handleDelete = async () => {
    if (unit && confirm) {
      confirm(async () => {
        const courseId = unit.courseId;
        await dispatch(deleteUnit(unit.id));
        onClose();
        await dispatch(refetchCurrentCourse());
        history.replace(`/course/${courseId}`);
      }, unit.lists.length);
    }
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const newUnit = mergeLanguageModel(unit, changes);
    if (newUnit) {
      if (picture) newUnit.picture = picture;
      if (newUnit.imageUrl !== '') delete newUnit.imageUrl;
      if (!newUnit.levelId) newUnit.levelId = Number(levelId);
      if (unit) {
        await dispatch(updateUnit(newUnit));
      } else {
        await dispatch(createUnit(newUnit));
        await dispatch(refetchCurrentCourse());
      }
      setUnit(newUnit);
      await afterSubmit();
      setChanges(undefined);
      onClose();
    }
  };

  React.useEffect(() => {
    if (!unit && !changes) {
      handleChange(
        'levelId',
        levelId,
        'sort',
        (maxSort !== Number.NEGATIVE_INFINITY ? maxSort ?? 0 : 0) + 10
      );
    }
  }, [levelId, maxSort, unit, changes, handleChange]);

  return (
    <Box className={classes.root}>
      <Box className={classes.row}>
        <div />
        <ButtonGroup>
          <Button
            disabled={saveDisabled}
            color="primary"
            variant="contained"
            endIcon={<Save />}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            disabled={Boolean(unit?.lists?.length ?? true)}
            color="secondary"
            variant="contained"
            endIcon={<Delete />}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Box>

      <Box className={classes.row}>
        <Box className={classes.column}>
          <Box style={{ position: 'relative' }}>
            <Avatar
              className={classes.image}
              variant="rounded"
              src={
                picture
                  ? URL.createObjectURL(picture)
                  : changes?.imageUrl ?? unit?.imageUrl ?? ''
              }
              alt="unit"
            >
              <IconButton component="label">
                <input
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  accept="image/*"
                  id="upload-file"
                  multiple
                  type="file"
                />
                <Publish />
              </IconButton>
            </Avatar>
            <Tooltip title="Delete image">
              <span>
                <IconButton
                  size="small"
                  className={classes.deleteImage}
                  onClick={() => {
                    setPicture(undefined);
                    handleChange('imageUrl', '');
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <Box className={classes.row}>
            <AsyncAutocomplete
              label="Level"
              defaultValueId={changes?.levelId ?? unit?.levelId}
              nameGetter={(level: Level) =>
                getByLanguageId(level.levelLanguages, user?.primaryLanguageId)
                  ?.name
              }
              onChange={(value) => handleChange('levelId', value?.id)}
            />
            <TextField
              margin="none"
              className={classes.inputRight}
              type="number"
              variant="outlined"
              value={changes?.sort ?? unit?.sort ?? ''}
              onChange={(e) => handleChange('sort', Number(e.target.value))}
              label={
                <Typography className={classes.labelText} variant="caption">
                  Sort
                </Typography>
              }
            />
          </Box>
        </Box>
        <Box className={classes.textFields}>
          {Object.values(LANGUAGES).map((language) => {
            const languageId = language.id;
            const unitLanguageIndex = unit?.unitLanguages?.findIndex(
              (al) => al?.languageId === languageId
            );
            const changesLanguageIndex = changes?.unitLanguages?.findIndex(
              (al) => al?.languageId === languageId
            );

            const ali =
              unitLanguageIndex !== undefined && unitLanguageIndex !== -1
                ? unitLanguageIndex
                : unit?.unitLanguages?.length ?? 0;
            const cli =
              changesLanguageIndex !== undefined && changesLanguageIndex !== -1
                ? changesLanguageIndex
                : changes?.unitLanguages?.length ?? 0;

            const unitLanguage = unit?.unitLanguages
              ? unit?.unitLanguages[ali]
              : undefined;
            const changesLanguage = changes?.unitLanguages
              ? changes?.unitLanguages[cli]
              : undefined;

            return (
              <Box display="flex" alignItems="center" key={languageId}>
                <IsEnabled
                  className={classes.isEnabled}
                  isEnabled={
                    changesLanguage?.isEnabled ??
                    unitLanguage?.isEnabled ??
                    false
                  }
                  onClick={(isEnabled) =>
                    handleChange(`unitLanguages[${cli ?? ali}]`, {
                      languageId,
                      isEnabled,
                      name: changesLanguage?.name ?? unitLanguage?.name ?? ''
                    })
                  }
                />
                <TextField
                  key={languageId}
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={changesLanguage?.name ?? unitLanguage?.name ?? ''}
                  onChange={(e) =>
                    handleChange(`unitLanguages[${cli ?? ali}]`, {
                      languageId,
                      name: e.target.value
                    })
                  }
                  label={
                    <Typography className={classes.labelText} variant="caption">
                      Name for&nbsp;&nbsp;
                      <img
                        className={classes.labelImage}
                        src={language?.flag ?? '/images/flags/INTL.svg'}
                        alt={`${language?.name ?? 'INTL'} flag`}
                      />
                    </Typography>
                  }
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export const UnitForm = confirmHOC(UnitFormComp);
