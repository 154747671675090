import React from 'react';

import {
  makeStyles,
  Theme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Button
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { User } from '../../features/authSlice/interfaces';
import { List } from '../../interfaces';
import { LanguageTableHead } from '../LanguageTableHead';
import { ListTableBody } from './ListTableBody';
import { getByLanguageId } from '../../utils/model';

interface Props {
  list?: List;
  user?: User | null;
  openEditDialog?: () => void;
  openPairDialog?: (
    languageId?: number,
    translationLanguageId?: number
  ) => void;
  loading: boolean;
  languageId?: number;
  translationLanguageId?: number;
  handleChange?: (pairId: number, sort: number) => void;
  handleDelete?: (pairId: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    margin: theme.spacing(4, 'auto')
  },
  main: {
    background: theme.palette.grey[200]
  },
  tableCell: {
    border: `1px solid ${theme.palette.divider}`,
    width: '24%'
  }
}));

export const ListTable = ({
  list,
  user,
  openEditDialog,
  openPairDialog,
  loading,
  languageId,
  translationLanguageId,
  handleChange,
  handleDelete
}: Props) => {
  const classes = useStyles();
  const primaryListLanguage = getByLanguageId(
    list?.listLanguages,
    user?.primaryLanguageId
  );
  const secondaryListLanguage = getByLanguageId(
    list?.listLanguages,
    user?.secondaryLanguageId
  );

  return (
    <TableContainer
      className={classes.root}
      component={Paper}
      style={{ width: languageId ? '100%' : '70%' }}
    >
      <Table>
        <TableHead>
          <TableRow className={classes.tableCell}>
            {!openEditDialog && <TableCell size="small" />}
            <TableCell size="small">{!openEditDialog && 'SORT'}</TableCell>
            <TableCell size="small">
              {openEditDialog && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={openEditDialog}
                  startIcon={<Edit />}
                >
                  EDIT
                </Button>
              )}
            </TableCell>
            <LanguageTableHead
              onClick={
                openPairDialog && primaryListLanguage
                  ? () =>
                      openPairDialog(
                        user?.primaryLanguageId,
                        user?.secondaryLanguageId
                      )
                  : undefined
              }
              topLeft
              pairsLength={primaryListLanguage?.pairsLength ?? 0}
              languageId={user?.primaryLanguageId ?? languageId}
            />
            <LanguageTableHead
              onClick={
                openPairDialog && secondaryListLanguage
                  ? () =>
                      openPairDialog(
                        user?.secondaryLanguageId,
                        user?.primaryLanguageId
                      )
                  : undefined
              }
              pairsLength={secondaryListLanguage?.pairsLength ?? 0}
              languageId={user?.secondaryLanguageId ?? translationLanguageId}
            />
            <LanguageTableHead topRight languageId={user?.tertiaryLanguageId} />
          </TableRow>
        </TableHead>
        <ListTableBody
          handleDelete={handleDelete}
          handleChange={handleChange}
          primaryListLanguageId={
            getByLanguageId(list?.listLanguages, languageId)?.id
          }
          secondaryListLanguageId={
            getByLanguageId(list?.listLanguages, translationLanguageId)?.id
          }
          loading={loading}
          rows={list?.pairs}
          tableCellClass={classes.tableCell}
          mainClass={classes.main}
          simple={!!languageId}
          languageId={languageId}
        />
      </Table>
    </TableContainer>
  );
};
