import React from 'react';

import { Chip, makeStyles, Theme, Box, useTheme } from '@material-ui/core';

import { PairStateColor } from '../interfaces';
import { RegionButton, Size } from './RegionButton';
import { Link } from 'react-router-dom';

interface Props {
  main?: boolean;
  isEnabled?: boolean;
  name?: string | React.ReactNode;
  regionId?: number | null;
  languageId?: number;
  onDelete?: () => void;
  onRegionChange?: (regionId: number | null) => void;
  onClick?: () => void;
  deleteIcon?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  column?: boolean;
  chainId?: number;
  chainIds?: number[];
  size?: Size;
}

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    fontSize: theme.spacing(2),
    fontWeight: 700,
    color: theme.palette.common.white,
    cursor: 'pointer'
  }
}));

export const PairChip = ({
  main,
  isEnabled,
  name = '-',
  onClick,
  onRegionChange,
  chainId,
  chainIds,
  onDelete,
  regionId,
  languageId,
  deleteIcon,
  size,
  column = false
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (onClick || onDelete || onRegionChange) {
      event.preventDefault();
      if (onClick) onClick();
    }
  };

  const handleDelete = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (onDelete) {
      event.preventDefault();
      onDelete();
    }
  };

  const margin = React.useMemo(
    () => ({
      marginRight: !column ? theme.spacing(1) : undefined,
      marginBottom: column ? theme.spacing(1) : undefined
    }),
    [column, theme]
  );

  const backgroundColor = React.useMemo(
    () =>
      main
        ? isEnabled
          ? PairStateColor.enabledMainColor
          : PairStateColor.disabledMainColor
        : isEnabled
        ? PairStateColor.enabledColor
        : PairStateColor.disabledColor,
    [isEnabled, main]
  );

  return (
    <Link
      onClick={handleClick}
      to={`/chain/${chainId}${
        chainIds ? `?chain-ids=${chainIds.join(',')}` : ''
      }`}
    >
      <Chip
        clickable={!!onClick}
        className={classes.chip}
        label={
          <Box display="flex" alignItems="center">
            <RegionButton
              onChange={onRegionChange}
              disabled={!onRegionChange}
              regionId={regionId}
              languageId={languageId}
              size={size}
            />
            {name}
          </Box>
        }
        style={{ backgroundColor, ...margin }}
        deleteIcon={deleteIcon}
        onDelete={onDelete ? handleDelete : undefined}
      />
    </Link>
  );
};
