import React, { useState, ReactElement } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Tooltip,
  Button,
  Box,
  IconButton
} from '@material-ui/core';
import { Add, Close, Edit } from '@material-ui/icons';

import { breakpointDownSm } from '../theme';
import { Transition } from './Transition';
interface PropsI {
  FormComponent: (props: any) => ReactElement;
  afterSubmit?: () => void;
  title: string;
  isNew?: boolean;
  text?: string;
  className?: string;
  startIcon?: React.ReactNode;
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
}

export function DialogResponsive({
  afterSubmit,
  FormComponent,
  text,
  isNew = false,
  title,
  startIcon,
  color = 'secondary',
  className,
  variant = 'contained',
  maxWidth = 'sm'
}: PropsI) {
  const fullScreen = useMediaQuery(breakpointDownSm);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    handleClose();
    if (afterSubmit) {
      afterSubmit();
    }
  };

  return (
    <div>
      <Tooltip title={text ?? (isNew ? 'Add' : 'Edit')}>
        <Button
          size="medium"
          className={className}
          color={color}
          variant={variant}
          onClick={handleClickOpen}
          startIcon={startIcon}
          endIcon={!startIcon ? isNew ? <Add /> : <Edit /> : null}
        >
          {text?.toUpperCase() ?? (isNew ? 'ADD' : 'EDIT')}
        </Button>
      </Tooltip>
      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth={maxWidth}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
      >
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>{title}</DialogTitle>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent dividers>
          <FormComponent afterSubmit={handleSubmit} onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
