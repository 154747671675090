import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../features/authSlice';
import { Redirect, Route } from 'react-router-dom';
import { HOME_ROUTE } from '../constants/routes';

export const ProtectedRoute = (props: any) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return isAuthenticated ? <Route {...props} /> : <Redirect to={HOME_ROUTE} />;
};
