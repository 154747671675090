import { Folder } from '../interfaces';
import { deleteRequest, postRequest, patchRequest } from './index';

const POST_FOLDER_ENDPOINT = '/folder';
const PUT_FOLDER_ENDPOINT = '/folder/';
const DELETE_FOLDER_ENDPOINT = '/folder/';

export const postFolder = (data: Folder) =>
  postRequest(POST_FOLDER_ENDPOINT, data);
export const patchFolder = (data: Folder, id: number) =>
  patchRequest(PUT_FOLDER_ENDPOINT + id, data);
export const deleteFolder = (id: number) =>
  deleteRequest(DELETE_FOLDER_ENDPOINT + id);
