import React from 'react';
import { useSelector } from 'react-redux';

import {
  makeStyles,
  Theme,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
  Typography,
  Box,
  Icon,
  Tooltip
} from '@material-ui/core';
import { RadioButtonChecked } from '@material-ui/icons';

import { DialogResponsive } from '../DialogResponsive';
import { Area } from '../../interfaces';
import { selectUser } from '../../features/authSlice';
import { AreaForm } from './AreaForm';
import { useHistory } from 'react-router-dom';

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: '30%',
    margin: theme.spacing(2, 'auto')
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  paddedText: {
    paddingLeft: theme.spacing(1),
    width: '70%'
  },
  media: {
    height: 250
  },
  bigFontButton: {
    fontSize: '1rem'
  },

  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  icon: {
    marginRight: theme.spacing(2)
  }
}));

export const AreaCard = (area: Area) => {
  const classes = styles();
  const { imageUrl, areaLanguages, foldersLength } = area;
  const user = useSelector(selectUser);
  const history = useHistory();
  const areaLanguage = areaLanguages.find(
    (al) => al.languageId === user?.primaryLanguageId
  );

  const openArea = () => {
    history.push(`/area/${area.id}`);
  };

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={openArea}>
        <CardMedia
          className={classes.media}
          component="img"
          image={imageUrl ?? '/images/boilerplate-bw.png'}
          title={areaLanguage?.name ?? '-'}
        />
      </CardActionArea>

      <CardActions className={classes.cardActions}>
        <Box className={classes.row}>
          <DialogResponsive
            title="Edit Area"
            maxWidth="md"
            FormComponent={(formProps) => (
              <AreaForm {...formProps} area={area} />
            )}
            color="primary"
            variant="outlined"
          />
          <Typography
            noWrap
            className={classes.paddedText}
            variant="h5"
            component="h2"
          >
            {areaLanguage?.name ?? '-'}
          </Typography>
        </Box>
        <Box className={classes.row}>
          <Tooltip title="Is it enabled?">
            <Icon className={area.isEnabled ? classes.success : classes.error}>
              <RadioButtonChecked />
            </Icon>
          </Tooltip>
          <Typography variant="h6">
            &nbsp;{foldersLength ?? 0} folders
          </Typography>
        </Box>
      </CardActions>
    </Card>
  );
};
