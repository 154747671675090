import Axios from 'axios';

const DEEPL_URL = 'https://api.deepl.com/v2/translate';
const AUTH_KEY = '1aec9c85-515a-7ede-87e1-a1d8bcc7ced2';

interface DeepLResponse {
  translations: {
    detected_source_language: string;
    text: string;
  }[];
}

export const translateText = async (
  targetLang: string,
  text: string
): Promise<DeepLResponse> => {
  const formData = new FormData();
  formData.append('auth_key', AUTH_KEY);
  formData.append('target_lang', targetLang);
  formData.append('text', text);

  const { data } = await Axios.post(DEEPL_URL, formData);
  return data;
};
