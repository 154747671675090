import Axios, { AxiosError, AxiosResponse } from 'axios';
import { isObject } from 'lodash';

import { SERVER_URL } from '../constants';

const apiUrl = SERVER_URL + 'api';

export const handleQueryParams = (query: {
  [key: string]: string | number | boolean | undefined | null;
}): string | void => {
  const queryParams = Object.keys(query)
    // eslint-disable-next-line array-callback-return
    .map((key) => {
      const value = query[key];
      if (value !== undefined && value !== null) {
        return `${key}=${encodeURIComponent(value)}`;
      }
    })
    .filter((value) => value)
    .join('&');

  if (queryParams) {
    return `?${queryParams}`;
  } else {
    return '';
  }
};

const responseHandler = async (res: AxiosResponse) => {
  // Handle in case there was ane error getting the response
  const { data, message }: { [key: string]: any } = res.data;
  if (!res?.data?.OK) {
    throw new Error(message);
  }

  return data;
};

const errorHandler = async (err: AxiosError, reload: boolean = false) => {
  // Handle in case there was ane error getting the response
  const { data, message }: { [key: string]: any } = err.response?.data;
  if (reload) {
    window.location.reload();
  }
  throw new Error(data ?? message ?? err.message);
};

export const getRequest = (endpoint: string, reload: boolean = false) =>
  Axios.get(`${apiUrl}${endpoint}`, { withCredentials: true })
    .then(responseHandler)
    .catch((err) => errorHandler(err, reload));

export const postRequest = (
  endpoint: string,
  body: any = {},
  form: boolean = false
) => {
  const formData = new FormData();
  if (form) {
    Object.keys(body).forEach((key) => {
      const value: any = body[key];
      if (Array.isArray(value)) {
        value.forEach((subValue) => {
          if (File.prototype.isPrototypeOf.call(File.prototype, subValue)) {
            formData.append(key, subValue);
          } else if (
            typeof subValue === 'object' &&
            subValue !== null &&
            !Array.isArray(subValue)
          ) {
            formData.append(key, JSON.stringify(subValue));
          } else {
            formData.append(key, subValue);
          }
        });
      } else if (File.prototype.isPrototypeOf.call(File.prototype, value)) {
        formData.append(key, value);
      } else if (isObject(value)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
  }

  return Axios.post(`${apiUrl}${endpoint}`, form ? formData : body, {
    withCredentials: true
  })
    .then(responseHandler)
    .catch(errorHandler);
};

export const putRequest = (endpoint: string, body?: object) =>
  Axios.put(`${apiUrl}${endpoint}`, body, { withCredentials: true })
    .then(responseHandler)
    .catch(errorHandler);

export const patchRequest = (
  endpoint: string,
  body?: any,
  form: boolean = false
) => {
  const formData = new FormData();
  if (form) {
    Object.keys(body).forEach((key) => {
      const value: any = body[key];
      if (Array.isArray(value)) {
        value.forEach((subValue) => {
          if (File.prototype.isPrototypeOf.call(File.prototype, subValue)) {
            formData.append(key, subValue);
          } else if (
            typeof subValue === 'object' &&
            subValue !== null &&
            !Array.isArray(subValue)
          ) {
            formData.append(key, JSON.stringify(subValue));
          } else {
            formData.append(key, subValue);
          }
        });
      } else if (File.prototype.isPrototypeOf.call(File.prototype, value)) {
        formData.append(key, value);
      } else if (isObject(value)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
  }

  return Axios.patch(`${apiUrl}${endpoint}`, form ? formData : body, {
    withCredentials: true
  })
    .then(responseHandler)
    .catch(errorHandler);
};

export const deleteRequest = (endpoint: string, data?: any) =>
  Axios.delete(`${apiUrl}${endpoint}`, { withCredentials: true, data })
    .then(responseHandler)
    .catch(errorHandler);
