import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  makeStyles,
  Theme,
  Typography,
  TextField,
  Button,
  ButtonGroup
} from '@material-ui/core';
import { Delete, RadioButtonChecked, Save } from '@material-ui/icons';

import { set } from 'lodash';

import { Area, Folder, Section } from '../../interfaces';
import { LANGUAGES } from '../../constants/languages';
import { getByLanguageId, mergeLanguageModel } from '../../utils/model';
import {
  createFolder,
  updateFolder,
  deleteFolder
} from '../../features/folderSlice';
import { confirmHOC } from '../hocs/confirmHOC';
import { AsyncAutocomplete } from '../AsyncAutocomplete';
import { selectUser } from '../../features/authSlice';

interface Props {
  afterSubmit: () => void;
  onClose: () => void;
  folder?: Folder;
  maxSort?: number;
  languageId?: number;
  areaId?: string;
  sectionId?: string;
  confirm?: (onConfirm: () => void, contentLength?: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    margin: theme.spacing(0, 2)
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0)
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%'
  },

  deleteImage: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    top: -10,
    right: -10,

    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    },
    '&:disabled': {
      display: 'none'
    }
  },
  textFields: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    marginLeft: theme.spacing(4)
  },
  textField: {
    marginTop: theme.spacing(0)
  },
  image: {
    width: '100%',
    height: theme.spacing(30),
    marginBottom: theme.spacing(2)
  },
  inputLeft: {
    marginRight: theme.spacing(1)
  },
  input: {
    marginBottom: theme.spacing(2)
  },
  inputRight: {
    marginLeft: theme.spacing(1),
    width: '20%'
  },
  color: {
    padding: theme.spacing(0)
  },
  labelText: {
    display: 'flex',
    alignItems: 'center'
  },
  labelImage: {
    height: '1em'
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
}));

const FolderFormComp = ({
  confirm,
  afterSubmit,
  areaId,
  sectionId,
  onClose,
  folder: folderProp,
  maxSort
}: Props) => {
  const [folder, setFolder] = React.useState(folderProp);
  const [changes, setChanges] = React.useState<Folder>();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const classes = useStyles();

  const saveDisabled = folder
    ? !changes
    : (!changes?.areaId && !changes?.sectionId) ||
      !changes?.sort ||
      !changes?.folderLanguages;

  const handleChange = React.useCallback(
    (path: string, value: unknown, path2?: string, value2?: unknown) => {
      const newChanges = { ...changes } as Folder;
      set(newChanges, path, value);
      if (path2) {
        set(newChanges, path2, value2);
      }
      setChanges(newChanges);
    },
    [changes]
  );

  const handleDelete = () => {
    if (folder && confirm) {
      confirm(() => {
        dispatch(deleteFolder(folder.id));
        afterSubmit();
        onClose();
      }, folder.lists.length);
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const newFolder = mergeLanguageModel(folder, changes);
    if (newFolder) {
      if (folder) {
        dispatch(updateFolder(newFolder));
      } else {
        dispatch(createFolder(newFolder));
      }
      setFolder(newFolder);
      setChanges(undefined);
      afterSubmit();
      onClose();
    }
  };

  React.useEffect(() => {
    if (!folder && !changes) {
      if (areaId) {
        handleChange(
          'areaId',
          areaId,
          'sort',
          (maxSort !== Number.NEGATIVE_INFINITY ? maxSort ?? 0 : 0) + 10
        );
      } else {
        handleChange(
          'sectionId',
          sectionId,
          'sort',
          (maxSort !== Number.NEGATIVE_INFINITY ? maxSort ?? 0 : 0) + 10
        );
      }
    }
  }, [areaId, sectionId, maxSort, folder, changes, handleChange]);

  return (
    <Box className={classes.root}>
      <Box className={classes.row}>
        <Button
          startIcon={<RadioButtonChecked />}
          className={
            changes?.isEnabled ?? folder?.isEnabled
              ? classes.success
              : classes.error
          }
          onClick={(_e) =>
            handleChange(
              'isEnabled',
              !changes?.isEnabled ?? folder?.isEnabled ?? true
            )
          }
        >
          <Typography
            className={
              changes?.isEnabled ?? folder?.isEnabled
                ? classes.success
                : classes.error
            }
            variant="subtitle2"
          >
            {changes?.isEnabled ?? folder?.isEnabled
              ? 'PUBLISHED'
              : 'NOT PUBLISHED'}
          </Typography>
        </Button>
        <ButtonGroup>
          <Button
            disabled={saveDisabled}
            color="primary"
            variant="contained"
            endIcon={<Save />}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            disabled={Boolean(folder?.listsLength ?? true)}
            color="secondary"
            variant="contained"
            endIcon={<Delete />}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Box>

      <Box className={classes.row}>
        <Box className={classes.column}>
          <Box className={classes.row}>
            <Box
              flexBasis="60%"
              className={`${classes.column} ${classes.inputLeft}`}
            >
              {areaId ? (
                <AsyncAutocomplete
                  label="Area"
                  defaultValueId={changes?.areaId ?? folder?.areaId}
                  nameGetter={(area: Area) =>
                    getByLanguageId(area.areaLanguages, user?.primaryLanguageId)
                      ?.name
                  }
                  onChange={(value) => handleChange('areaId', value?.id)}
                />
              ) : (
                <AsyncAutocomplete
                  label="Section"
                  defaultValueId={changes?.sectionId ?? folder?.sectionId}
                  nameGetter={(section: Section) =>
                    (
                      getByLanguageId(
                        section.sectionLanguages,
                        user?.primaryLanguageId
                      ) ?? section
                    ).name
                  }
                  onChange={(value) => handleChange('sectionId', value?.id)}
                />
              )}
            </Box>
            <TextField
              margin="none"
              className={classes.inputRight}
              type="number"
              variant="outlined"
              value={changes?.sort ?? folder?.sort ?? ''}
              onChange={(e) => handleChange('sort', Number(e.target.value))}
              label={
                <Typography className={classes.labelText} variant="caption">
                  Sort
                </Typography>
              }
            />
          </Box>
        </Box>
        <Box className={classes.textFields}>
          {Object.values(LANGUAGES).map((language) => {
            const languageId = language.id;
            const folderLanguageIndex = folder?.folderLanguages?.findIndex(
              (al) => al?.languageId === languageId
            );
            const changesLanguageIndex = changes?.folderLanguages?.findIndex(
              (al) => al?.languageId === languageId
            );

            const ali =
              folderLanguageIndex !== undefined && folderLanguageIndex !== -1
                ? folderLanguageIndex
                : folder?.folderLanguages?.length ?? 0;
            const cli =
              changesLanguageIndex !== undefined && changesLanguageIndex !== -1
                ? changesLanguageIndex
                : changes?.folderLanguages?.length ?? 0;

            const folderLanguage = folder?.folderLanguages
              ? folder?.folderLanguages[ali]
              : undefined;
            const changesLanguage = changes?.folderLanguages
              ? changes?.folderLanguages[cli]
              : undefined;

            return (
              <TextField
                key={languageId}
                className={classes.textField}
                fullWidth
                margin="normal"
                variant="outlined"
                value={changesLanguage?.name ?? folderLanguage?.name ?? ''}
                onChange={(e) =>
                  handleChange(`folderLanguages[${cli ?? ali}]`, {
                    languageId,
                    name: e.target.value
                  })
                }
                label={
                  <Typography className={classes.labelText} variant="caption">
                    Name for&nbsp;&nbsp;
                    <img
                      className={classes.labelImage}
                      src={language?.flag ?? '/images/flags/INTL.svg'}
                      alt={`${language?.name ?? 'INTL'} flag`}
                    />
                  </Typography>
                }
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export const FolderForm = confirmHOC(FolderFormComp);
