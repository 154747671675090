import React from 'react';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Card,
  CardActionArea,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';

import { Unit, UnitLanguage } from '../../interfaces';
import { getByLanguageId } from '../../utils/model';
import { User } from '../../features/authSlice/interfaces';
import { RegionButton } from '../RegionButton';

interface Props {
  unit: Unit;
  user?: User | null;
}

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    verticalAlign: 'middle',
    padding: theme.spacing(2, 2)
  },
  listImage: {
    width: '9rem',
    height: '9rem',
    marginBottom: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  unitCard: {
    margin: theme.spacing(2, 1),
    width: '15%',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  lists: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  flag: {
    width: theme.spacing(2.25),
    height: theme.spacing(1.5),
    display: 'inline-block',
    fontSize: 1
  }
}));

export const UnitCard = ({ unit, user }: Props) => {
  const theme = useTheme();
  const classes = useStyles();

  const unitLanguage = getByLanguageId<UnitLanguage>(
    unit.unitLanguages,
    user?.primaryLanguageId
  );

  return (
    <Card key={unit.id} elevation={0} className={classes.unitCard}>
      <Link to={`/unit/${unit.id}`}>
        <CardActionArea className={classes.column}>
          <Avatar
            className={classes.listImage}
            src={unit?.imageUrl ?? '/logo-bw.png'}
            style={{
              border: `0.5rem solid ${
                unit.unitLanguages.some((ul) => ul.isEnabled)
                  ? theme.palette.success.main
                  : theme.palette.error.main
              }`
            }}
          />
          <Typography variant="h5" className={classes.marginBottom}>
            {unitLanguage?.name ?? '-'}
          </Typography>
          {unit.unitLanguages.some((ul) => !ul.isEnabled)
            ? unit.unitLanguages.map((ul) =>
                ul.isEnabled ? (
                  <RegionButton
                    key={ul.id}
                    size="m"
                    disabled
                    onlyLanguage
                    languageId={ul.languageId}
                  />
                ) : undefined
              )
            : undefined}
        </CardActionArea>
      </Link>
    </Card>
  );
};
