import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons';
import React from 'react';
import { getPairInflexions } from '../../api/pair';
import { LANGUAGES } from '../../constants/languages';
import { Pair } from '../../interfaces';
import { breakpointDownSm } from '../../theme';
import { InflexionEnglishCustomVerb } from '../Inflexion/InflexionEnglishCustomVerb';
import { InflexionEnglishName } from '../Inflexion/InflexionEnglishName';
import { InflexionEnglishVerb } from '../Inflexion/InflexionEnglishVerb';
import { InflexionSpanishName } from '../Inflexion/InflexionSpanishName';
import { InflexionsTable } from '../Inflexion/InflexionsTable';
import { Transition } from '../Transition';
import { ChainPairTagDialog } from './ChainPairTagDialog';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pairId: number;
  pairIds: number[];
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '80vh'
  },
  flexMode: {
    flex: 1
  },
  appBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  pairNavigator: {
    flex: 4,
    display: 'flex',
    justifyContent: 'space-between'
  },
  pairName: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  flag: {
    marginRight: theme.spacing(1)
  }
}));

const FIRST_PERSON_ID = 1;
const SPANISH_ID = 1;
const ENGLISH_ID = 2;
const NAME_ID = 1;
const VERB_ID = 2;

export function ChainInflexionsDialog({
  pairId,
  pairIds,
  open,
  setOpen,
  maxWidth = 'lg'
}: Props) {
  const [tagDialogInflexion, setTagDialogInflexion] = React.useState<Pair>();
  const [currentPair, setCurrentPair] = React.useState<Pair>();
  const [currentIndex, setCurrentIndex] = React.useState<number>(
    pairIds.findIndex((id) => id === pairId)
  );
  const [beMode, setBeMode] = React.useState<boolean>(false);

  const isEnglishVerb = React.useMemo(
    () =>
      currentPair?.grammaticalCategoryId === VERB_ID &&
      currentPair?.languageId === ENGLISH_ID,
    [currentPair]
  );

  const fullScreen = useMediaQuery(breakpointDownSm);
  const classes = useStyles();

  const toggleBeMode = () => {
    setBeMode(!beMode);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrevious = () => {
    if (pairIds[currentIndex - 1]) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (pairIds[currentIndex + 1]) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  function handleInflexionChange(newInflexion: Pair): void {
    if (currentPair) {
      const newInflexions = [...(currentPair.inflexions ?? [])];
      const newIndex = newInflexions.findIndex((p) => newInflexion.id === p.id);
      newInflexions[newIndex] = { ...newInflexion };
      setCurrentPair({ ...currentPair, inflexions: newInflexions });
    }
  }

  const getTableComponent = (): JSX.Element => {
    if (currentPair!.languageId === SPANISH_ID) {
      switch (currentPair!.grammaticalCategoryId) {
        case NAME_ID:
          return (
            <InflexionSpanishName
              onClick={setTagDialogInflexion}
              pair={currentPair!}
              setPair={setCurrentPair}
            />
          );
      }
    } else if (currentPair!.languageId === ENGLISH_ID) {
      switch (currentPair!.grammaticalCategoryId) {
        case VERB_ID:
          if (beMode) {
            return (
              <InflexionEnglishCustomVerb
                onClick={setTagDialogInflexion}
                pair={currentPair!}
                setPair={setCurrentPair}
              />
            );
          } else {
            return (
              <InflexionEnglishVerb
                onClick={setTagDialogInflexion}
                pair={currentPair!}
                setPair={setCurrentPair}
              />
            );
          }
        case NAME_ID:
          return (
            <InflexionEnglishName
              onClick={setTagDialogInflexion}
              pair={currentPair!}
              setPair={setCurrentPair}
            />
          );
      }
    }

    return <React.Fragment />;
  };

  React.useEffect(() => {
    (async () => {
      if (open) {
        const pair = await getPairInflexions(pairIds[currentIndex]);

        setCurrentPair(pair);
        setBeMode(
          pair?.inflexions?.some((p) => p.verbPersonId === FIRST_PERSON_ID) ??
            false
        );
      } else {
        setCurrentPair(undefined);
        setCurrentIndex(pairIds.findIndex((id) => id === pairId));
      }
    })();
  }, [pairIds, currentIndex, pairId, open]);

  return (
    <>
      <ChainPairTagDialog
        open={Boolean(tagDialogInflexion)}
        setOpen={(_) => setTagDialogInflexion(undefined)}
        pair={tagDialogInflexion}
        refresh={false}
        setPair={(p) => handleInflexionChange(p)}
      />
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Inflexions</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.container} dividers>
          <Box className={classes.appBar}>
            <div className={classes.flexMode} />
            <Box className={classes.pairNavigator}>
              <IconButton
                disabled={!pairIds[currentIndex - 1]}
                onClick={handlePrevious}
              >
                <ChevronLeft />
              </IconButton>
              <Typography className={classes.pairName} variant="h6">
                {currentPair ? (
                  <img
                    className={classes.flag}
                    width="20px"
                    src={LANGUAGES[currentPair.languageId].flag}
                    alt={LANGUAGES[currentPair.languageId].name}
                  />
                ) : null}
                {currentPair?.name ?? '-'} ({currentPair?.id ?? '-'})
              </Typography>
              <IconButton
                disabled={!pairIds[currentIndex + 1]}
                onClick={handleNext}
              >
                <ChevronRight />
              </IconButton>
            </Box>
            {isEnglishVerb ? (
              <Button
                variant="outlined"
                className={classes.flexMode}
                onClick={toggleBeMode}
              >
                TO BE MODE
              </Button>
            ) : (
              <div className={classes.flexMode} />
            )}
          </Box>
          {!currentPair ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {getTableComponent()}
              <Divider />
              <InflexionsTable
                pair={currentPair}
                setPair={setCurrentPair}
                setTagDialogInflexion={setTagDialogInflexion}
              />
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
