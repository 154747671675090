import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { FolderState } from './interfaces';

import {
  postFolder,
  patchFolder,
  deleteFolder as deleteFolderAPI
} from '../../api/folder';
import { Folder } from '../../interfaces';
import { reorderIds } from '../../utils/reoderIds';
import { refetchCurrentArea } from '../areaSlice';

// Slice name
export const folderSliceName = 'folder';

// Initial State
const folderInitialState: FolderState = {
  allFolderIds: [],
  foldersById: {},
  loading: true,
  error: null
};

// Slice definition with reducers
const folderSlice = createSlice({
  name: folderSliceName,
  initialState: folderInitialState,
  reducers: {
    setLoading: (state: FolderState, action) => {
      state.loading = action.payload;
    },
    setError: (state: FolderState, action) => {
      const errorMessage: string = action.payload;
      state.error = errorMessage;
    },
    pushFolders: (state: FolderState, action) => {
      const folders: Folder[] = action.payload;

      const allIds: string[] = folders.map(({ id }) => id.toString());
      const foldersById: {
        [key: string]: Folder;
      } = folders.reduce(
        (acc, next) => ({
          ...acc,
          [next.id]: next
        }),
        {}
      );

      state.allFolderIds.push(...allIds);
      state.foldersById = { ...state.foldersById, ...foldersById };
    },
    pushNewFolder: (state: FolderState, action) => {
      const folderFromApi: Folder = action.payload;

      state.foldersById[folderFromApi.id] = folderFromApi;

      state.allFolderIds.push(folderFromApi.id.toString());
      state.allFolderIds = reorderIds(state.allFolderIds, state.foldersById);
    },
    pushExistingFolder: (state: FolderState, action) => {
      const folderFromApi: Folder = action.payload;
      state.foldersById[folderFromApi.id] = folderFromApi;
      state.allFolderIds = reorderIds(state.allFolderIds, state.foldersById);
    },
    removeFolder: (state: FolderState, action) => {
      const id = action.payload;
      delete state.foldersById[id];
      state.allFolderIds = state.allFolderIds.filter((fId) => fId !== id);
    }
  }
});

// Actions
// TODO: Remove when [this PR](https://github.com/benmosher/eslint-plugin-import/pull/2038) is merged.
// eslint-disable-next-line import/no-unused-modules
export const {
  setLoading,
  setError,
  pushFolders,
  removeFolder,
  pushNewFolder,
  pushExistingFolder
} = folderSlice.actions;

export const createFolder =
  (folder: Folder) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const fetchedFolder = await postFolder(folder);
      await dispatch(pushNewFolder(fetchedFolder));
      await dispatch(refetchCurrentArea());
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateFolder =
  (folder: Folder) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    try {
      const fetchedFolder = await patchFolder(folder, folder.id);
      dispatch(pushExistingFolder(fetchedFolder));
      await dispatch(refetchCurrentArea());
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteFolder =
  (folderId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    try {
      await deleteFolderAPI(folderId);
      dispatch(removeFolder(folderId));
      dispatch(refetchCurrentArea());
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

// Reducer
export default folderSlice.reducer;
