import { FunctionComponent } from 'react';

import { Home } from '../pages/Home';
import { Areas } from '../pages/Areas';
import { Area } from '../pages/Area';
import { Courses } from '../pages/Courses';
import { List } from '../pages/List';
import { Chain } from '../pages/Chain';
import { Search } from '../pages/Search';
import { Course } from '../pages/Course';
import { Unit } from '../pages/Unit';
import { Tutorial } from '../pages/Tutorial';
import { Sections } from '../pages/Sections';
import { Section } from '../pages/Section';

export const HOME_ROUTE = '/';
export const SEARCH_ROUTE = '/search';
const SECTIONS_ROUTE = '/sections';
const SECTION_ROUTE = '/section/:id';
const AREAS_ROUTE = '/areas';
const AREA_ROUTE = '/area/:id';
const COURSES_ROUTE = '/courses';
const COURSE_ROUTE = '/course/:id';
const TUTORIAL_ROUTE = '/tutorial';
const LIST_ROUTE = '/list/:id';
const NEW_CHAIN_ROUTE = '/chain';
const CHAIN_ROUTE = '/chain/:id';
const UNIT_ROUTE = '/unit/:id';

export const drawerRoutes: Array<{
  text: string;
  path: string;
  disabled?: boolean;
}> = [
  { text: 'SEARCH', path: SEARCH_ROUTE },
  { text: 'AREAS', path: AREAS_ROUTE },
  { text: 'COURSES', path: COURSES_ROUTE },
  { text: 'SECTIONS', path: SECTIONS_ROUTE },
  { text: 'CREATE CHAIN', path: NEW_CHAIN_ROUTE }
];

export const appRoutes: Array<{
  path: string;
  page: FunctionComponent<any>;
  isProtected: boolean;
}> = [
  { path: HOME_ROUTE, page: Home, isProtected: false },
  { path: SEARCH_ROUTE, page: Search, isProtected: true },
  { path: AREAS_ROUTE, page: Areas, isProtected: true },
  { path: AREA_ROUTE, page: Area, isProtected: true },
  { path: COURSES_ROUTE, page: Courses, isProtected: true },
  { path: COURSE_ROUTE, page: Course, isProtected: true },
  { path: TUTORIAL_ROUTE, page: Tutorial, isProtected: true },
  { path: LIST_ROUTE, page: List, isProtected: true },
  { path: NEW_CHAIN_ROUTE, page: Chain, isProtected: true },
  { path: CHAIN_ROUTE, page: Chain, isProtected: true },
  { path: UNIT_ROUTE, page: Unit, isProtected: true },
  { path: SECTIONS_ROUTE, page: Sections, isProtected: true },
  { path: SECTION_ROUTE, page: Section, isProtected: true }
];
