import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  CircularProgress,
  Typography,
  makeStyles,
  Theme,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton
} from '@material-ui/core';

import { loadList, selectError, selectListsId } from '../features/listSlice';
import { selectUser } from '../features/authSlice';

import { TITLE } from '../constants';
import {
  ListHeader,
  ListTable,
  ListForm,
  ListPairForm,
  ListChainTable
} from '../components/List';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 4)
  }
}));

export function List() {
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const [openPair, setOpenPair] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [chainMode, setChainMode] = React.useState<boolean>(false);
  const [languageIds, setLanguageIds] = React.useState({
    languageId: 1,
    translationLanguageId: 2
  });
  const { id } = useParams<{ id: string }>();
  const list = useSelector(selectListsId)[Number(id)];
  // const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handlePairOpen = (
    languageId: number = 1,
    translationLanguageId: number = 2
  ) => {
    setLanguageIds({
      languageId,
      translationLanguageId
    });
    setOpenPair(true);
  };

  const handlePairClose = () => {
    fetchList();
    setOpenPair(false);
  };

  async function fetchList() {
    document.title = `List ${id} | ${TITLE}`;
    await dispatch(
      loadList(Number(id), user?.primaryLanguageId, user?.secondaryLanguageId)
    );
    setLoading(false);
  }

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (Boolean(error) || !list) {
    return (
      <Typography variant="h1" color="textPrimary">
        {error}
      </Typography>
    );
  }

  return (
    <Box className={classes.root}>
      <Dialog open={openEdit} onClose={handleEditClose} maxWidth="lg">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Edit List
            <IconButton onClick={handleEditClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <ListForm
            afterSubmit={handleEditClose}
            onClose={handleEditClose}
            list={list}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={openPair} onClose={handlePairClose} fullWidth maxWidth="lg">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Edit Pair List
            <IconButton onClick={handlePairClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <ListPairForm
            refetchList={fetchList}
            onClose={handlePairClose}
            listId={list.id}
            user={user}
            languageId={languageIds.languageId}
            translationLanguageId={languageIds.translationLanguageId}
          />
        </DialogContent>
      </Dialog>
      <ListHeader
        chainMode={chainMode}
        setChainMode={setChainMode}
        list={list}
        user={user}
      />
      {chainMode ? (
        <ListChainTable id={Number(id)} chainMode={chainMode} />
      ) : (
        <ListTable
          list={list}
          user={user}
          loading={loading}
          openPairDialog={handlePairOpen}
          openEditDialog={() => setOpenEdit(true)}
        />
      )}
    </Box>
  );
}
