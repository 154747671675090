import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  CircularProgress,
  makeStyles,
  Theme
} from '@material-ui/core';

import { User } from '../../features/authSlice/interfaces';
import { Transition } from '../Transition';
import { breakpointDownSm } from '../../theme';
import { Chain, Pair } from '../../interfaces';
import { LanguageTableHead } from '../LanguageTableHead';
import {
  deletePairTranslations,
  getPairTranslations,
  postPairTranslations
} from '../../api/pair';
import { useDispatch } from 'react-redux';
import { loadChain } from '../../features/chainSlice';
import { ChainTranslationsRow } from './ChainTranslationsRow';

interface Props {
  user?: User | null;
  chain?: Chain;
}

interface Translations {
  pairs: Pair[];
  translationPairs: Pair[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4, 12)
  },
  main: {
    background: theme.palette.grey[200]
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  chip: {
    fontSize: theme.spacing(2),
    fontWeight: 700,
    color: theme.palette.common.white
  }
}));

export const ChainTranslationsDialog = ({ user, chain }: Props) => {
  const [translations, setTranslations] = React.useState<Translations>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState<boolean>(false);
  const fullScreen = useMediaQuery(breakpointDownSm);
  const dispatch = useDispatch();
  const classes = useStyles();

  const fetchTranslations = async () => {
    if (user && chain) {
      setLoading(true);
      const fetchedTranslations = await getPairTranslations(
        chain.id,
        user?.primaryLanguageId,
        user?.secondaryLanguageId
      );
      await dispatch(loadChain(chain.id, false));
      setTranslations(fetchedTranslations);
      setLoading(false);
    }
  };

  const addTranslation = async (originPair: Pair, destinationPair?: Pair) => {
    if (destinationPair) {
      await postPairTranslations(originPair.id, destinationPair.id);
      await fetchTranslations();
    }
  };

  const removeTranslation =
    (originPair: Pair, destinationPair?: Pair) => async () => {
      if (destinationPair) {
        await deletePairTranslations(originPair.id, destinationPair.id);
        await fetchTranslations();
      }
    };

  const handleOpen = () => {
    setOpen(true);
    fetchTranslations();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} color="secondary" variant="contained">
        INDIVIDUAL TRANSLATIONS
      </Button>
      <Dialog
        fullWidth
        maxWidth="lg"
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Translations</DialogTitle>
        <DialogContent dividers className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <LanguageTableHead
                  languageId={user?.primaryLanguageId}
                  topLeft
                />
                <LanguageTableHead languageId={user?.secondaryLanguageId} />
                <LanguageTableHead
                  languageId={user?.tertiaryLanguageId}
                  topRight
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && translations ? (
                <>
                  <ChainTranslationsRow
                    classes={classes}
                    pairs={translations.pairs}
                    addTranslation={addTranslation}
                    removeTranslation={removeTranslation}
                    chain={chain}
                    translationLanguageId={user?.secondaryLanguageId}
                  />
                  <ChainTranslationsRow
                    classes={classes}
                    pairs={translations.translationPairs}
                    addTranslation={addTranslation}
                    removeTranslation={removeTranslation}
                    chain={chain}
                    translationLanguageId={user?.primaryLanguageId}
                    reverse
                  />
                </>
              ) : (
                <TableRow>
                  <TableCell className={classes.center} colSpan={3}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
