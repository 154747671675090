import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import { ErrorStateI } from './interfaces';

// Slice name
export const errorSliceName = 'error';

// Initial State
const errorInitialState: ErrorStateI = {
  error: null
};

// Slice definition with reducers
const errorSlice = createSlice({
  name: errorSliceName,
  initialState: errorInitialState,
  reducers: {
    setError: (state: ErrorStateI, action) => {
      const payload: string = action.payload;
      state.error = payload;
    },
    clearError: (state: ErrorStateI) => {
      state.error = null;
    }
  }
});

// Actions
// TODO: Remove when [this PR](https://github.com/benmosher/eslint-plugin-import/pull/2038) is merged.
// eslint-disable-next-line import/no-unused-modules
export const { setError, clearError } = errorSlice.actions;

// Selectors
export const selectError = (state: RootState) => state[errorSliceName].error;

// Reducer
export default errorSlice.reducer;
