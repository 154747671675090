import React from 'react';
import { Box } from '@material-ui/core';

import { SearchBar, SearchModel, SearchTable } from '../components/Search';
import { mainSearch } from '../api/search';
import { TITLE } from '../constants';
import { Chain, Pair } from '../interfaces';
import { useDebounce } from '../utils/hooks';

interface Props {
  onSubmit?: (pairId: number) => Promise<void>;
  listPairs?: Pair[];
  languageId?: number;
}

export const Search = ({
  onSubmit,
  listPairs,
  languageId: languageIdProp
}: Props) => {
  const [chains, setChains] = React.useState<Chain[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const [term, setTerm] = React.useState<string>('');
  const [languageId, setLanguageId] = React.useState<number>(
    languageIdProp ?? 0
  );
  const [tagId, setTagId] = React.useState<number>(0);
  const [model, setModel] = React.useState<SearchModel>(SearchModel.pair);

  const debouncedTerm = useDebounce(term, 500);

  const handleSubmit = async (pairId: number) => {
    if (onSubmit) {
      setLoading(true);
      try {
        await onSubmit(pairId);
      } catch (err: any) {
        setLoading(false);
      }
    }
  };

  const handleSearch = React.useCallback(
    async (
      possibleTerm: string,
      possibleTagId: number,
      possibleModel: SearchModel
    ) => {
      setLoading(true);
      if (possibleTerm.length > 2 || possibleTagId) {
        const { chains: fetchedChains } = await mainSearch(
          possibleTerm
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
          languageId,
          possibleTagId,
          possibleModel
        );
        setChains(fetchedChains);
      } else {
        setChains(undefined);
      }
      setLoading(false);
    },
    [languageId]
  );

  const handleFilterLanguage = (languageId: number): void => {
    setLanguageId(languageId);
  };

  const handleTermChange = (term: string) => {
    setTagId(0);
    setTerm(term);
  };

  const handleFilterTag = (tagId: number): void => {
    setTerm('');
    setTagId(tagId);
  };

  const handleModelChange = (model: SearchModel): void => {
    setModel(model);
  };

  const handlePageChange = (newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(newPage);
  };

  React.useEffect(() => {
    if (debouncedTerm.length >= 2 || tagId) {
      setPage(1);
    }
  }, [debouncedTerm, tagId]);

  React.useEffect(() => {
    if (debouncedTerm.length > 2) {
      handleSearch(debouncedTerm, 0, SearchModel.pair);
    }
  }, [handleSearch, debouncedTerm]);

  React.useEffect(() => {
    if (tagId) {
      handleSearch('', tagId, model);
    }
  }, [handleSearch, tagId, model]);

  React.useEffect(() => {
    if (!listPairs) document.title = `Search | ${TITLE}`;
  }, [listPairs]);

  return (
    <Box p={2}>
      <SearchBar
        term={term}
        setTerm={handleTermChange}
        onFilterLanguage={handleFilterLanguage}
        onFilterTag={handleFilterTag}
        onModelChange={handleModelChange}
        languageId={languageId}
        model={model}
        tagId={tagId}
      />
      <SearchTable
        onSubmit={onSubmit ? handleSubmit : undefined}
        term={debouncedTerm}
        chains={chains}
        page={page}
        listPairs={listPairs}
        loading={loading}
        tagId={tagId}
        handlePageChange={handlePageChange}
      />
    </Box>
  );
};
