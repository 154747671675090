import React from 'react';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
  Theme
} from '@material-ui/core';

import { SearchInput } from './SearchInput';
import { LanguageFilter } from './LanguageFilter';
import { TagFilter } from './TagFilter';

export enum SearchModel {
  all = '',
  pair = 'pair',
  chain = 'chain',
  list = 'list'
}

interface IProps {
  setTerm: (term: string) => void;
  term: string;
  onFilterLanguage?(languageId: number): void;
  onModelChange?(model: SearchModel): void;
  onFilterTag(tagId: number): void;
  languageId: number;
  tagId: number;
  model: SearchModel;
}

const styles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: theme.spacing(8)
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  languageFilter: {
    marginRight: theme.spacing(4)
  },
  button: {
    marginLeft: theme.spacing(8)
  }
}));

// This component will evolve dynamically so it becomes the Search handler for all elements in our platform
export const SearchBar = ({
  term,
  setTerm,
  onFilterLanguage,
  onModelChange,
  onFilterTag,
  model,
  languageId,
  tagId
}: IProps) => {
  const classes = styles();

  const handleModelChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onModelChange) {
      const { value: model } = e.target;
      onFilterTag(0);
      onModelChange(model as SearchModel);
    }
  };

  return (
    <Box className={classes.main}>
      <Box className={classes.wrapper}>
        <SearchInput setTerm={setTerm} term={term} />
        <Box className={classes.container}>
          <Box className={classes.languageFilter}>
            {onFilterLanguage ? (
              <LanguageFilter
                languageId={languageId}
                onFilterLanguage={onFilterLanguage}
              />
            ) : null}
          </Box>
          {onModelChange && (
            <RadioGroup value={model} row onChange={handleModelChange}>
              {/* TODO: Enable search radio */}
              <FormControlLabel
                disabled
                value={SearchModel.all}
                control={<Radio />}
                label="All"
              />
              <FormControlLabel
                value={SearchModel.pair}
                control={<Radio />}
                label="Pair"
              />
              <FormControlLabel
                value={SearchModel.chain}
                control={<Radio />}
                label="Chain"
              />
              <FormControlLabel
                disabled
                value={SearchModel.list}
                control={<Radio />}
                label="List"
              />
            </RadioGroup>
          )}
          {onFilterTag ? (
            <TagFilter
              onFilterTag={onFilterTag}
              tagId={tagId}
              isChain={model === SearchModel.chain}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
