import React from 'react';
import { cloneDeep } from 'lodash';

import {
  Button,
  Checkbox,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import { getListImportablePairsById } from '../../api/lists';
import { ImportablePair } from '../../interfaces';
import { ListTableBody } from './ListTableBody';

interface Props {
  listId?: number;
  languageId: number;
  translationLanguageId: number;
  handleAdd: (pairId: number) => Promise<void>;
  handleClose: () => void;
}

export const ListPairImport = ({
  listId,
  languageId,
  translationLanguageId,
  handleAdd,
  handleClose
}: Props) => {
  const [importable, setImportable] = React.useState<ImportablePair[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const allSelected = React.useMemo(
    () => importable.every((i) => i.selected),
    [importable]
  );

  const select = (index: number, selected: boolean) => {
    const newImportable = cloneDeep(importable) ?? [];
    newImportable[index].selected = selected;
    setImportable(newImportable);
  };

  const selectAll = () => {
    const newImportable = cloneDeep(importable) ?? [];
    newImportable.forEach((i) => {
      i.selected = allSelected ? false : true;
    });
    setImportable(newImportable);
  };

  const submitPairs = async () => {
    setLoading(true);
    await Promise.all(
      importable
        .filter((p) => p.selected)
        .map((p) => handleAdd(p.translationPairId))
    );
    setLoading(false);
    handleClose();
  };

  React.useEffect(() => {
    (async () => {
      if (listId) {
        setLoading(true);
        const { pairs } = await getListImportablePairsById(
          listId,
          translationLanguageId,
          languageId
        );
        setImportable(pairs);
      }
      setLoading(false);
    })();
  }, [listId, languageId, translationLanguageId]);

  return (
    <TableContainer style={{ width: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                value={allSelected}
                checked={allSelected}
                onChange={selectAll}
              />
            </TableCell>
            <TableCell>Select all</TableCell>
            <TableCell />
            <TableCell align="right">
              <Button
                onClick={submitPairs}
                variant="contained"
                color="secondary"
              >
                SUBMIT
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <ListTableBody check={select} rows={importable} loading={loading} />
      </Table>
    </TableContainer>
  );
};
