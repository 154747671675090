import { Pair, PairList } from '../interfaces';
import {
  deleteRequest,
  getRequest,
  handleQueryParams,
  patchRequest,
  postRequest
} from './index';

interface NewPair {
  name: string;
  grammaticalCategoryId?: number;
  languageId: number;
  chainId?: number;
  isEnabled?: boolean;
  picture?: File;
  audio?: File;
}

const POST_PAIR_ENDPOINT = '/pair/';
const PATCH_PAIR_ENDPOINT = '/pair/';

const PAIR_TRANSLATIONS_ENDPOINT = '/pair/translations/';

const PAIR_INFLEXIONS_ENDPOINT = '/pair/inflexions/';

const PAIR_LISTS_ENDPOINT = '/list/pair/';

const PAIR_LEVEL_ENDPOINT = '/pair/levels';

export const postPair = (data: NewPair): Promise<Pair> =>
  postRequest(POST_PAIR_ENDPOINT, data, true);
export const patchPair = (id: number, data: Partial<Pair>) =>
  patchRequest(PATCH_PAIR_ENDPOINT + id, data, true);

export const getPairTranslations = (
  chainId: number,
  languageId: number,
  translationLanguageId: number
) =>
  getRequest(
    PAIR_TRANSLATIONS_ENDPOINT +
      chainId +
      handleQueryParams({ languageId, translationLanguageId })
  );
export const postPairTranslations = (
  originPairId: number,
  destinationPairId: number
) =>
  postRequest(
    PAIR_TRANSLATIONS_ENDPOINT +
      originPairId +
      handleQueryParams({ destinationPairId })
  );
export const deletePairTranslations = (
  originPairId: number,
  destinationPairId: number
) =>
  deleteRequest(
    PAIR_TRANSLATIONS_ENDPOINT +
      originPairId +
      handleQueryParams({ destinationPairId })
  );

export const getPairLists = (
  pairId: number,
  languageId: number,
  translationLanguageId: number
): Promise<PairList[]> =>
  getRequest(
    PAIR_LISTS_ENDPOINT +
      pairId +
      handleQueryParams({ languageId, translationLanguageId })
  );

export const getPairInflexions = (pairId: number): Promise<Pair> =>
  getRequest(PAIR_INFLEXIONS_ENDPOINT + pairId);

export const postPairInflexion = (
  pairId: number,
  body: Partial<Pair>
): Promise<Pair> => postRequest(PAIR_INFLEXIONS_ENDPOINT + pairId, body);

export const patchPairInflexion = (
  inflexionId: number,
  body: Partial<Pair>
): Promise<Pair> => patchRequest(PAIR_INFLEXIONS_ENDPOINT + inflexionId, body);

export const deletePairInflexion = (inflexionId: number): Promise<Pair> =>
  deleteRequest(PAIR_INFLEXIONS_ENDPOINT + inflexionId);

export const patchPairsLevel = (
  pairIds: number[],
  level: number
): Promise<Pair[]> => patchRequest(PAIR_LEVEL_ENDPOINT, { pairIds, level });
