import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
  getCourses,
  getCourseById,
  patchCourse,
  postCourse,
  deleteCourse as deleteCourseAPI
} from '../../api/courses';
import { reorderIds } from '../../utils/reoderIds';
import { RootState } from '../../store';
import { Course } from '../../interfaces';

// Slice name
export const courseSliceName = 'course';

interface CourseState {
  allIds: string[];
  coursesById: {
    [key: string]: Course;
  };
  loading: boolean;
  error: string | null;
  currentId: number;
}

// Initial State
const coursesInitialState: CourseState = {
  allIds: [],
  coursesById: {},
  loading: true,
  error: null,
  currentId: -1
};

// Slice definition with reducers
const coursesSlice = createSlice({
  name: courseSliceName,
  initialState: coursesInitialState,
  reducers: {
    setLoading: (state: CourseState, action) => {
      state.loading = action.payload;
    },
    setError: (state: CourseState, action) => {
      const errorMessage: string = action.payload;
      state.error = errorMessage;
    },
    pushInitialCourses: (state: CourseState, action) => {
      const coursesFromApi: Course[] = action.payload;

      const allIds = coursesFromApi.map(({ id }) => id.toString());
      const coursesById = Object.values(coursesFromApi).reduce(
        (acc, nextVal) => ({
          ...acc,
          [nextVal.id]: nextVal
        }),
        {}
      );

      state.allIds = allIds;
      state.coursesById = coursesById;
    },
    pushNewCourse: (state: CourseState, action) => {
      const courseFromApi: Course = action.payload;

      state.coursesById[courseFromApi.id] = courseFromApi;

      state.allIds.push(courseFromApi.id.toString());
      state.allIds = reorderIds(state.allIds, state.coursesById);
    },
    pushExistingCourse: (state: CourseState, action) => {
      const courseFromApi: Course = action.payload;
      state.coursesById[courseFromApi.id] = courseFromApi;
      state.allIds = reorderIds(state.allIds, state.coursesById);
    },
    removeCourse: (state: CourseState, action) => {
      const courseId = action.payload;
      delete state.coursesById[courseId];
      state.allIds = state.allIds.filter((id) => id !== courseId.toString());
    },
    setCurrentCourseId: (state: CourseState, action) => {
      const courseId = action.payload;
      state.currentId = courseId;
    }
  }
});

// Actions
const {
  pushInitialCourses,
  pushNewCourse,
  pushExistingCourse,
  removeCourse,
  setLoading,
  setError
} = coursesSlice.actions;

// TODO: Remove when [this PR](https://github.com/benmosher/eslint-plugin-import/pull/2038) is merged.
// eslint-disable-next-line import/no-unused-modules
export const { setCurrentCourseId } = coursesSlice.actions;

// Thunks
export const loadCourses = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));

  try {
    const courses = await getCourses();
    dispatch(pushInitialCourses(courses));
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const loadCourse = (id: number) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));

  try {
    const area = await getCourseById(id);
    dispatch(pushExistingCourse(area));
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const refetchCurrentCourse =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setLoading(true));

    try {
      const { currentId } = getState()[courseSliceName];
      const area = await getCourseById(currentId);
      dispatch(pushExistingCourse(area));
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createCourse = (course: Course) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  try {
    const fetchedCourse = await postCourse(course);
    dispatch(pushNewCourse(fetchedCourse));
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateCourse = (course: Course) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));

  try {
    const fetchedCourse = await patchCourse(course, course.id);
    dispatch(pushExistingCourse(fetchedCourse));
  } catch (err: any) {
    dispatch(setError(err.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteCourse =
  (courseId: number) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true));

    try {
      await deleteCourseAPI(courseId);
      dispatch(removeCourse(courseId));
    } catch (err: any) {
      dispatch(setError(err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

// Selectors
export const selectAllCoursesIds = (state: RootState) =>
  state[courseSliceName].allIds;
export const selectCoursesById = (state: RootState) =>
  state[courseSliceName].coursesById;
export const selectLoading = (state: RootState) =>
  state[courseSliceName].loading;
export const selectError = (state: RootState) => state[courseSliceName].error;

// Reducer
export default coursesSlice.reducer;
