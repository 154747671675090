import React from 'react';

import {
  makeStyles,
  Theme,
  Box,
  Typography,
  IconButton
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

import { Chain, ChainLanguage } from '../../interfaces';

import { LANGUAGES } from '../../constants/languages';
import { getByLanguageId } from '../../utils/model';

import { ChainPair } from './ChainPair';
import { ChainAddPairDialog } from './ChainAddPairDialog';

interface Props {
  languageId?: number;
  chain?: Chain;
}

const useStyles = makeStyles((_theme: Theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '30%'
  }
}));

export const ChainPairList = ({ chain, languageId }: Props) => {
  const classes = useStyles();
  const chainLanguage = getByLanguageId<ChainLanguage>(
    chain?.chainLanguages,
    languageId
  );

  const [open, setOpen] = React.useState<boolean>(false);
  const [hasMainPairRegions, setHasMainPairRegions] = React.useState<{
    [regionId: number]: boolean;
  }>(
    languageId
      ? LANGUAGES[languageId].regions.reduce(
          (obj: { [regionId: number]: boolean }, { id }) => {
            obj[id] =
              chainLanguage?.pairs
                .filter((p) => p.regionId === id)
                .some((p) => p.main) ?? false;
            return obj;
          },
          {
            0:
              chainLanguage?.pairs
                .filter((p) => p.regionId === null)
                .some((p) => p.main) ?? false
          }
        )
      : {}
  );

  return (
    <Box className={classes.list}>
      <Typography className={classes.title} variant="h3">
        {languageId ? LANGUAGES[languageId].name.toUpperCase() : ''} PAIRS
        <IconButton
          disabled={!chain || !languageId}
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddCircle fontSize="large" />
        </IconButton>
        <ChainAddPairDialog
          chainId={chain?.id}
          languageId={languageId}
          open={open}
          setOpen={setOpen}
        />
      </Typography>
      <Box className={classes.list}>
        {chainLanguage?.pairs?.map((pair) => (
          <ChainPair
            pairIds={chainLanguage?.pairs?.map((p) => p.id)}
            hasMainPairRegions={hasMainPairRegions}
            setHasMainPairRegions={setHasMainPairRegions}
            key={pair.id}
            pair={pair}
          />
        ))}
      </Box>
    </Box>
  );
};
