import React from 'react';

import { Box, makeStyles, Theme, Typography, Tooltip } from '@material-ui/core';

import { GRAMMATICAL_CATEGORIES } from '../constants/grammatical-categories';

interface Props {
  isEnabled?: boolean;
  grammaticalCategoryName?: string;
  grammaticalCategoryId?: number;
  className?: string;
  onClick?: (isEnabled: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '25px',
    height: '25px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    boxShadow: 'inset 0px 5px 5px rgba(0, 0, 0, 0.25)',
    '&> span': {
      position: 'absolute',
      color: theme.palette.common.white
    }
  },

  hover: {
    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(120%) contrast(70%)'
    }
  },

  green: {
    backgroundColor: theme.palette.success.main
  },

  error: {
    backgroundColor: theme.palette.error.main
  },

  blue: {
    backgroundColor: theme.palette.primary.dark
  }
}));

export const IsEnabled = ({
  isEnabled,
  grammaticalCategoryId,
  grammaticalCategoryName,
  className,
  onClick
}: Props) => {
  const classes = useStyles();

  const isEnabledClass = React.useMemo(() => {
    if (isEnabled === undefined) {
      return classes.blue;
    } else {
      return isEnabled ? classes.green : classes.error;
    }
  }, [isEnabled, classes]);

  return (
    <Tooltip arrow title="Is it enabled?">
      <Box
        onClick={onClick ? () => onClick(!isEnabled) : undefined}
        className={`${classes.container} ${isEnabledClass} ${className} ${
          onClick ? classes.hover : ''
        }`}
      >
        {(grammaticalCategoryId || grammaticalCategoryName) && (
          <Typography variant="button">
            {grammaticalCategoryName?.toUpperCase() ??
              GRAMMATICAL_CATEGORIES[
                grammaticalCategoryId ?? 1
              ]?.abbreviation.toUpperCase() ??
              '-'}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};
