import { createMuiTheme, Theme } from '@material-ui/core/styles';
import createBreakpoints, {
  Breakpoints
} from '@material-ui/core/styles/createBreakpoints';

import * as colors from './colors';

const breakpoints: Breakpoints = createBreakpoints({});
export const breakpointDownSm = breakpoints.down('sm');
const breakpointMd = breakpoints.up('md');

export const drawerWidth = 240;

// Inject our custom theme and then create the Material Theme with those preferences
// this method takes an incomplete object and adds the mising parts
export const muiTheme = (prefersDarkMode: boolean): Theme =>
  createMuiTheme({
    palette: {
      type: prefersDarkMode ? 'light' : 'light',
      primary: { main: colors.primaryColor },
      secondary: { main: colors.secondaryColor }
    },
    typography: {
      fontFamily: 'Roboto',
      h1: {
        fontSize: '2.125rem',
        [breakpointMd]: {
          fontSize: '3.75rem'
        }
      },
      h2: {
        fontSize: '1.75rem',
        [breakpointMd]: {
          fontSize: '3rem'
        }
      },
      h3: {
        fontSize: '1.5rem',
        [breakpointMd]: {
          fontSize: '2.125rem'
        }
      },
      h4: {
        fontSize: '1.25rem',
        [breakpointMd]: {
          fontSize: '1.75rem'
        }
      },
      h5: {
        fontSize: '1.1rem',
        [breakpointMd]: {
          fontSize: '1.5rem'
        }
      },
      h6: {
        fontSize: '0.95rem',
        [breakpointMd]: {
          fontSize: '1.125rem'
        }
      },
      body1: {
        fontFamily: 'Roboto',
        fontSize: '0.85rem',
        [breakpointMd]: {
          fontSize: '1rem'
        }
      },
      body2: {
        fontFamily: 'Roboto',
        fontSize: '0.75rem',
        [breakpointMd]: {
          fontSize: '0.9rem'
        }
      },
      button: {
        fontSize: '0.8rem',
        [breakpointMd]: {
          fontSize: '0.875rem'
        }
      }
    }
  });
