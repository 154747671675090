import React from 'react';
import { Chain, Pair } from '../../interfaces';
import { TableRow, TableCell, MenuItem, Select, Chip } from '@material-ui/core';
import { PairChip } from '../PairChip';
import { RegionButton } from '../RegionButton';
import { getByLanguageId } from '../../utils/model';

interface Props {
  classes: any;
  pairs: Pair[];
  chain?: Chain;
  reverse?: boolean;
  translationLanguageId?: number;
  addTranslation: (
    originPair: Pair,
    destinationPair?: Pair | undefined
  ) => Promise<void>;
  removeTranslation: (
    originPair: Pair,
    destinationPair?: Pair | undefined
  ) => () => Promise<void>;
}

export const ChainTranslationsRow = ({
  classes,
  pairs,
  addTranslation,
  removeTranslation,
  chain,
  translationLanguageId,
  reverse = false
}: Props) => {
  const chainLanguage = React.useMemo(() => {
    return getByLanguageId(chain?.chainLanguages, translationLanguageId ?? 1);
  }, [chain?.chainLanguages, translationLanguageId]);

  return (
    <>
      {pairs.map((p) => (
        <TableRow key={p.id}>
          {!reverse ? (
            <TableCell className={classes.main}>
              <PairChip
                main={p.main}
                isEnabled={p.isEnabled}
                name={p.name}
                regionId={p.regionId}
                chainId={p.chainId}
              />
            </TableCell>
          ) : undefined}
          <TableCell className={classes.column}>
            {p.translations?.length
              ? p.translations?.map((t) => (
                  <PairChip
                    key={t.id}
                    regionId={t.regionId}
                    onDelete={removeTranslation(p, t)}
                    main={t.main}
                    chainId={t.chainId}
                    isEnabled={t.isEnabled}
                    name={t.name}
                    column
                  />
                ))
              : undefined}
            {!p.main && p.translations?.every((t) => t.regionId) ? (
              <Chip
                label={
                  <Select
                    autoWidth
                    placeholder="New"
                    value=""
                    onChange={(e) =>
                      addTranslation(
                        p,
                        chainLanguage?.pairs.find(
                          (clp) => clp.id === (e.target.value as number)
                        )
                      )
                    }
                  >
                    {chainLanguage?.pairs
                      .filter(
                        (clp) =>
                          clp.isEnabled &&
                          !p.translations?.some((t) => t.id === clp.id)
                      )
                      .map((clp) => (
                        <MenuItem key={clp.id} value={clp.id}>
                          <RegionButton disabled regionId={clp.regionId} />
                          {clp.name}
                        </MenuItem>
                      ))}
                  </Select>
                }
              />
            ) : undefined}
          </TableCell>
          {reverse ? (
            <TableCell className={classes.main}>
              <PairChip
                main={p.main}
                isEnabled={p.isEnabled}
                name={p.name}
                regionId={p.regionId}
                chainId={p.chainId}
              />
            </TableCell>
          ) : undefined}
          <TableCell />
        </TableRow>
      ))}
    </>
  );
};
