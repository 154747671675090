import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const confirmHOC = <P extends {}>(WrappedComponent: React.FC<P>) => {
  return (props: P): React.ReactElement<P> => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [onConfirm, setOnConfirm] = React.useState<() => void>();
    const [contentLength, setContentLength] = React.useState<number>();

    const handleOpen = (
      onConfirmProp: () => void,
      newContentLength?: number
    ) => {
      setOpen(true);
      setOnConfirm(() => onConfirmProp);
      setContentLength(newContentLength);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleConfirm = () => {
      if (onConfirm) {
        onConfirm();
        handleClose();
      }
    };

    const model = React.useMemo(
      () => (contentLength && contentLength > 1 ? 'models' : 'model'),
      [contentLength]
    );

    return (
      <>
        <Dialog open={open} onClose={handleClose} disableBackdropClick>
          <DialogTitle>Delete model?</DialogTitle>
          <DialogContent>
            <DialogContentText>{`You are about to delete a model${
              contentLength ? ` with ${contentLength} ${model} inside` : ''
            }. Are you sure?`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <WrappedComponent {...(props as P)} confirm={handleOpen} />
      </>
    );
  };
};
