import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  makeStyles,
  Theme,
  Typography,
  TextField,
  Button,
  ButtonGroup
} from '@material-ui/core';
import { Delete, RadioButtonChecked, Save } from '@material-ui/icons';

import { set } from 'lodash';

import { Level, Course } from '../../interfaces';
import { LANGUAGES } from '../../constants/languages';
import {
  createLevel,
  updateLevel,
  deleteLevel
} from '../../features/levelSlice';
import { getByLanguageId, mergeLanguageModel } from '../../utils/model';
import { AsyncAutocomplete } from '../AsyncAutocomplete';
import { selectUser } from '../../features/authSlice';
import { confirmHOC } from '../hocs/confirmHOC';

interface Props {
  afterSubmit: () => void;
  onClose: () => void;
  maxSort?: number;
  level?: Level;
  courseId?: string;
  confirm?: (onConfirm: () => void, contentLength?: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    margin: theme.spacing(0, 2)
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0)
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%'
  },

  deleteImage: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    top: -10,
    right: -10,

    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    },
    '&:disabled': {
      display: 'none'
    }
  },
  textFields: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    marginLeft: theme.spacing(4)
  },
  textField: {
    marginTop: theme.spacing(0)
  },
  image: {
    width: '100%',
    height: theme.spacing(30),
    marginBottom: theme.spacing(2)
  },
  inputLeft: {
    marginRight: theme.spacing(1)
  },
  input: {
    marginBottom: theme.spacing(2)
  },
  inputRight: {
    marginLeft: theme.spacing(1),
    width: '20%'
  },
  color: {
    padding: theme.spacing(0)
  },
  labelText: {
    display: 'flex',
    alignItems: 'center'
  },
  labelImage: {
    height: '1em'
  }
}));

const LevelFormComp = ({
  maxSort,
  onClose,
  level: levelProp,
  courseId,
  confirm
}: Props) => {
  const [level, setLevel] = React.useState(levelProp);
  const [changes, setChanges] = React.useState<Level>();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const classes = useStyles();

  const saveDisabled = level
    ? !changes
    : !changes?.primaryColor ||
      !changes?.secondaryColor ||
      !changes?.tertiaryColor ||
      !changes?.sort ||
      !changes?.levelLanguages;

  const handleChange = React.useCallback(
    (path: string, value: unknown, path2?: string, value2?: unknown) => {
      const newChanges = { ...changes } as Level;
      set(newChanges, path, value);
      if (path2) {
        set(newChanges, path2, value2);
      }
      setChanges(newChanges);
    },
    [changes]
  );

  const handleDelete = () => {
    if (level && confirm) {
      confirm(() => {
        dispatch(deleteLevel(level.id));
        onClose();
      }, level.units.length);
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const newLevel = mergeLanguageModel(level, changes);
    if (newLevel) {
      if (!newLevel.courseId) newLevel.courseId = Number(courseId);
      if (level) {
        dispatch(updateLevel(newLevel));
      } else {
        dispatch(createLevel(newLevel));
      }
      setLevel(newLevel);
      setChanges(undefined);
      onClose();
    }
  };

  React.useEffect(() => {
    if (!level && !changes) {
      handleChange(
        'courseId',
        Number(courseId),
        'sort',
        (maxSort !== Number.NEGATIVE_INFINITY ? maxSort ?? 0 : 0) + 10
      );
    }
  }, [changes, maxSort, handleChange, level, courseId]);

  return (
    <Box className={classes.root}>
      <Box className={classes.row}>
        <Button
          startIcon={<RadioButtonChecked />}
          className={
            changes?.isEnabled ?? level?.isEnabled
              ? classes.success
              : classes.error
          }
          onClick={(_e) =>
            handleChange(
              'isEnabled',
              !(changes?.isEnabled ?? level?.isEnabled ?? true)
            )
          }
        >
          <Typography
            className={
              changes?.isEnabled ?? level?.isEnabled
                ? classes.success
                : classes.error
            }
            variant="subtitle2"
          >
            {changes?.isEnabled ?? level?.isEnabled
              ? 'PUBLISHED'
              : 'NOT PUBLISHED'}
          </Typography>
        </Button>
        <ButtonGroup>
          <Button
            disabled={saveDisabled}
            color="primary"
            variant="contained"
            endIcon={<Save />}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            disabled={Boolean(level?.units?.length ?? true)}
            color="secondary"
            variant="contained"
            endIcon={<Delete />}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Box>

      <Box className={classes.row}>
        <Box className={classes.column}>
          <Box className={classes.row}>
            <Box
              flexBasis="40%"
              className={`${classes.column} ${classes.inputLeft}`}
            >
              <AsyncAutocomplete
                label="Course"
                defaultValueId={changes?.courseId ?? level?.courseId}
                nameGetter={(course: Course) =>
                  getByLanguageId(
                    course.courseLanguages,
                    user?.primaryLanguageId
                  )?.name
                }
                onChange={(value) => handleChange('courseId', value?.id)}
              />
              <TextField
                margin="none"
                className={classes.input}
                type="color"
                variant="outlined"
                value={changes?.primaryColor ?? level?.primaryColor}
                onChange={(e) => handleChange('primaryColor', e.target.value)}
                label={
                  <Typography className={classes.labelText} variant="caption">
                    Primary Color
                  </Typography>
                }
              />
              <TextField
                margin="none"
                className={classes.input}
                type="color"
                variant="outlined"
                value={changes?.secondaryColor ?? level?.secondaryColor}
                onChange={(e) => handleChange('secondaryColor', e.target.value)}
                label={
                  <Typography className={classes.labelText} variant="caption">
                    Secondary Color
                  </Typography>
                }
              />
              <TextField
                margin="none"
                className={classes.input}
                type="color"
                variant="outlined"
                value={changes?.tertiaryColor ?? level?.tertiaryColor}
                onChange={(e) => handleChange('tertiaryColor', e.target.value)}
                label={
                  <Typography className={classes.labelText} variant="caption">
                    Tertiary Color
                  </Typography>
                }
              />
            </Box>
            <TextField
              margin="none"
              className={classes.inputRight}
              type="number"
              variant="outlined"
              value={changes?.sort ?? level?.sort}
              onChange={(e) => handleChange('sort', Number(e.target.value))}
              label={
                <Typography className={classes.labelText} variant="caption">
                  Sort
                </Typography>
              }
            />
          </Box>
        </Box>
        <Box className={classes.textFields}>
          {Object.values(LANGUAGES).map((language) => {
            const languageId = language.id;
            const levelLanguageIndex = level?.levelLanguages?.findIndex(
              (al) => al?.languageId === languageId
            );
            const changesLanguageIndex = changes?.levelLanguages?.findIndex(
              (al) => al?.languageId === languageId
            );

            const ali =
              levelLanguageIndex !== undefined && levelLanguageIndex !== -1
                ? levelLanguageIndex
                : level?.levelLanguages?.length ?? 0;
            const cli =
              changesLanguageIndex !== undefined && changesLanguageIndex !== -1
                ? changesLanguageIndex
                : changes?.levelLanguages?.length ?? 0;

            const levelLanguage = level?.levelLanguages
              ? level?.levelLanguages[ali]
              : undefined;
            const changesLanguage = changes?.levelLanguages
              ? changes?.levelLanguages[cli]
              : undefined;

            return (
              <TextField
                key={languageId}
                className={classes.textField}
                fullWidth
                margin="normal"
                variant="outlined"
                value={changesLanguage?.name ?? levelLanguage?.name}
                onChange={(e) =>
                  handleChange(`levelLanguages[${cli ?? ali}]`, {
                    languageId,
                    name: e.target.value
                  })
                }
                label={
                  <Typography className={classes.labelText} variant="caption">
                    Name for&nbsp;&nbsp;
                    <img
                      className={classes.labelImage}
                      src={language?.flag ?? '/images/flags/INTL.svg'}
                      alt={`${language?.name ?? 'INTL'} flag`}
                    />
                  </Typography>
                }
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export const LevelForm = confirmHOC(LevelFormComp);
