import { makeStyles, MenuItem, Select } from '@material-ui/core';
import { capitalize } from 'lodash';
import React from 'react';

interface Definition {
  id: number;
  name: string;
}

interface Props<T> {
  disabled?: boolean;
  value?: number;
  onChange?: (id: number | null) => void;
  field: string;
  options: T[];
}

const useStyles = makeStyles((_) => ({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
}));

export const InflexionCell = <T extends Definition>({
  disabled = false,
  field,
  onChange = (_) => {},
  value,
  options
}: Props<T>) => {
  const classes = useStyles();

  return (
    <Select
      disabled={disabled}
      label={capitalize(field)}
      value={value ?? 0}
      inputProps={{ className: classes.option }}
      onChange={(e) => onChange(e.target.value ? Number(e.target.value) : null)}
    >
      <MenuItem className={classes.option} value={0}>
        <span>0</span>
        None
      </MenuItem>
      {options.map((o) => (
        <MenuItem key={o.id} className={classes.option} value={o.id}>
          <span>{o.id.toString()}</span>
          {o.name}
        </MenuItem>
      ))}
    </Select>
  );
};
