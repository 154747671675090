// Used to properly sort elements by id when showing the elements normalized in arrays
export const reorderIds = (
  allIds: string[],
  elementsById: { [key: string]: { isEnabled?: boolean; sort: number } }
) =>
  allIds.sort((id1: string, id2: string) => {
    const element1 = elementsById[id1];
    const element2 = elementsById[id2];

    if (element1.isEnabled === element2.isEnabled) {
      return element1.sort - element2.sort;
    }

    return element1.isEnabled ? -1 : 1;
  });
